import { SET_TABLE_LOADING, SET_CREATE_LOADING } from '../reducers/types';

export const toggleTableLoading = (loading) => async (dispatch) => {
	dispatch({
		type: SET_TABLE_LOADING,
		payload: loading,
	});
};

export const toggleCreateLoading = (loading) => async (dispatch) => {
	dispatch({
		type: SET_CREATE_LOADING,
		payload: loading,
	});
};
