import {
	SET_ORGANISATIONS,
	LOADING_ORGANISATIONS,
	CREATE_ORGANISATION,
	UPDATE_ORGANISATION,
	DELETE_ORGANISATION,
} from './types';

const initialState = {
	organisations: [],
	error: null,
	loading: false,
};

const organisationReducer = (state = initialState, action) => {
	switch (action.type) {
		case LOADING_ORGANISATIONS:
			return {
				...state,
				loading: true,
			};

		case SET_ORGANISATIONS:
			return {
				...state,
				organisations: action.payload || [],
				loading: false,
			};

		case CREATE_ORGANISATION:
			return {
				...state,
				organisations: [action.payload, ...state.organisations],
			};

		case UPDATE_ORGANISATION:
			const organisationIdx = _.findIndex(
				state.organisations,
				(org) => org.id === action.payload.organisationId,
			);
			return {
				...state,
				organisations: [
					...state.organisations.slice(0, organisationIdx),
					action.payload,
					...state.organisations.slice(organisationIdx + 1),
				],
			};

		case DELETE_ORGANISATION:
			return {
				...state,
				organisations: _.reject(state.organisations, (i) => i.id === action.payload),
			};

		default:
			return state;
	}
};

export default organisationReducer;
