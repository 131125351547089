import {
	SET_SALE_VOUCHERS,
	LOADING_SALE_VOUCHERS,
	CREATE_SALE_VOUCHER,
	UPDATE_SALE_VOUCHER,
	DELETE_SALE_VOUCHER,
	SET_PAYMENT_METHODS,
} from './types';

const initialState = {
	saleVouchers: [],
	paymentMethods: [],
	error: null,
	loading: false,
};

const saleVoucherReducer = (state = initialState, action) => {
	switch (action.type) {
		case LOADING_SALE_VOUCHERS:
			return {
				...state,
				loading: true,
			};

		case SET_SALE_VOUCHERS:
			return {
				...state,
				saleVouchers: action.payload || [],
				loading: false,
			};

		case CREATE_SALE_VOUCHER:
			return {
				...state,
				saleVouchers: [action.payload, ...state.saleVouchers],
			};

		case UPDATE_SALE_VOUCHER:
			state.saleVouchers = state.saleVouchers.filter((o) => o.id != action.payload.id);
			return {
				saleVouchers: [action.payload, ...state.saleVouchers],
				...state,
			};

		case DELETE_SALE_VOUCHER:
			state.saleVouchers = state.saleVouchers.filter((ig) => ig.id != action.payload);
			return {
				...state,
			};
		case SET_PAYMENT_METHODS:
			return {
				...state,
				paymentMethods: action.payload,
			};

		default:
			return state;
	}
};

export default saleVoucherReducer;
