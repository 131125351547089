import React from 'react';
import { authMenu, dashboardMenu } from '../menu';
import DashboardHeader from '../pages/common/Headers/DashboardHeader';
import DefaultHeader from '../pages/common/Headers/DefaultHeader';

const headers = [
	{ path: authMenu.login.path, element: null, exact: true },
	{ path: authMenu.signUp.path, element: null, exact: true },
	{ path: authMenu.page404.path, element: null, exact: true },
	{ path: dashboardMenu.patients.subMenu.faceCard.path, element: null, exact: true },
	{ path: dashboardMenu.patients.subMenu.patientExaminationPdf.path, element: null, exact: true },
	{
		path: dashboardMenu.patients.subMenu.patientTreatmentPlanPdf.path,
		element: null,
		exact: true,
	},
	{ path: dashboardMenu.patients.subMenu.patientTreatmentPdf.path, element: null, exact: true },
	{
		path: dashboardMenu.patients.subMenu.patientPrescriptionPdf.path,
		element: null,
		exact: true,
	},
	{ path: dashboardMenu.patients.subMenu.patientBillingPdf.path, element: null, exact: true },
	{ path: dashboardMenu.patients.subMenu.patientPaymentPdf.path, element: null, exact: true },
	{ path: dashboardMenu.patients.subMenu.patientOpdPdf.path, element: null, exact: true },
	{ path: dashboardMenu.patients.subMenu.patientShortReport.path, element: null, exact: true },
	{ path: dashboardMenu.patients.subMenu.patientLongReport.path, element: null, exact: true },
	{
		path: dashboardMenu.patients.subMenu.patientDetailedLongReport.path,
		element: null,
		exact: true,
	},
	{
		path: dashboardMenu.patients.subMenu.staticConsentForm.path,
		element: null,
		exact: true,
	},
	{
		path: dashboardMenu.patients.subMenu.pathology.path,
		element: null,
		exact: true,
	},
	{
		path: dashboardMenu.patients.subMenu.requisition.path,
		element: null,
		exact: true,
	},
	{
		path: dashboardMenu.patients.subMenu.orthodonticTreatmentConsent.path,
		element: null,
		exact: true,
	},
	{
		path: dashboardMenu.opd.subMenu.pdfHeader.path,
		element: null,
		exact: true,
	},
	{ path: dashboardMenu.pharmacy.subMenu.saleVoucher.path, element: null, exact: true },
	{ path: dashboardMenu.settings.subMenu.consentsPrint.path, element: null, exact: true },
	{ path: dashboardMenu.accounts.subMenu.printMis.path, element: null, exact: true },
	{ path: dashboardMenu.accounts.subMenu.printDrWise.path, element: null, exact: true },
	// { path: dashboardMenu.dashboard.path, element: <DashboardHeader />, exact: true },
	{
		path: `*`,
		element: <DefaultHeader />,
	},
];

export default headers;
