import React, { useContext, useState, useEffect, useLayoutEffect, useRef } from 'react';
import { ThemeProvider } from 'react-jss';
import ReactNotification from 'react-notifications-component';
import { useFullscreen } from 'react-use';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';
import { useSelector, useDispatch } from 'react-redux';
import { io } from 'socket.io-client';

// Initialize socket
const socket = io(process.env.REACT_APP_SOCKET_URL || 'https://app.docplix.online');

import ThemeContext from '../contexts/themeContext';
import Aside from '../layout/Aside/Aside';
import Wrapper from '../layout/Wrapper/Wrapper';
import Portal from '../layout/Portal/Portal';
import { Toast, ToastContainer } from '../components/bootstrap/Toasts';
import useDarkMode from '../hooks/useDarkMode';
import COLORS from '../common/data/enumColors';
import { getOS } from '../helpers/helpers';
import { authMenu, dashboardMenu } from '../menu';
import { getUserData, getUserPermissions } from '../actions/profileActions';
import NewOpdPopup from '../common/NewOpdPopup';
import NewPatientPopup from '../common/NewPatientPopup';
import showNotification from '../components/extras/showNotification';
import {
	getPendingExaminations,
	getPendingPrescription,
	getPendingTreatmentPlans,
	getPendingTreatments,
	getSelectedOrgBackend,
} from '../actions/helperActions';
import { SET_NOTIFICATIONS_UNREAD } from '../reducers/types';
import { setInitialOrganisation } from '../actions/organisationActions';
import Modal, { ModalBody, ModalFooter } from '../components/bootstrap/Modal';
import Button from '../components/bootstrap/Button';

const App = () => {
	getOS();

	/**
	 * Dark Mode
	 */
	const { themeStatus, darkModeStatus } = useDarkMode();
	const theme = {
		theme: themeStatus,
		primary: COLORS.PRIMARY.code,
		secondary: COLORS.SECONDARY.code,
		success: COLORS.SUCCESS.code,
		info: COLORS.INFO.code,
		warning: COLORS.WARNING.code,
		danger: COLORS.DANGER.code,
		dark: COLORS.DARK.code,
		light: COLORS.LIGHT.code,
	};

	useEffect(() => {
		if (darkModeStatus) {
			document.documentElement.setAttribute('theme', 'dark');
		}
		return () => {
			document.documentElement.removeAttribute('theme');
		};
	}, [darkModeStatus]);

	/**
	 * Full Screen
	 */
	const { fullScreenStatus, setFullScreenStatus } = useContext(ThemeContext);
	const ref = useRef(null);
	useFullscreen(ref, fullScreenStatus, {
		onClose: () => setFullScreenStatus(false),
	});

	/**
	 * Modern Design
	 */
	useLayoutEffect(() => {
		if (process.env.REACT_APP_MODERN_DESGIN === 'true') {
			document.body.classList.add('modern-design');
		} else {
			document.body.classList.remove('modern-design');
		}
	});

	const loggedIn = useSelector((state) => state.auth.loggedIn);
	const user = useSelector((state) => state.auth.user);
	const selectedOrg = useSelector((state) => state.profile.selectedOrg);

	/**
	 * Socket Connection
	 */
	const [newOpdModal, setNewOpdModal] = useState(false);
	const [newPatientModal, setNewPatientModal] = useState(false);
	const [audio] = useState(new Audio('/sounds/ting.mp3'));

	const [verifyModal, setVerifyModal] = useState(false);
	const [verifyData, setVerifyData] = useState(null);

	const dispatch = useDispatch();

	useEffect(() => {
		socket.on('connect', () => {
			console.log('Connected to server on socket');

			socket.emit('init', { vendorId: user?.Vendor_detail?.vendorId });

			socket.on('opd-add', (data) => {
				audio.play();
				console.log('-------------------------------------opd-add fired!!!');
				console.log(data);
				dispatch({ type: SET_NOTIFICATIONS_UNREAD });
				showNotification('Notification', 'A new OPD has been added', 'warning');
				setNewOpdModal(true);
			});

			socket.on('patient-add', (data) => {
				audio.play();
				console.log('-------------------------------------patient-add fired!!!');
				console.log(data);
				dispatch({ type: SET_NOTIFICATIONS_UNREAD });
				showNotification('Notification', 'A new Patient has been added', 'warning');
				setNewPatientModal(true);
			});

			socket.on('treatment-plan-add', (data) => {
				audio.play();
				console.log('-------------------------------------treatment-plan-add fired!!!');
				console.log(data);
				dispatch(getPendingTreatmentPlans());
				dispatch({ type: SET_NOTIFICATIONS_UNREAD });
				showNotification('Notification', 'A new Treatment Plan has been added', 'warning');
			});

			socket.on('treatment-add', (data) => {
				audio.play();
				console.log('-------------------------------------treatment-add fired!!!');
				console.log(data);
				dispatch(getPendingTreatments());
				dispatch({ type: SET_NOTIFICATIONS_UNREAD });
				showNotification('Notification', 'A new Treatment has been added', 'warning');
			});

			socket.on('examination-add', (data) => {
				audio.play();
				showNotification('Notification', 'A new Examination has been added', 'warning');
				console.log(data);
				dispatch(getPendingExaminations());
				dispatch({ type: SET_NOTIFICATIONS_UNREAD });
				console.log('-------------------------------------examination-add fired!!!');
			});

			socket.on('prescription-add', (data) => {
				audio.play();
				showNotification('Notification', 'A new Prescription has been added', 'warning');
				console.log(data);
				dispatch(getPendingPrescription());
				dispatch({ type: SET_NOTIFICATIONS_UNREAD });
				console.log('-------------------------------------prescription-add fired!!!');
			});

			socket.on('prescription-verify', (data) => {
				audio.play();
				console.log({ data });
				setVerifyData({
					...data,
					type: 'Prescription',
				});
				setVerifyModal(true);
			});

			socket.on('examination-verify', (data) => {
				audio.play();
				console.log({ data });
				setVerifyData({
					...data,
					type: 'Examination',
				});
				setVerifyModal(true);
			});

			socket.on('treatment-plan-verify', (data) => {
				audio.play();
				console.log({ data });
				setVerifyData({
					...data,
					type: 'Treatment Plan',
				});
				setVerifyModal(true);
			});

			socket.on('treatment-verify', (data) => {
				audio.play();
				console.log({ data });
				setVerifyData({
					...data,
					type: 'Treatment',
				});
				setVerifyModal(true);
			});
		});

		return () => {
			socket.off('connect');
		};
	}, []);

	//	Add paths to the array that you don't want to be "Aside".
	const withOutAsidePages = [
		authMenu.login.path,
		authMenu.signUp.path,
		dashboardMenu.patients.subMenu.faceCard.path,
		dashboardMenu.patients.subMenu.patientExaminationPdf.path,
		dashboardMenu.patients.subMenu.patientTreatmentPlanPdf.path,
		dashboardMenu.patients.subMenu.patientTreatmentPdf.path,
		dashboardMenu.patients.subMenu.patientPrescriptionPdf.path,
		dashboardMenu.patients.subMenu.patientBillingPdf.path,
		dashboardMenu.patients.subMenu.patientPaymentPdf.path,
		dashboardMenu.patients.subMenu.patientOpdPdf.path,
		dashboardMenu.patients.subMenu.patientShortReport.path,
		dashboardMenu.patients.subMenu.patientLongReport.path,
		dashboardMenu.patients.subMenu.patientDetailedLongReport.path,
		dashboardMenu.patients.subMenu.staticConsentForm.path,
		dashboardMenu.patients.subMenu.orthodonticTreatmentConsent.path,
		dashboardMenu.patients.subMenu.pathology.path,
		dashboardMenu.patients.subMenu.requisition.path,
		dashboardMenu.opd.subMenu.pdfHeader.path,
		dashboardMenu.pharmacy.subMenu.saleVoucher.path,
		dashboardMenu.settings.subMenu.consentsPrint.path,
		dashboardMenu.accounts.subMenu.printMis.path,
		dashboardMenu.accounts.subMenu.printDrWise.path,
	];

	useEffect(() => {
		let mounted = true;

		const fetchAuth = async () => {
			// CHECK SELECTED ORG
			if (mounted && loggedIn) {
				const backendOrg = await getSelectedOrgBackend();
				if (backendOrg) dispatch(setInitialOrganisation(backendOrg));
				else dispatch(setInitialOrganisation());

				// GET USER DATA
				dispatch(getUserData());

				// GET STAFF PERMISSIONS
				if (user && selectedOrg?.userType === 'staff') {
					dispatch(
						getUserPermissions(
							selectedOrg?.organisationId,
							user?.Vendor_detail?.vendorId,
						),
					);
				}
			}
		};

		fetchAuth();

		return () => {
			mounted = false;
		};
	}, []);

	return (
		<ThemeProvider theme={theme}>
			<ToastProvider components={{ ToastContainer, Toast }}>
				<div
					ref={ref}
					className='app'
					style={{
						backgroundColor: fullScreenStatus && 'var(--bs-body-bg)',
						zIndex: fullScreenStatus && 1,
						overflow: fullScreenStatus && 'scroll',
					}}>
					<Routes>
						{withOutAsidePages.map((path) => (
							<Route key={path} path={path} />
						))}
						<Route
							path='*'
							element={
								loggedIn ? (
									<Aside />
								) : (
									window.location.url !== '/auth-pages/login' && (
										<Navigate to='/auth-pages/login' />
									)
								)
							}
						/>
					</Routes>
					<Wrapper />
				</div>
				<Portal id='portal-notification'>
					<ReactNotification />
				</Portal>
				<NewOpdPopup isOpenModal={newOpdModal} setIsOpenModal={setNewOpdModal} />
				<NewPatientPopup
					isOpenModal={newPatientModal}
					setIsOpenModal={setNewPatientModal}
				/>
				<Modal isOpen={verifyModal}>
					<ModalBody>
						Your {verifyData?.type} for patient{' '}
						<strong>{verifyData?.patientName}</strong> is {verifyData?.status} by{' '}
						<strong>Dr. {verifyData?.vendorName}</strong>
					</ModalBody>
					<ModalFooter>
						<Button
							color='primary'
							isOutline
							onClick={() => {
								setVerifyData(null);
								setVerifyModal(false);
							}}>
							OK
						</Button>
					</ModalFooter>
				</Modal>
			</ToastProvider>
		</ThemeProvider>
	);
};

export default App;
