import _ from 'lodash';
import {
	SET_STORE_ITEMS,
	SET_STORE_PURCHASE,
	SET_STORE_ASSIGN_LIST,
	SET_STORE_INVENTORY,
	SET_STORE_INDENTATION,
	SET_STORE_SALES,
	SET_PATIENT_STORE_ASSIGN,
} from './types';

const initialState = {
	page: 1, // Page for ipds
	total: 0, // total Records for all ipd list
	storePage: 1,
	storeTotal: 0,
	indentationPage: 0,
	indentationTotal: 0,
	salesPage: 0,
	salesTotal: 0,
	storeItems: [],
	storePurchase: [],
	storeAssigns: [],
	storeSales: [],
	storeIndentation: [],
	storeInventory: [],
	patientStoreAssign: [],
	paymentRemarks: [],
	storePurchaseUrl: null,
	storeItemsUrl: null,
};

const ipdReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_STORE_ITEMS:
			return {
				...state,
				storeItems: action.payload.data || [],
				storeItemsUrl: action.payload.storeItemsUrl || null,
			};
		case SET_STORE_PURCHASE:
			return {
				...state,
				storePurchase: action.payload.data || [],
				storePurchaseUrl: action.payload.storePurchaseUrl || null,
				storePage: action.payload.page || 1,
				storeTotal: action.payload.total || 0,
			};
		case SET_STORE_ASSIGN_LIST:
			return {
				...state,
				storeAssigns: action.payload || [],
			};
		case SET_STORE_INVENTORY:
			return {
				...state,
				storeInventory: action.payload || [],
			};
		case SET_STORE_INDENTATION:
			return {
				...state,
				storeIndentation: action.payload.data || [],
				indentationPage: action.payload.page || 1,
				indentationTotal: action.payload.total || 0,
			};
		case SET_STORE_SALES:
			return {
				...state,
				storeSales: action.payload.data || [],
				salesPage: action.payload.page || 0,
				salesTotal: action.payload.total || 0,
			};
		case SET_PATIENT_STORE_ASSIGN:
			return {
				...state,
				patientStoreAssign: action.payload || [],
			};
		default:
			return state;
	}
};

export default ipdReducer;
