import {
	SET_LAB_VENDORS,
	LOADING_LAB_VENDORS,
	CREATE_LAB_VENDOR,
	UPDATE_LAB_VENDOR,
	DELETE_LAB_VENDOR,
	SET_LAB_TRANSACTIONS,
	LOADING_LAB_TRANSACTIONS,
	CREATE_LAB_TRANSACTION,
	UPDATE_LAB_TRANSACTION,
	DELETE_LAB_TRANSACTION,
	SET_LAB_LOGS,
} from './types';

const initialState = {
	vendors: [],
	transactions: [],
	logs: [],
	error: null,
	loading: false,
};

const labReducer = (state = initialState, action) => {
	switch (action.type) {
		case LOADING_LAB_VENDORS:
			return {
				...state,
				loading: true,
			};

		case SET_LAB_VENDORS:
			return {
				...state,
				vendors: action.payload || [],
				loading: false,
			};

		case CREATE_LAB_VENDOR:
			return {
				...state,
				vendors: [action.payload, ...state.vendors],
			};

		case UPDATE_LAB_VENDOR:
			state.vendors = state.vendors.filter((o) => o.id != action.payload.id);
			return {
				vendors: [action.payload, ...state.vendors],
				...state,
			};

		case DELETE_LAB_VENDOR:
			state.vendors = state.vendors.filter((ig) => ig.id != action.payload);
			return {
				...state,
			};

		case LOADING_LAB_TRANSACTIONS:
			return {
				...state,
				loading: true,
			};

		case SET_LAB_TRANSACTIONS:
			return {
				...state,
				transactions: action.payload || [],
				loading: false,
			};

		case CREATE_LAB_TRANSACTION:
			return {
				...state,
				transactions: [action.payload, ...state.transactions],
			};

		case UPDATE_LAB_TRANSACTION:
			state.transactions = state.transactions.filter((o) => o.id != action.payload.id);
			return {
				transactions: [action.payload, ...state.transactions],
				...state,
			};

		case DELETE_LAB_TRANSACTION:
			state.transactions = state.transactions.filter((ig) => ig.id != action.payload);
			return {
				...state,
			};

		case SET_LAB_LOGS:
			return {
				...state,
				logs: action.payload || [],
			};

		default:
			return state;
	}
};

export default labReducer;
