import {
	SET_APPOINTMENTS,
	LOADING_APPOINTMENTS,
	CREATE_APPOINTMENT,
	UPDATE_APPOINTMENT,
	DELETE_APPOINTMENT,
	SET_CALENDAR_APPOINTMENTS,
} from './types';

const initialState = {
	calendarAppointments: [],
	appointments: [],
	error: null,
	loading: false,
};

const appointmentReducer = (state = initialState, action) => {
	switch (action.type) {
		case LOADING_APPOINTMENTS:
			return {
				...state,
				loading: true,
			};

		case SET_APPOINTMENTS:
			return {
				...state,
				appointments: action.payload || [],
				loading: false,
			};

		case SET_CALENDAR_APPOINTMENTS:
			return {
				...state,
				calendarAppointments: action.payload || [],
				loading: false,
			};

		case CREATE_APPOINTMENT:
			return {
				...state,
				appointments: [action.payload, ...state.appointments],
			};

		case UPDATE_APPOINTMENT:
			const appointmentIdx = _.findIndex(
				state.appointments,
				(i) => i.id === action.payload.appointmentId,
			);
			return {
				...state,
				appointments: [
					...state.appointments.slice(0, appointmentIdx),
					action.payload,
					...state.appointments.slice(appointmentIdx + 1),
				],
			};

		case DELETE_APPOINTMENT:
			return {
				...state,
				appointments: _.reject(state.appointments, (org) => org.id === action.payload),
			};

		default:
			return state;
	}
};

export default appointmentReducer;
