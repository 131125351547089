import {
	SET_DEPARTMENTS,
	CREATE_DEPARTMENT,
	UPDATE_DEPARTMENT,
	DELETE_DEPARTMENT,
	SET_DEPARTMENT_ERROR,
	SET_SELECTED_DEPARTMENT,
} from '../reducers/types';
import axios from '../helpers/axios';
import showNotification from '../components/extras/showNotification';
import { dashboardMenu } from '../menu';

export const getDepartments = () => async (dispatch) => {
	try {
		const res = await axios.get('/department');
		if (res.data.success) {
			const depts = res.data?.departments || [];
			dispatch({
				type: SET_DEPARTMENTS,
				payload: depts,
			});
			if (depts?.length > 0) setInitialDept(depts?.[0]);
		}
	} catch (err) {
		console.log(err);
	}
};

export const setInitialDept = (dept) => async (dispatch) => {
	if (dept) {
		dispatch({
			type: SET_SELECTED_DEPARTMENT,
			payload: dept,
		});
		return;
	}
	try {
		const res = await axios.get('/department');
		if (res.data.success) {
			dispatch({
				type: SET_DEPARTMENTS,
				payload: res.data.departments,
			});
			if (res.data?.departments?.length > 0) {
				dispatch({
					type: SET_SELECTED_DEPARTMENT,
					payload: res.data.departments?.[0],
				});
			}
		}
	} catch (err) {
		console.log(err);
	}
};

export const createDepartment = (newDep, navigate) => async (dispatch) => {
	try {
		const res = await axios.post('/department', newDep);
		if (res.data.success) {
			showNotification('Success', 'The department is created successfully', 'success');
			dispatch(getDepartments());
			navigate?.(`/${dashboardMenu.settings.subMenu.viewDepartments.path}`);
		}
	} catch (err) {
		console.log(err);
	}
};

export const updateDepartment = (updateBody, navigate) => async (dispatch) => {
	try {
		const res = await axios.patch('/department', updateBody);
		if (res.data.success) {
			// showNotification('Success', 'The department is updated successfully', 'success');
			dispatch(getDepartments());
			navigate?.(`/${dashboardMenu.settings.subMenu.viewDepartments.path}`);
		}
	} catch (err) {
		console.log(err);
	}
};

export const deleteDepartment = (departmentId) => async (dispatch) => {
	try {
		const res = await axios.delete(`/department/${departmentId}`);
		if (res.data.success) {
			showNotification('Success', 'The department has been deleted successfully', 'success');
			dispatch({
				type: DELETE_DEPARTMENT,
				payload: departmentId,
			});
		}
	} catch (err) {
		console.log(err);
	}
};

// Helpers
export const deleteDepartmentImage = async (departmentId) => {
	try {
		const res = await axios.delete(`/department/image/${departmentId}`);
		if (res.data.success) {
			showNotification('Success', 'The image has been deleted successfully', 'success');
		}
	} catch (err) {
		console.log(err);
	}
};
