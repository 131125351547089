import { SET_DOCTORS, CREATE_DOCTOR, UPDATE_DOCTOR, DELETE_DOCTOR } from '../reducers/types';
import axios from '../helpers/axios';
import showNotification from '../components/extras/showNotification';
import { dashboardMenu } from '../menu';
import { getUserData } from './profileActions';

export const getDoctors = () => async (dispatch) => {
	try {
		const res = await axios.get('/doctor/list-doctor');
		if (res.data.success) {
			dispatch({
				type: SET_DOCTORS,
				payload: res.data.vendors,
			});
		}
	} catch (err) {
		console.log(err);
	}
};

export const createDoctor =
	(newDoc, navigate, isStudent = false) =>
	async (dispatch) => {
		if (!newDoc.get('organisationIds[]')) {
			showNotification('Error', 'Please select organisation', 'danger');
			return;
		}
		// if (!newDoc.get('specialityIds[]')) {
		// 	showNotification('Error', 'Please select specialities', 'danger');
		// 	return;
		// }
		try {
			const res = await axios.post('/doctor/add-doctor', newDoc);
			if (res.data.success) {
				// showNotification('Success', 'The doctor is created successfully', 'success');
				dispatch(getDoctors());
				if (!isStudent) navigate(`/${dashboardMenu.settings.subMenu.viewDoctors.path}`);
				else navigate(`/${dashboardMenu.settings.subMenu.viewStudents.path}`);
			}
		} catch (err) {
			console.log(err);
		}
	};

export const updateDoctor =
	(updateBody, currentUser, navigate, isStudent = false) =>
	async (dispatch) => {
		try {
			const res = await axios.patch('/doctor/update-doctor', updateBody);
			if (res.data.success) {
				dispatch(getDoctors());
				if (currentUser?.Vendor_detail?.vendorId == updateBody.get('vendorId'))
					dispatch(getUserData());
				// showNotification('Success', 'The doctor is updated successfully', 'success');
				if (!isStudent) navigate(`/${dashboardMenu.settings.subMenu.viewDoctors.path}`);
				else navigate(`/${dashboardMenu.settings.subMenu.viewStudents.path}`);
			}
		} catch (err) {
			console.log(err);
		}
	};

export const addGoogleProfile = (googleProfileUrl) => async (dispatch) => {
	try {
		const res = await axios.post(`/doctor/profile/googleprofile`, { googleProfileUrl });
	} catch (error) {
		console.error(error);
	}
};

export const getGoogleProfile = async () => {
	try {
		const res = await axios.get(`/doctor/profile/googleprofile`);
		if (res.data.success) {
			return res.data.googleProfileUrl;
		}
	} catch (error) {
		console.error(error);
		return null;
	}
};

export const deleteDoctor = (doctorId) => async (dispatch) => {
	try {
		const res = await axios.delete('/doctor/delete-doctor', { data: { vendorId: doctorId } });
		if (res.data.success) {
			dispatch({
				type: DELETE_DOCTOR,
				payload: doctorId,
			});
			showNotification('Success', 'The doctor has been deleted successfully', 'success');
		}
	} catch (err) {
		console.log(err);
	}
};
