import {
	SET_ORGANISATIONS,
	DELETE_ORGANISATION,
	SET_SELECTED_ORGANISATION,
} from '../reducers/types';
import axios from '../helpers/axios';
import showNotification from '../components/extras/showNotification';
import { dashboardMenu } from '../menu';
import { setSelectedOrgBackend } from './helperActions';
import { store } from '../store/configureStore';

export const getOrganisations = () => async (dispatch) => {
	try {
		const res = await axios.get('/organisation-ehr');
		if (res.data.success) {
			dispatch({
				type: SET_ORGANISATIONS,
				payload: res.data.organisations,
			});
		}
	} catch (err) {
		console.log(err);
	}
};

export const getOrganisation = async (orgId) => {
	try {
		const res = await axios.get(`/organisation-ehr/${orgId}`);
		if (res.data.success) {
			return res.data.organisation;
		}
	} catch (err) {
		console.log(err);
		return null;
	}
};

export const setInitialOrganisation = (org) => async (dispatch) => {
	try {
		// IF ORG IN PARAMETER
		if (org) {
			// IF NEW N ALREADY SET ORG EQUAL
			// if (org.organisationId == store?.getState?.()?.profile?.selectedOrg?.organisationId)
			// 	return;

			// SET IN STATE
			dispatch({
				type: SET_SELECTED_ORGANISATION,
				payload: org,
			});

			// SET IN BACKEND
			await setSelectedOrgBackend(org.organisationId);

			// RELOAD IF UPDATE
			// if (isUpdate) setTimeout(() => window.location.reload(), 100);

			// IF NO PARAMETER
		} else {
			const selectedOrg = store?.getState?.()?.auth.user?.Vendor_organisations?.[0];

			dispatch({
				type: SET_SELECTED_ORGANISATION,
				payload: selectedOrg,
			});

			await setSelectedOrgBackend(selectedOrg?.oraganisationId);

			// RELOAD IF UPDATE
			// if (isUpdate) setTimeout(() => window.location.reload(), 100);
		}
	} catch (err) {
		console.log(err);
	}
};

export const createOrganisation = (newOrg, navigate) => async (dispatch) => {
	try {
		const res = await axios.post('/organisation-ehr', newOrg);
		if (res.data.success) {
			showNotification('Success', 'The organisation is created successfully', 'success');
			dispatch(getOrganisations());
			navigate(`/${dashboardMenu.settings.subMenu.viewOrganisations.path}`);
		}
	} catch (err) {
		console.log(err);
	}
};

export const updateOrganisation = (updateBody, navigate) => async (dispatch, getState) => {
	try {
		const res = await axios.patch('/organisation-ehr', updateBody);
		if (res.data.success) {
			showNotification('Success', 'The organisation is updated successfully', 'success');
			dispatch(getOrganisations());

			const selectedOrg = getState().profile.selectedOrg;

			if (updateBody?.get('organisationId') == selectedOrg?.organisationId) {
				const resOrg = await getOrganisation(selectedOrg?.organisationId);
				console.log({ resOrg });
				dispatch(setInitialOrganisation(resOrg || selectedOrg));
			}

			if (navigate) navigate(`/${dashboardMenu.settings.subMenu.viewOrganisations.path}`);
		}
	} catch (err) {
		console.log(err);
	}
};

export const deleteOrganisation = (organisationId) => async (dispatch) => {
	try {
		const res = await axios.delete('/organisation-ehr', { data: { organisationId } });
		if (res.data.success) {
			dispatch({
				type: DELETE_ORGANISATION,
				payload: organisationId,
			});
			showNotification(
				'Success',
				'The organisation has been deleted successfully',
				'success',
			);
		}
	} catch (err) {
		console.log(err);
	}
};
