import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

import Button from '../../../components/bootstrap/Button';
import Card, {
	CardBody,
	CardFooter,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../components/bootstrap/Card';
import Modal, {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
} from '../../../components/bootstrap/Modal';

import {
	deletePatient,
	getPatientAppointments,
	getPatientExaminations,
	getPatientPrescriptions,
	getPatientTreatments,
	getTreatmentPlans,
	sendReviewLink,
} from '../../../actions/patientActions';
import InputGroup, { InputGroupText } from '../../../components/bootstrap/forms/InputGroup';
import Input from '../../../components/bootstrap/forms/Input';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import ReactSelect from 'react-select';
import { getDoctors, getGoogleProfile } from '../../../actions/doctorActions';
import { SET_REPORTS_MODAL } from '../../../reducers/types';
import { isPermitted } from '../../../actions/helperActions';

const consentData = [
	{
		title: 'Examination and X-rays',
		text: 'I understand that the initial visit may require radiographs in order to complete the examination, diagnosis, and treatment plan.',
	},
	{
		title: 'Drugs, Medication and Sedation',
		text: 'I have been informed and understand that antibiotic, analgesics, and other medications can cause allergic reactions causing redness, swelling of tissues, pain, itching, vomiting, and/or anaphylactic shock (severe allergic reaction). They may cause drowsiness and lack of awareness and coordination, which can be increased by the use of alcohol or other drugs. I understand that and fully agree not to operate any vehicle or hazardous device for at least 12 hours or until fully recovered from the effects of the anesthetic medication and drugs that may have been given me in the office for my treatment. I understand that failure to take medications prescribed for me in the manner prescribed may offer risks of continued or aggravated infection, pain, and potential resistance to effect treatment of my condition. I understand that antibiotics can reduce the effectiveness of oral contraceptives.',
	},
	{
		title: 'Changes in Treatment Plan',
		text: 'I understand that during treatment, it may be necessary to change or add procedures because of conditions found while working on teeth that were not discovered during examination, the most common being root canal therapy following routine restorative procedures. I give my permission to the Dentist to make any or all changes and additions as necessary.',
	},
	{
		title: 'Temporomandibular Joint Dysfunctions (TMJ)',
		text: 'I understand that symptoms of popping, clicking, locking and pain can intensify or develop in the joint of the lower (near the ear) subsequent to routine dental treatment wherein the mouth is held in the open position. However, symptoms of TMJ associated with dental treatment are usually transitory in nature and well tolerated by most patients. I understand that should the need for treatment arise, then I will be referred to a specialist for treatment, and the cost of which is my responsibility.',
	},
	{
		title: 'Fillings',
		text: 'I understand that care must be exercised in chewing on filling during the first 24 hours to avoid breakage, and tooth sensitivity is common after-effect of a newly placed filling.',
	},
	{
		title: 'Removal of Teeth (Extraction)',
		text: 'Alternative to removal has been explained to me (root canal therapy, crowns, periodontal surgery, etc.) and I authorize the Dentist to remove the following teeth and any others necessary for the reasons in paragraph #3. I understand removing teeth does not always remove all infection if present and it may be necessary to have further treatment. I understand the risks involved is having teeth removed, some of which are pain, swelling, and spread of infection, dry socket, loss of feeling in my teeth, lips, tongue, and surrounding tissue (parathesia) that can last for an indefinite period of time or fractured jaw. I understand I may need further treatment by a specialist or even hospitalization if complications arise during or following treatment, the cost of which is my responsibility.',
	},
	{
		title: 'Crowns, Bridges, Veneers and Bonding',
		text: 'I understand that sometimes it is not possible to match the color of natural teeth exactly with artificial teeth. I further understand that I may be wearing temporary crowns, which may come off easily and that I must be careful to ensure that they are kept on until the permanent crowns are delivered. I realized that the final opportunity to make changes in my new crowns, bridge or cap (including shape, fit, size, placement, and color) will be done before cementation. It has been explained to me that, in very few cases, cosmetic procedures may result in the need for future root canal treatment, which cannot always be predicted or anticipated. I understand that cosmetic procedures may affect tooth surfaces and may require modification of daily cleaning procedures.',
	},
	{
		title: 'Dentures – Complete or Partial',
		text: 'I realize that full or partial dentures are artificial, constructed of plastic, metal and or porcelain. The problems of wearing those appliances have been explained to me including looseness, soreness, and possible breakage. I realize the final opportunity to make changes in my new denture (including shape, fit, size, placement, and color) will be “teeth in wax” try-in visit. I understand that most dentures require relining approximately three to twelve months after initial placement. The cost for this procedure is not the initial denture fee.',
	},
	{
		title: 'Endodontic Treatment (Root Canal)',
		text: 'I realize there is no guarantee that root canal treatment will save my tooth and those complications can occur from the treatment and that occasionally metal objects are cemented in the tooth, or extend through the root, which does not necessarily affect the success of the treatment. I understand that occasionally additional surgical procedures may be necessary following root canal treatment (apicoectomy).',
	},
	{
		title: 'Periodontal Treatment',
		text: 'I understand that I have a serious condition causing gum inflammation and/or bone loss and that it can lead to the loss of my teeth. Alternative treatment plans have been explained to me, including non-surgical cleaning, gum surgery and/or extractions. I understand the success of a treatment depends in part on my efforts to brush and floss daily, receive regular cleaning as directed, following a healthy diet, avoid tobacco products and follow other recommendations.',
	},
];

const Reports = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const user = useSelector((state) => state.auth.user);
	const selectedOrg = useSelector((state) => state.profile.selectedOrg);
	const patient = useSelector((state) => state.patient.patient);
	const examinations =
		useSelector((state) =>
			state.patient?.examinationRecords?.filter?.((i) => i.isVerify === 'Approved'),
		) || [];
	const prescriptions =
		useSelector((state) =>
			state.patient?.prescriptions?.filter?.((i) => i.isVerify === 'Approved'),
		) || [];
	const treatmentPlans =
		useSelector((state) =>
			state.patient?.treatmentPlans?.filter?.((i) => i.isVerify === 'Approved'),
		) || [];
	const treatments =
		useSelector((state) =>
			state.patient?.treatments?.filter?.((i) => i.isVerify === 'Approved'),
		) || [];
	const appointments = useSelector((state) => state.patient.appointments);
	const doctors = useSelector((state) => state.doctor.doctors);

	const [selectedDoctor, setSelectedDoctor] = useState({
		value: doctors?.[0],
		label: doctors?.[0]?.Vendor_detail?.name,
	});

	const [deleteWarn, setDeleteWarn] = useState(false);
	const [doctorGoogleUrl, setDoctorGoogleUrl] = useState('');
	const [staticFormDropdowm, setStaticFormDropdowm] = useState(false);
	const [shortDropdown, setShortDropdown] = useState(false);
	const [longDropDown, setLongDropDown] = useState(false);
	const [detailedDropDown, setDetailedDropDown] = useState(false);
	const [fromDate, setFromDate] = useState(moment().startOf('month').format('YYYY-MM-DD'));
	const [toDate, setToDate] = useState(moment().endOf('month').format('YYYY-MM-DD'));
	const resetDates = () => {
		setFromDate(moment().startOf('month').format('YYYY-MM-DD'));
		setToDate(moment().endOf('month').format('YYYY-MM-DD'));
	};

	const handleShortReportPrint = () => {
		navigate(`/patients/${patient?.id}/short-report`, {
			state: {
				patient,
				examinations: examinations?.filter((i) => i.date >= fromDate && i.date <= toDate),
				prescriptions: prescriptions?.filter((i) => i.date >= fromDate && i.date <= toDate),
				range: (fromDate + toDate).toString(),
			},
		});
	};

	const handleLongReportPrint = () => {
		navigate(`/patients/${patient?.id}/long-report`, {
			state: {
				patient,
				examinations: examinations?.filter((i) => i.date >= fromDate && i.date <= toDate),
				prescriptions: prescriptions?.filter((i) => i.date >= fromDate && i.date <= toDate),
				treatments: treatmentPlans?.filter((i) => i.date >= fromDate && i.date <= toDate),
				range: (fromDate + toDate).toString(),
			},
		});
	};

	const handleDetailedReportPrint = () => {
		navigate(`/patients/${patient?.id}/detailed-long-report`, {
			state: {
				patient,
				examinations: examinations?.filter((i) => i.date >= fromDate && i.date <= toDate),
				prescriptions: prescriptions?.filter((i) => i.date >= fromDate && i.date <= toDate),
				treatmentPlans: treatmentPlans?.filter(
					(i) => i.date >= fromDate && i.date <= toDate,
				),
				treatments: treatments?.filter((i) => i.date >= fromDate && i.date <= toDate),
				appointments: appointments?.filter(
					(i) =>
						moment(i.appointmentDate) >= moment(fromDate) &&
						moment(i.appointmentDate) <= moment(toDate),
				),
				range: (fromDate + toDate).toString(),
			},
		});
	};

	const handleDeleteConfirm = () => {
		if (patient) dispatch(deletePatient(patient?.id, selectedOrg?.organisationId, navigate));
		dispatch({
			type: SET_REPORTS_MODAL,
			payload: false,
		});
		setDeleteWarn(false);
	};

	const sendLinkToWhatsapp = async (type, url) => {
		dispatch(
			sendReviewLink(
				patient?.id,
				type === 'doctor'
					? `Dr. ${user?.Vendor_detail?.name}`
					: `${selectedOrg?.Organisation?.name}`,
				url,
			),
		);
	};

	useEffect(() => {
		let mounted = true;

		const fetchOthers = async () => {
			if (mounted) {
				// dispatch(getPatient(id));
				dispatch(getPatientTreatments(patient?.id));
				dispatch(getTreatmentPlans(patient?.id));
				dispatch(getPatientExaminations(patient?.id));
				dispatch(getDoctors());
				getGoogleProfile().then((res) => setDoctorGoogleUrl(res));
			}
		};

		fetchOthers();

		return () => {
			mounted = false;
		};
	}, [patient?.id]);

	return (
		<>
			<Card className='h-100'>
				<CardHeader>
					<CardLabel icon='Person' iconColor='primary'>
						<CardTitle>Patient Details</CardTitle>
					</CardLabel>
				</CardHeader>
				<CardBody isScrollable>
					<div className='row g-3'>
						<div className='col-12'>
							<Button
								icon='print'
								color='primary'
								className='w-100 p-3 text-nowrap'
								isLight={true}
								onClick={() => {
									dispatch(getPatientPrescriptions(patient?.id));
									setShortDropdown(true);
								}}>
								Short Report
							</Button>
						</div>
						<div className='col-12'>
							<Button
								icon='print'
								color='primary'
								className='w-100 p-3 text-nowrap'
								isLight={true}
								onClick={() => {
									dispatch(getPatientPrescriptions(patient?.id));
									setLongDropDown(true);
								}}>
								Long Report
							</Button>
						</div>
						<div className='col-12'>
							<Button
								icon='print'
								color='primary'
								className='w-100 p-3 text-nowrap'
								isLight={true}
								onClick={() => {
									dispatch(getPatientPrescriptions(patient?.id));
									dispatch(getPatientAppointments(patient?.id));
									setDetailedDropDown(true);
								}}>
								Detailed Long Report
							</Button>
						</div>
						<div className='col-12'>
							<Button
								icon='print'
								color='primary'
								className='w-100 p-3 text-nowrap'
								isLight={true}
								onClick={() => {
									setStaticFormDropdowm(true);
								}}>
								Consent Form
							</Button>
						</div>
						{doctorGoogleUrl?.length > 0 && (
							<div className='col-12'>
								<Button
									icon='google'
									color='primary'
									className='w-100 p-3 text-nowrap'
									isLight={true}
									onClick={() => {
										sendLinkToWhatsapp('doctor', doctorGoogleUrl);
									}}>
									Send Google Review Link
								</Button>
							</div>
						)}

						{user.employeeType === 'hod' && (
							<Button
								icon='Delete'
								color='danger'
								isLight
								className='w-100 p-3'
								onClick={() =>
									isPermitted('Patients', 'delete', () => setDeleteWarn(true))
								}>
								Delete Patient
							</Button>
						)}
					</div>
				</CardBody>
			</Card>

			<Modal
				isOpen={deleteWarn}
				setIsOpen={() => {}}
				titleId={'deletePatient'}
				isCentered
				isAnimation={false}>
				<ModalHeader setIsOpen={setDeleteWarn}>
					<ModalTitle id='deleteOrgLabel'>Warning</ModalTitle>
				</ModalHeader>
				<ModalBody>Are you sure you want to delete the patient?</ModalBody>
				<ModalFooter>
					<Button
						color='primary'
						isOutline
						className='border-0'
						onClick={() => setDeleteWarn(false)}>
						Cancel
					</Button>
					<Button
						color='danger'
						icon='delete'
						onClick={() => isPermitted('Patients', 'delete', handleDeleteConfirm)}>
						Delete
					</Button>
				</ModalFooter>
			</Modal>
			<Modal
				isOpen={shortDropdown}
				setIsOpen={() => {}}
				titleId={'deleteRec'}
				isCentered
				isAnimation={false}>
				<ModalHeader setIsOpen={setShortDropdown}>
					<ModalTitle id='deleteRecLabel'>Select Date Range</ModalTitle>
				</ModalHeader>
				<ModalBody>
					<Card>
						<div className='row'>
							<div className='col-12'>
								<InputGroup>
									<Input
										type='date'
										id='minDate'
										ariaLabel='From Date'
										placeholder='From'
										value={fromDate}
										onChange={(e) => setFromDate(e.target.value)}
									/>
									<InputGroupText>to</InputGroupText>
									<Input
										type='date'
										id='maxDate'
										ariaLabel='To Date'
										placeholder='To'
										value={toDate}
										onChange={(e) => setToDate(e.target.value)}
									/>
								</InputGroup>
							</div>
						</div>
					</Card>
				</ModalBody>
				<ModalFooter>
					<div className='row w-100'>
						<div className='col-6'>
							<Button
								color='primary'
								isOutline
								className='w-100'
								onClick={resetDates}>
								Reset
							</Button>
						</div>
						<div className='col-6'>
							<Button
								color='primary'
								className='w-100'
								onClick={handleShortReportPrint}>
								Print
							</Button>
						</div>
					</div>
				</ModalFooter>
			</Modal>
			<Modal
				isOpen={longDropDown}
				setIsOpen={() => {}}
				titleId={'deleteRec'}
				isCentered
				isAnimation={false}>
				<ModalHeader setIsOpen={setLongDropDown}>
					<ModalTitle id='deleteRecLabel'>Select Date Range</ModalTitle>
				</ModalHeader>
				<ModalBody>
					<Card>
						<div className='row'>
							<div className='col-12'>
								<InputGroup>
									<Input
										type='date'
										id='minDate'
										ariaLabel='From Date'
										placeholder='From'
										value={fromDate}
										onChange={(e) => setFromDate(e.target.value)}
									/>
									<InputGroupText>to</InputGroupText>
									<Input
										type='date'
										id='maxDate'
										ariaLabel='To Date'
										placeholder='To'
										value={toDate}
										onChange={(e) => setToDate(e.target.value)}
									/>
								</InputGroup>
							</div>
						</div>
					</Card>
				</ModalBody>
				<ModalFooter>
					<div className='row w-100'>
						<div className='col-6'>
							<Button
								color='primary'
								isOutline
								className='w-100'
								onClick={resetDates}>
								Reset
							</Button>
						</div>
						<div className='col-6'>
							<Button
								color='primary'
								className='w-100'
								onClick={handleLongReportPrint}>
								Print
							</Button>
						</div>
					</div>
				</ModalFooter>
			</Modal>
			<Modal
				isOpen={detailedDropDown}
				setIsOpen={() => {}}
				titleId={'deleteRec'}
				isCentered
				isAnimation={false}>
				<ModalHeader setIsOpen={setDetailedDropDown}>
					<ModalTitle id='deleteRecLabel'>Select Date Range</ModalTitle>
				</ModalHeader>
				<ModalBody>
					<Card>
						<div className='row'>
							<div className='col-12'>
								<InputGroup>
									<Input
										type='date'
										id='minDate'
										ariaLabel='From Date'
										placeholder='From'
										value={fromDate}
										onChange={(e) => setFromDate(e.target.value)}
									/>
									<InputGroupText>to</InputGroupText>
									<Input
										type='date'
										id='maxDate'
										ariaLabel='To Date'
										placeholder='To'
										value={toDate}
										onChange={(e) => setToDate(e.target.value)}
									/>
								</InputGroup>
							</div>
						</div>
					</Card>
				</ModalBody>
				<ModalFooter>
					<div className='row w-100'>
						<div className='col-6'>
							<Button
								color='primary'
								isOutline
								className='w-100'
								onClick={resetDates}>
								Reset
							</Button>
						</div>
						<div className='col-6'>
							<Button
								color='primary'
								className='w-100'
								onClick={handleDetailedReportPrint}>
								Print
							</Button>
						</div>
					</div>
				</ModalFooter>
			</Modal>
			<Modal
				isOpen={staticFormDropdowm}
				setIsOpen={() => {}}
				titleId={'deleteRec'}
				isCentered
				isAnimation={false}>
				<ModalHeader setIsOpen={setStaticFormDropdowm}>
					<ModalTitle id='deleteRecLabel'>Print Consent Form</ModalTitle>
				</ModalHeader>
				<ModalBody>
					<Card isScrollable>
						<CardBody>
							<div className='row'>
								<div className='col-12 mb-3'>
									<FormGroup label='Select Doctor'>
										<ReactSelect
											id='dcotors'
											className='form-control'
											styles={{
												control: () => ({
													position: 'relative',
													display: 'flex',
												}),
											}}
											value={selectedDoctor}
											options={doctors.map((i) => ({
												value: i,
												label: i?.Vendor_detail?.name,
											}))}
											onChange={(list) => setSelectedDoctor(list)}
										/>
									</FormGroup>
								</div>
								{consentData?.map((data) => (
									<div className='col-12 pb-3'>
										<Button
											color='primary'
											className='w-100'
											onClick={() => {
												navigate(`/patients/${patient?.id}/consent-form`, {
													state: {
														title: data.title,
														text: data.text,
														doctor: selectedDoctor?.value
															?.Vendor_detail,
														patient,
													},
												});
											}}>
											{data.title}
										</Button>
									</div>
								))}
								<div className='col-12 pb-3'>
									<Button
										color='primary'
										className='w-100'
										onClick={() => {
											navigate(
												`/patients/${patient?.id}/orthodontic-treatment-consent`,
												{
													state: {
														doctor: selectedDoctor?.value
															?.Vendor_detail,
														patient,
													},
												},
											);
										}}>
										Orthodontic Treatment Consent
									</Button>
								</div>
							</div>
						</CardBody>
						<CardFooter>
							<div className='row w-100'>
								<div className='col-8'></div>
								<div className='col-4'>
									<Button
										color='danger'
										isOutline
										className='w-100'
										onClick={() => setStaticFormDropdowm(false)}>
										Cancel
									</Button>
								</div>
							</div>
						</CardFooter>
					</Card>
				</ModalBody>
			</Modal>
		</>
	);
};

export default Reports;
