import {
	SET_OPD_STATUSES,
	DELETE_OPD_STATUS,
	SET_CHIEF_COMPLAINTS,
	DELETE_CHIEF_COMPLAINT,
	SET_EXAMINATIONS,
	DELETE_EXAMINATION,
	SET_DIAGNOSES,
	DELETE_DIAGNOSIS,
	SET_COMMUNICATION_GROUPS,
	DELETE_COMMUNICATION_GROUP,
	SET_REFERRAL_TYPES,
	DELETE_REFERRAL_TYPE,
	SET_EXTERNAL_REFERRERS,
	DELETE_EXTERNAL_REFERRER,
	SET_NOTES,
	SET_TREATMENTS,
	DELETE_TREATMENT,
	SET_PRESCRIPTION_TEMPLATES,
	DELETE_PRESCRIPTION_TEMPLATE,
	DELETE_NOTE,
	SET_INVESTIGATIONS,
	DELETE_INVESTIGATION,
	SET_ORAL_EXAMINATIONS,
	DELETE_ORAL_EXAMINATION,
	SET_PATIENT_OCCUPATION,
	CREATE_CONSENT,
	DELETE_CONSENT,
	SET_CONSENTS_lIST,
} from '../reducers/types';
import axios from '../helpers/axios';
import showNotification from '../components/extras/showNotification';
import { toggleCreateLoading, toggleTableLoading } from './componentActions';

export const getOpdStatuses = () => async (dispatch) => {
	console.log('running getOpdStatuses');
	try {
		const res = await axios.get('/opd/get-organisation-opd-status');
		if (res.data.success) {
			dispatch({
				type: SET_OPD_STATUSES,
				payload: res.data.data,
			});
		}
	} catch (err) {
		console.log(err);
	}
};

export const createOpdStatus = (newStatus) => async (dispatch) => {
	try {
		const res = await axios.post('/opd-status', newStatus);
		if (res.data.success) {
			showNotification('Success', 'The status is created successfully', 'success');
			dispatch(getOpdStatuses());
		}
	} catch (err) {
		console.log(err);
	}
};

export const updateOpdStatus = (updateBody) => async (dispatch) => {
	try {
		const res = await axios.patch('/opd-status', updateBody);
		if (res.data.success) {
			showNotification('Success', 'The status is updated successfully', 'success');
			dispatch(getOpdStatuses());
		}
	} catch (err) {
		console.log(err);
	}
};

export const deleteOpdStatus = (statusId) => async (dispatch) => {
	try {
		const res = await axios.delete(`/opd-status/${statusId}`);
		if (res.data.success) {
			dispatch({
				type: DELETE_OPD_STATUS,
				payload: statusId,
			});
			showNotification('Success', 'The status has been deleted successfully', 'success');
		}
	} catch (err) {
		console.log(err);
	}
};

export const getChiefComplaints = () => async (dispatch) => {
	console.log('running getChiefComplaints');
	try {
		const res = await axios.get('/chief-complaint');
		if (res.data.success) {
			dispatch({
				type: SET_CHIEF_COMPLAINTS,
				payload: res.data.chiefComplaints,
			});
		}
	} catch (err) {
		console.log(err);
	}
};

export const createChiefComplaint = (newComplaint) => async (dispatch) => {
	try {
		const res = await axios.post('/chief-complaint', newComplaint);
		if (res.data.success) {
			showNotification('Success', 'The chief complaint is created successfully', 'success');
			dispatch(getChiefComplaints());
		}
	} catch (err) {
		console.log(err);
	}
};

export const createChiefComplaintNow = async (newComplaint) => {
	try {
		const res = await axios.post('/chief-complaint', newComplaint);
		if (res.data.success) {
			showNotification('Success', 'The chief complaint is created successfully', 'success');
			return res.data.chiefComplaint;
		}
	} catch (err) {
		console.log(err);
	}
};

export const updateChiefComplaint = (updateBody) => async (dispatch) => {
	try {
		const res = await axios.patch('/chief-complaint', updateBody);
		if (res.data.success) {
			showNotification('Success', 'The chief complaint is updated successfully', 'success');
			dispatch(getChiefComplaints());
		}
	} catch (err) {
		console.log(err);
	}
};

export const deleteChiefComplaint = (id) => async (dispatch) => {
	try {
		const res = await axios.delete(`/chief-complaint/${id}`);
		if (res.data.success) {
			dispatch({
				type: DELETE_CHIEF_COMPLAINT,
				payload: id,
			});
			showNotification(
				'Success',
				'The chief complaint has been deleted successfully',
				'success',
			);
		}
	} catch (err) {
		console.log(err);
	}
};

export const getExaminations = () => async (dispatch) => {
	console.log('running getExaminations');
	try {
		const res = await axios.get('/doctor/examination');
		if (res.data.success) {
			dispatch({
				type: SET_EXAMINATIONS,
				payload: res.data.examinations,
			});
		}
	} catch (err) {
		console.log(err);
	}
};

export const createExamination = (newExamination) => async (dispatch) => {
	try {
		const res = await axios.post('/doctor/examination', newExamination);
		if (res.data.success) {
			showNotification('Success', 'The examination is created successfully', 'success');
			dispatch(getExaminations());
		}
	} catch (err) {
		console.log(err);
	}
};

export const updateExamination = (updateBody) => async (dispatch) => {
	try {
		const res = await axios.patch('/doctor/examination', updateBody);
		if (res.data.success) {
			showNotification('Success', 'The examination is updated successfully', 'success');
			dispatch(getExaminations());
		}
	} catch (err) {
		console.log(err);
	}
};

export const deleteExamination = (id) => async (dispatch) => {
	try {
		const res = await axios.delete(`/doctor/examination/${id}`);
		if (res.data.success) {
			dispatch({
				type: DELETE_EXAMINATION,
				payload: id,
			});
			showNotification('Success', 'The examination has been deleted successfully', 'success');
		}
	} catch (err) {
		console.log(err);
	}
};

export const getDiagnoses = () => async (dispatch) => {
	console.log('running getDiagnoses');
	try {
		const res = await axios.get('/doctor/diagnosis');
		if (res.data.success) {
			dispatch({
				type: SET_DIAGNOSES,
				payload: res.data.diagnoses,
			});
		}
	} catch (err) {
		console.log(err);
	}
};

export const createDiagnosis = (newDiagnosis) => async (dispatch) => {
	try {
		const res = await axios.post('/doctor/diagnosis', newDiagnosis);
		if (res.data.success) {
			showNotification('Success', 'The diagnosis is created successfully', 'success');
			dispatch(getDiagnoses());
		}
	} catch (err) {
		console.log(err);
	}
};

export const createDiagnosisNow = async (data) => {
	try {
		const res = await axios.post('/doctor/diagnosis', data);
		if (res.data.success) {
			return res.data.data;
		}
	} catch (error) {
		console.error(error);
		return null;
	}
};

export const updateDiagnosis = (updateBody) => async (dispatch) => {
	try {
		const res = await axios.patch('/doctor/diagnosis', updateBody);
		if (res.data.success) {
			showNotification('Success', 'The diagnosis is updated successfully', 'success');
			dispatch(getDiagnoses());
		}
	} catch (err) {
		console.log(err);
	}
};

export const deleteDiagnosis = (id) => async (dispatch) => {
	try {
		const res = await axios.delete(`/doctor/diagnosis/${id}`);
		if (res.data.success) {
			dispatch({
				type: DELETE_DIAGNOSIS,
				payload: id,
			});
			showNotification('Success', 'The diagnosis has been deleted successfully', 'success');
		}
	} catch (err) {
		console.log(err);
	}
};

export const getCommunicationGroups = () => async (dispatch) => {
	console.log('running getCommunicationGroups');
	try {
		const res = await axios.get('/communication');
		if (res.data.success) {
			dispatch({
				type: SET_COMMUNICATION_GROUPS,
				payload: res.data.communicationGroups,
			});
		}
	} catch (err) {
		console.log(err);
	}
};

export const createCommunicationGroup = (newCommGroup) => async (dispatch) => {
	try {
		const res = await axios.post('/communication', newCommGroup);
		if (res.data.success) {
			showNotification(
				'Success',
				'The communication group is created successfully',
				'success',
			);
			dispatch(getCommunicationGroups());
		}
	} catch (err) {
		console.log(err);
	}
};

export const updateCommunicationGroup = (updateBody) => async (dispatch) => {
	try {
		const res = await axios.patch('/communication', updateBody);
		if (res.data.success) {
			showNotification(
				'Success',
				'The communication group is updated successfully',
				'success',
			);
			dispatch(getCommunicationGroups());
		}
	} catch (err) {
		console.log(err);
	}
};

export const deleteCommunicationGroup = (id) => async (dispatch) => {
	try {
		const res = await axios.delete(`/communication/${id}`);
		if (res.data.success) {
			dispatch({
				type: DELETE_COMMUNICATION_GROUP,
				payload: id,
			});
			showNotification('Success', 'The status has been deleted successfully', 'success');
		}
	} catch (err) {
		console.log(err);
	}
};

export const getReferralTypes = () => async (dispatch) => {
	console.log('running getReferralTypes');
	try {
		const res = await axios.get('/referral-type');
		if (res.data.success) {
			dispatch({
				type: SET_REFERRAL_TYPES,
				payload: res.data.referralTypes,
			});
		}
	} catch (err) {
		console.log(err);
	}
};

export const createReferralType = (newRefType) => async (dispatch) => {
	try {
		const res = await axios.post('/referral-type', newRefType);
		if (res.data.success) {
			showNotification('Success', 'The referral type is created successfully', 'success');
			dispatch(getReferralTypes());
		}
	} catch (err) {
		console.log(err);
	}
};

export const updateReferralType = (updateBody) => async (dispatch) => {
	try {
		const res = await axios.patch('/referral-type', updateBody);
		if (res.data.success) {
			showNotification('Success', 'The referral type is updated successfully', 'success');
			dispatch(getReferralTypes());
		}
	} catch (err) {
		console.log(err);
	}
};

export const deleteReferralType = (id) => async (dispatch) => {
	try {
		const res = await axios.delete(`/referral-type/${id}`);
		if (res.data.success) {
			dispatch({
				type: DELETE_REFERRAL_TYPE,
				payload: id,
			});
			showNotification(
				'Success',
				'The referral type has been deleted successfully',
				'success',
			);
		}
	} catch (err) {
		console.log(err);
	}
};

export const getExternalReferrers = () => async (dispatch) => {
	console.log('running getExternalReferrers');
	try {
		const res = await axios.get('/externalreferrer');
		if (res.data.success) {
			dispatch({
				type: SET_EXTERNAL_REFERRERS,
				payload: res.data.externalReferrers,
			});
		}
	} catch (err) {
		console.log(err);
	}
};

export const createExternalReferrer = (newExtRef) => async (dispatch) => {
	try {
		const res = await axios.post('/externalreferrer', newExtRef);
		if (res.data.success) {
			showNotification('Success', 'The external referrer is created successfully', 'success');
			dispatch(getExternalReferrers());
		}
	} catch (err) {
		console.log(err);
	}
};

export const updateExternalReferrer = (updateBody) => async (dispatch) => {
	try {
		const res = await axios.patch('/externalreferrer', updateBody);
		if (res.data.success) {
			showNotification('Success', 'The external referrer is updated successfully', 'success');
			dispatch(getExternalReferrers());
		}
	} catch (err) {
		console.log(err);
	}
};

export const deleteExternalReferrer = (id) => async (dispatch) => {
	try {
		const res = await axios.delete(`/externalreferrer/${id}`);
		if (res.data.success) {
			dispatch({
				type: DELETE_EXTERNAL_REFERRER,
				payload: id,
			});
			showNotification(
				'Success',
				'The external referrer has been deleted successfully',
				'success',
			);
		}
	} catch (err) {
		console.log(err);
	}
};

export const getTreatments = () => async (dispatch) => {
	console.log('running getTreatments');
	try {
		const res = await axios.get('/treatment?type=EHR');
		if (res.data.success) {
			dispatch({
				type: SET_TREATMENTS,
				payload: res.data.treatments,
			});
		}
	} catch (err) {
		console.log(err);
	}
};

export const createTreatment = (newTreatment) => async (dispatch) => {
	try {
		const res = await axios.post('/treatment', { ...newTreatment, type: 'EHR' });
		if (res.data.success) {
			showNotification('Success', 'The treatment is created successfully', 'success');
			dispatch(getTreatments());
		}
	} catch (err) {
		console.log(err);
	}
};

export const updateTreatment = (updateBody) => async (dispatch) => {
	try {
		const res = await axios.patch('/treatment', updateBody);
		if (res.data.success) {
			showNotification('Success', 'The treatment is updated successfully', 'success');
			dispatch(getTreatments());
		}
	} catch (err) {
		console.log(err);
	}
};

export const deleteTreatment = (id) => async (dispatch) => {
	try {
		const res = await axios.delete(`/treatment/${id}`);
		if (res.data.success) {
			dispatch({
				type: DELETE_TREATMENT,
				payload: id,
			});
			showNotification('Success', 'The treatment has been deleted successfully', 'success');
		}
	} catch (err) {
		console.log(err);
	}
};

export const getPrescriptionTemplates = () => async (dispatch) => {
	console.log('running getPrescriptionTemplates');
	try {
		const res = await axios.get('/prescription-template');
		if (res.data.success) {
			dispatch({
				type: SET_PRESCRIPTION_TEMPLATES,
				payload: res.data.prescriptionTemplates,
			});
		}
	} catch (err) {
		console.log(err);
	}
};

export const createPrescriptionTemplate = (newPrescTemp) => async (dispatch) => {
	try {
		const res = await axios.post('/prescription-template', newPrescTemp);
		if (res.data.success) {
			dispatch(getPrescriptionTemplates());
			showNotification(
				'Success',
				'The Prescription Template is created successfully',
				'success',
			);
		}
	} catch (err) {
		console.log(err);
	}
};

export const updatePrescriptionTemplate = (updateBody) => async (dispatch) => {
	try {
		const res = await axios.patch('/prescription-template', updateBody);
		if (res.data.success) {
			dispatch(getPrescriptionTemplates());
			showNotification(
				'Success',
				'The Prescription Template is updated successfully',
				'success',
			);
		}
	} catch (err) {
		console.log(err);
	}
};

export const deletePrescriptionTemplate = (id) => async (dispatch) => {
	try {
		const res = await axios.delete(`/prescription-template/${id}`);
		if (res.data.success) {
			dispatch({
				type: DELETE_PRESCRIPTION_TEMPLATE,
				payload: id,
			});
			showNotification(
				'Success',
				'The Prescription Template has been deleted successfully',
				'success',
			);
		}
	} catch (err) {
		console.log(err);
	}
};

export const deletePrescriptionTemplateList = (id) => async (dispatch) => {
	try {
		const res = await axios.delete(`/prescription-template/list/${id}`);
		if (res.data.success) {
			dispatch(getPrescriptionTemplates());
			showNotification(
				'Success',
				'The Prescription Template has been deleted successfully',
				'success',
			);
		}
	} catch (err) {
		console.log(err);
	}
};

export const getNotes = () => async (dispatch) => {
	console.log('running getNotes');
	try {
		const res = await axios.get('/notes');
		if (res.data.success) {
			dispatch({
				type: SET_NOTES,
				payload: res.data.notes,
			});
		}
	} catch (err) {
		console.log(err);
	}
};

export const createNote = (newNote) => async (dispatch) => {
	try {
		const res = await axios.post('/notes', newNote);
		if (res.data.success) {
			showNotification('Success', 'The note is created successfully', 'success');
			dispatch(getNotes());
		}
	} catch (err) {
		console.log(err);
	}
};

export const updateNote = (updateBody) => async (dispatch) => {
	try {
		const res = await axios.patch('/notes', updateBody);
		if (res.data.success) {
			showNotification('Success', 'The note is updated successfully', 'success');
			dispatch(getNotes());
		}
	} catch (err) {
		console.log(err);
	}
};

export const deleteNote = (id) => async (dispatch) => {
	try {
		const res = await axios.delete(`/notes/${id}`);
		if (res.data.success) {
			dispatch({
				type: DELETE_NOTE,
				payload: id,
			});
			showNotification('Success', 'The note has been deleted successfully', 'success');
		}
	} catch (err) {
		console.log(err);
	}
};

export const getInvestigations = () => async (dispatch) => {
	console.log('running getInvestigations');
	try {
		const res = await axios.get('/investigation');
		if (res.data.success) {
			dispatch({
				type: SET_INVESTIGATIONS,
				payload: res.data.investigations,
			});
		}
	} catch (err) {
		console.log(err);
	}
};

export const createInvestigation = (newInvestigation) => async (dispatch) => {
	try {
		const res = await axios.post('/investigation', newInvestigation);
		if (res.data.success) {
			showNotification('Success', 'The investigation is created successfully', 'success');
			dispatch(getInvestigations());
		}
	} catch (err) {
		console.log(err);
	}
};

export const updateInvestigation = (updateBody) => async (dispatch) => {
	try {
		const res = await axios.patch('/investigation', updateBody);
		if (res.data.success) {
			showNotification('Success', 'The investigation is updated successfully', 'success');
			dispatch(getInvestigations());
		}
	} catch (err) {
		console.log(err);
	}
};

export const deleteInvestigation = (id) => async (dispatch) => {
	try {
		const res = await axios.delete(`/investigation/${id}`);
		if (res.data.success) {
			dispatch({
				type: DELETE_INVESTIGATION,
				payload: id,
			});
			showNotification(
				'Success',
				'The investigation has been deleted successfully',
				'success',
			);
		}
	} catch (err) {
		console.log(err);
	}
};

export const getOralExaminations = () => async (dispatch) => {
	console.log('running getOralExaminations');
	try {
		const res = await axios.get('/oral-examination');
		if (res.data.success) {
			dispatch({
				type: SET_ORAL_EXAMINATIONS,
				payload: res.data.oralExaminations,
			});
		}
	} catch (err) {
		console.log(err);
	}
};

export const createOralExamination = (newExam) => async (dispatch) => {
	try {
		const res = await axios.post('/oral-examination', newExam);
		if (res.data.success) {
			showNotification('Success', 'The examination is created successfully', 'success');
			dispatch(getOralExaminations());
		}
	} catch (err) {
		console.log(err);
	}
};

export const updateOralExamination = (updateBody) => async (dispatch) => {
	try {
		const res = await axios.patch('/oral-examination', updateBody);
		if (res.data.success) {
			showNotification('Success', 'The examination is updated successfully', 'success');
			dispatch(getOralExaminations());
		}
	} catch (err) {
		console.log(err);
	}
};

export const deleteOralExamination = (id) => async (dispatch) => {
	try {
		const res = await axios.delete(`/oral-examination/${id}`);
		if (res.data.success) {
			dispatch({
				type: DELETE_ORAL_EXAMINATION,
				payload: id,
			});
			showNotification('Success', 'The examination has been deleted successfully', 'success');
		}
	} catch (err) {
		console.log(err);
	}
};

export const getOccupations = () => async (dispatch) => {
	try {
		const res = await axios.get(`/doctor/patient/occupation`);
		if (res.data.success) {
			dispatch({
				type: SET_PATIENT_OCCUPATION,
				payload: res.data.occupations,
			});
		}
	} catch (error) {
		console.error(error);
	}
};

export const getConsentForms = () => async (dispatch) => {
	try {
		const res = await axios.get(`/doctor/consent`);
		if (res.data.success) {
			dispatch({
				type: SET_CONSENTS_lIST,
				payload: res?.data?.consents,
			});
		}
	} catch (error) {
		console.error(error);
	} finally {
		dispatch(toggleTableLoading(false));
	}
};

export const createConsentForm = (data, setModal) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	try {
		const res = await axios.post(`/doctor/consent`, data);
		if (res.data.success) {
			showNotification('Success', 'The Habit is created successfully', 'success');
			setModal(false);
			dispatch({
				type: CREATE_CONSENT,
				payload: res.data.consent,
			});
			// dispatch(getOrgExpenses());
		}
	} catch (err) {
		console.log(err);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const updateConsentForm = (data, setModal) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	try {
		const res = await axios.patch(`/doctor/consent`, data);
		if (res.data.success) {
			showNotification('Success', 'The habit is updated successfully', 'success');
			dispatch(getConsentForms());
			setModal(false);
		}
	} catch (err) {
		console.log(err);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const deleteConsentForm = (id) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	try {
		const res = await axios.delete(`/doctor/consent/${id}`);
		if (res.data.success) {
			showNotification('Success', 'The habit is deleted successfully', 'success');
			dispatch({
				type: DELETE_CONSENT,
				payload: id,
			});
		}
	} catch (err) {
		console.log(err);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};
