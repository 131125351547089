import * as React from 'react';

function Tooth(props) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			fill='currentColor'
			className='svg-icon'
			viewBox='0 0 32 32'
			{...props}>
			<path d='M4.428 20.351c.336.805.621 2.198.896 3.546.379 1.856.708 3.459 1.262 4.292.1.149.195.305.291.462.251.408.535.872.914 1.276.556.595 1.259.952 1.978 1.006a2.843 2.843 0 0 0 1.93-.602c.707-.557.971-1.302 1.183-1.901l.1-.277c.07-.186.139-.358.207-.526.164-.409.333-.832.474-1.349 1.307-4.809 2.143-5.242 2.286-5.278h.137c.437.004.53 0 .822.638.527 1.15.979 2.49 1.464 4.345.291 1.11.578 2.065.931 3.099.422 1.236 1.302 1.917 2.477 1.917 1.582 0 2.824-1.608 3.62-2.818.515-.783.924-2.302 1.538-4.711.357-1.404.727-2.855 1.06-3.656.494-1.187.934-2.139 1.312-2.958C30.363 14.57 31 13.19 31 10.728c0-2.513-.857-4.897-2.412-6.712-1.67-1.951-3.974-3.025-6.487-3.025-2.009 0-3.979 1.133-5.416 1.96-.089.051-.201.121-.322.196-.087.055-.216.135-.333.203-.313-.173-.57-.317-.71-.398-1.435-.828-3.4-1.961-5.421-1.961-2.513 0-4.816 1.074-6.487 3.023C1.857 5.829 1 8.21 1 10.718c0 2.424.79 4.04 1.882 6.277.466.952.993 2.032 1.546 3.356zm.503-15.037C6.216 3.815 7.98 2.99 9.899 2.99c1.485 0 3.183.979 4.423 1.695 3.472 1.998 3.878 1.998 4.052 1.998a1 1 0 0 0 .233-1.973 8.154 8.154 0 0 1-.525-.255c1.192-.678 2.696-1.464 4.018-1.464 1.919 0 3.684.826 4.968 2.326C28.314 6.77 29 8.691 29 10.728c0 2.023-.504 3.115-1.508 5.289a82.345 82.345 0 0 0-1.343 3.028c-.389.936-.76 2.391-1.152 3.931-.402 1.58-.902 3.546-1.27 4.105-1.171 1.78-1.788 1.918-1.949 1.918-.184 0-.392 0-.584-.563a41.11 41.11 0 0 1-.889-2.959c-.516-1.972-1.004-3.412-1.581-4.671C17.963 19.142 16.965 19 16.058 19h-.11c-1.976 0-3.114 2.7-4.215 6.754-.11.404-.245.74-.4 1.128-.072.18-.146.366-.222.566l-.113.313c-.175.496-.295.809-.534.997a.803.803 0 0 1-.542.18c-.216-.017-.46-.154-.67-.379-.24-.257-.449-.596-.669-.956-.111-.177-.22-.353-.333-.523-.338-.507-.687-2.212-.967-3.583-.294-1.441-.599-2.932-1.01-3.916-.575-1.378-1.117-2.486-1.594-3.464C3.668 14.047 3 12.68 3 10.718c0-2.031.686-3.95 1.931-5.404z' />
		</svg>
	);
}
export default Tooth;
