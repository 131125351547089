import React, { useEffect, useState } from 'react';
import Modal, {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
} from '../components/bootstrap/Modal';
import Button from '../components/bootstrap/Button';

// Popup to show a notification when a new patient is assigned to a student and to be verified
const NewPatientPopup = ({ isOpenModal, setIsOpenModal }) => {
	return (
		<Modal isOpen={isOpenModal} setIsOpen={setIsOpenModal}>
			<ModalHeader setIsOpen={setIsOpenModal}>
				<ModalTitle className='d-flex align-items-end'>New OPD Added</ModalTitle>
			</ModalHeader>
			<ModalBody>A new patient is added</ModalBody>
			<ModalFooter>
				<Button icon='Close' color='danger' isLight onClick={() => setIsOpenModal(false)}>
					Reject
				</Button>
				<Button
					icon='DoneOutline'
					color='success'
					isLight
					onClick={() => {
						setIsOpenModal(false);
					}}>
					Accept
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default NewPatientPopup;
