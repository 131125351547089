import React, { Suspense, useState } from 'react';
import Card, { CardBody } from '../../components/bootstrap/Card';
import PageWrapper from '../PageWrapper/PageWrapper';
import Page from '../Page/Page';
import SubHeader from '../SubHeader/SubHeader';
import ContentRoutes from './ContentRoutes';

import Button from '../../components/bootstrap/Button';

import ChatIcon from '../../assets/img/chatbot.png';
import InsulinBot from '../Bots/InsulinBot';
import AdrenalBot from '../Bots/AdrenalBot';

const _loading = (
	<PageWrapper>
		<SubHeader>
			<div />
		</SubHeader>
		<Page>
			<div className='row h-100'>
				<div className='col-lg-6'>
					<Card stretch>
						<CardBody>
							<div />
						</CardBody>
					</Card>
				</div>
				<div className='col-lg-6'>
					<Card stretch='semi'>
						<CardBody>
							<div />
						</CardBody>
					</Card>
					<Card stretch='semi'>
						<CardBody>
							<div />
						</CardBody>
					</Card>
				</div>
			</div>
		</Page>
	</PageWrapper>
);

const Content = () => {
	const [chatBot, setChatbot] = useState(false);
	const [whichBot, setWhichBot] = useState(null);

	const handleOpenChatbot = (type) => {
		switch (type) {
			case 'insulin':
				setWhichBot('insulin');
				break;

			case 'adrenal':
				setWhichBot('adrenal');
				break;
		}
		setChatbot(false);
	};

	const handleWholeBotClose = () => {
		setChatbot(false);
		setWhichBot(null);
	};

	return (
		<main className='content'>
			<Suspense fallback={_loading}>
				<ContentRoutes />
			</Suspense>
			{/* <div className='chatbot'>
				{chatBot && (
					<div className='chatbot-dropdown'>
						<ul>
							<li>
								<Button
									className='inline-buttons'
									onClick={() => handleOpenChatbot('adrenal')}>
									Adrenal Insufficiency Steroid Dose Calculator
								</Button>
							</li>
						</ul>
					</div>
				)}
				{whichBot == 'insulin' && !chatBot && <InsulinBot />}
				{whichBot == 'adrenal' && !chatBot && (
					<AdrenalBot handleWholeBotClose={handleWholeBotClose} whichBot={whichBot} />
				)}

				<Button
					className='chatbot-btn'
					onClick={() => {
						setChatbot(!chatBot);
						setWhichBot(null);
					}}>
					<img src={ChatIcon} alt='chatbot' />
				</Button>
			</div> */}
		</main>
	);
};

export default Content;
