import {
	SET_PATIENTS,
	DELETE_PATIENT,
	SET_PATIENT_EXAMINATIONS,
	DELETE_PATIENT_EXAMINATION,
	SET_PATIENT_TREATMENT_PLANS,
	DELETE_PATIENT_TREATMENT_PLAN,
	SET_PATIENT_TREATMENTS,
	DELETE_PATIENT_TREATMENT,
	SET_PATIENT_PRESCRIPTIONS,
	DELETE_PATIENT_PRESCRIPTION,
	SET_PATIENT_BILLS,
	UPDATE_PATIENT_BILL,
	DELETE_PATIENT_BILL,
	SET_PATIENT_PAYMENTS,
	UPDATE_PATIENT_PAYMENT,
	DELETE_PATIENT_PAYMENT,
	SET_PATIENT,
	LOADING_PATIENTS,
	SET_PATIENT_APPOINTMENTS,
	SET_PATIENT_DOCUMENTS,
	SET_TIMINGS,
	SET_PATIENT_PICKLIST,
	SET_MEDICAL_ALERTS,
	SET_CHIEF_COMPLAINTS_VALUES,
	SET_CC_SYMPTOMS,
	SET_PATIENT_CASE_SHEETS,
	DELETE_PATIENT_CASE_SHEET,
	SET_WHITEBOARD,
	SET_CLINICAL_NOTES,
	SET_SOAP_ATTACHMENTS,
	SET_PATIENT_REFUND_PAYMENTS,
	SET_PATIENT_VISITS,
} from '../reducers/types';
import axios from '../helpers/axios';
import Axios from 'axios';
import showNotification from '../components/extras/showNotification';
import { dashboardMenu } from '../menu';

export const getPatientsByDept = (organisationId, departmentId) => async (dispatch) => {
	console.log('running getPatientsByDept');

	var orgId = organisationId;
	try {
		if (!organisationId) {
			const res = await axios.get('/organisation-ehr');
			if (res.data.success) {
				if (res.data.organisations.length > 0) {
					orgId = res.data.organisations[0].id;
				}
			}
		}
		const res = await axios.get('/doctor/patient/department-patient', {
			params: { departmentId, limit: 1000 },
		});
		if (res.data.success) {
			dispatch({
				type: SET_PATIENTS,
				payload: res.data.users,
			});
		}
	} catch (err) {
		console.log(err);
	}
};

export const getPatients = (organisationId) => async (dispatch) => {
	console.log('running getPatients');

	var orgId = organisationId;
	try {
		if (!organisationId) {
			const res = await axios.get('/organisation-ehr');
			if (res.data.success) {
				if (res.data.organisations.length > 0) {
					orgId = res.data.organisations[0].id;
				}
			}
		}
		const res = await axios.get('/doctor/patient/list', {
			params: { organisationId: orgId, pagination: false },
		});
		if (res.data.success) {
			dispatch({
				type: SET_PATIENTS,
				payload: res.data.data,
			});
		}
	} catch (err) {
		console.log(err);
	}
};

export const getPatient = (patientId) => async (dispatch) => {
	try {
		dispatch({
			type: LOADING_PATIENTS,
		});
		const res = await axios.get(`/doctor/patient/${patientId}`);
		if (res.data.success) {
			dispatch({
				type: SET_PATIENT,
				payload: res.data.user,
			});
		}
	} catch (err) {
		console.log(err);
	}
};

export const getAdjacentOpd = async (patientId, date) => {
	try {
		console.log('running getAdjacentOpd');
		const res = await axios.get(`/opd/patient?userId=${patientId}&date=${date}`);
		if (res.data.success) {
			return res.data.opd;
		}
	} catch (err) {
		console.log(err);
	}
	return null;
};

export const createPatient = async (newPatient, dispatch, deptId) => {
	try {
		const res = await axios.post('/doctor/patient/create-department-patient', newPatient);
		if (res.data.success) {
			showNotification('Success', 'The patient is created successfully', 'success');
			if (!deptId) {
				dispatch(getPatients(newPatient.get('organisationId')));
				return;
			}
			if (newPatient.get('organisationId'))
				dispatch(getPatientsByDept(newPatient.get('organisationId'), deptId));
			return res.data.userId;
		}
	} catch (err) {
		console.log(err);
	}
};

export const updatePatient = (updateBody, navigate, organisationId, deptId) => async (dispatch) => {
	try {
		const res = await axios.post('/doctor/patient/update', updateBody);
		if (res.data.success) {
			showNotification('Success', 'The patient is updated successfully', 'success');
			if (deptId) dispatch(getPatientsByDept(organisationId, deptId));
			else dispatch(getPatients(organisationId));
			navigate && navigate(`/${dashboardMenu.patients.subMenu.viewPatients.path}`);
		}
	} catch (err) {
		console.log(err);
	}
};

export const deletePatient = (patientId, organisationId, navigate) => async (dispatch) => {
	try {
		const res = await axios.delete('/doctor/patient', {
			data: { userId: patientId, organisationId },
		});
		if (res.data.success) {
			showNotification('Success', 'The patient has been deleted successfully', 'success');
			dispatch({
				type: DELETE_PATIENT,
				payload: patientId,
			});
			navigate(`/${dashboardMenu.patients.subMenu.viewPatients.path}`);
		}
	} catch (err) {
		console.log(err);
	}
};

export const transferPatientToDepartment = (data, departmentId, orgId) => async (dispatch) => {
	try {
		const res = await axios.post(`/doctor/patient/transfer-patient`, data);
		if (res.data.success) {
			dispatch(getPatientsByDept(orgId, departmentId));
		}
	} catch (error) {
		console.error(error);
	}
};

export const getPatientExaminations = (userId) => async (dispatch) => {
	try {
		dispatch({
			type: LOADING_PATIENTS,
		});
		console.log('running getExaminationRecords');
		const res = await axios.get(`/examination-record?userId=${userId}`);
		if (res.data.success) {
			dispatch({
				type: SET_PATIENT_EXAMINATIONS,
				payload: res.data.examinationRecords,
			});
		}
	} catch (err) {
		console.log(err);
	}
};

export const getPatientExaminationsByDept = (userId) => async (dispatch) => {
	try {
		dispatch({
			type: LOADING_PATIENTS,
		});
		console.log('running getExaminationRecords by dept');
		const res = await axios.get(
			`/examination-record/department-examination-record?userId=${userId}`,
		);
		if (res.data.success) {
			dispatch({
				type: SET_PATIENT_EXAMINATIONS,
				payload: res.data.examinationRecords,
			});
		}
	} catch (err) {
		console.log(err);
	}
};

export const createPatientExamination =
	(newPatientExam, isDept = null) =>
	async (dispatch) => {
		try {
			const res = await axios.post('/examination-record', newPatientExam);
			if (res.data.success) {
				showNotification('Success', 'The examination is created successfully', 'success');
				// if (isDept) dispatch(getPatientExaminationsByDept(newPatientExam.userId));
				dispatch(getPatientExaminations(newPatientExam.userId));
				// dispatch({
				// 	type: CREATE_PATIENT_EXAMINATION,
				// 	payload: newPatientExam,
				// });
			}
		} catch (err) {
			console.log(err);
		}
	};

export const updatePatientExamination =
	(recordId, updateBody, isDept = null) =>
	async (dispatch) => {
		try {
			const res = await axios.patch(`/examination-record`, {
				...updateBody,
				examinationRecordId: recordId,
			});
			if (res.data.success) {
				showNotification('Success', 'The examination is updated successfully', 'success');
				dispatch(getPatientExaminations(updateBody.userId));
				// if (isDept) dispatch(getPatientExaminationsByDept(updateBody.userId));
				dispatch(getPatientExaminations(updateBody.userId));
			}
		} catch (err) {
			console.log(err);
		}
	};

export const updatePatientExaminationNote = (patientId, updateBody) => async (dispatch) => {
	try {
		const res = await axios.post('/examination-record/note', updateBody);
		if (res.data.success) {
			showNotification('Success', 'The examination note is updated successfully', 'success');
			dispatch(getPatientExaminations(patientId));
		}
	} catch (err) {
		console.log(err);
	}
};

export const deletePatientExaminationRow = (recordId, userId) => async (dispatch) => {
	try {
		const res = await axios.delete(
			`/examination-record?userId=${userId}&examinationRecordListId=${recordId}`,
		);
		if (res.data.success) {
			dispatch(getPatientExaminations(userId));
			showNotification(
				'Success',
				'The prescription row has been deleted successfully',
				'success',
			);
		}
	} catch (err) {
		console.log(err);
	}
};

export const deletePatientExamination = (userId, patientExamId) => async (dispatch) => {
	try {
		const res = await axios.delete(
			`/examination-record/delete?userId=${userId}&examinationRecordId=${patientExamId}`,
		);
		if (res.data.success) {
			dispatch({
				type: DELETE_PATIENT_EXAMINATION,
				payload: patientExamId,
			});
			showNotification('Success', 'The examination has been deleted successfully', 'success');
		}
	} catch (err) {
		console.log(err);
	}
};

export const getTreatmentPlans = (userId) => async (dispatch) => {
	try {
		dispatch({
			type: LOADING_PATIENTS,
		});
		console.log('running getTreatmentPlans');
		const res = await axios.get('/treatment-plan', { params: { userId } });
		if (res.data.success) {
			dispatch({
				type: SET_PATIENT_TREATMENT_PLANS,
				payload: res.data.treatmentPlans,
			});
		}
	} catch (err) {
		console.log(err);
	}
};

export const getTreatmentPlansByDept = (userId) => async (dispatch) => {
	try {
		dispatch({
			type: LOADING_PATIENTS,
		});
		console.log('running getTreatmentPlans');
		const res = await axios.get('/treatment-plan/department-treatment-plan', {
			params: { userId },
		});
		if (res.data.success) {
			dispatch({
				type: SET_PATIENT_TREATMENT_PLANS,
				payload: res.data.treatmentPlans,
			});
		}
	} catch (err) {
		console.log(err);
	}
};

export const createTreatmentPlan =
	(newTreatmentPlan, isDept = null) =>
	async (dispatch) => {
		try {
			const res = await axios.post('/treatment-plan', newTreatmentPlan);
			if (res.data.success) {
				showNotification(
					'Success',
					'The treatment plan is created successfully',
					'success',
				);
				// if (isDept) dispatch(getTreatmentPlansByDept(newTreatmentPlan.userId));
				dispatch(getTreatmentPlans(newTreatmentPlan.userId));
			}
		} catch (err) {
			console.log(err);
		}
	};

export const updateTreatmentPlan =
	(updateBody, treatmentPlanId, userId, isDept = null) =>
	async (dispatch) => {
		try {
			const res = await axios.patch('/treatment-plan', { ...updateBody, treatmentPlanId });
			if (res.data.success) {
				// if (isDept) dispatch(getTreatmentPlansByDept(userId));
				dispatch(getTreatmentPlans(userId));
				showNotification(
					'Success',
					'The treatment plan is updated successfully',
					'success',
				);
			}
		} catch (err) {
			console.log(err);
		}
	};

export const updateTreatmentPlanStatus = (userId, treatmentPlanId, status) => async (dispatch) => {
	try {
		const res = await axios.get(
			`/treatment-plan/changeStatus?userId=${userId}&treatmentPlanId=${treatmentPlanId}&status=${status}`,
		);
		if (res.data.success) {
			dispatch(getTreatmentPlans(userId));
			showNotification('Success', 'Status updated successfully', 'success');
		}
	} catch (err) {
		console.log(err);
	}
};

export const deleteTreatmentPlanList =
	(listId, userId, isDept = null) =>
	async (dispatch) => {
		try {
			const res = await axios.delete(`/treatment-plan/list/${listId}`);
			if (res.data.success) {
				// if (isDept) dispatch(getTreatmentPlansByDept(userId));
				dispatch(getTreatmentPlans(userId));
				showNotification(
					'Success',
					'The treatment plan row has been deleted successfully',
					'success',
				);
			}
		} catch (error) {
			console.error(error);
		}
	};

export const deleteTreatmentPlan = (patientTreatId) => async (dispatch) => {
	try {
		const res = await axios.delete(`/treatment-plan/${patientTreatId}`);
		if (res.data.success) {
			dispatch({
				type: DELETE_PATIENT_TREATMENT_PLAN,
				payload: patientTreatId,
			});
			showNotification(
				'Success',
				'The treatment plan has been deleted successfully',
				'success',
			);
		}
	} catch (err) {
		console.log(err);
	}
};

export const getPatientTreatments = (userId) => async (dispatch) => {
	try {
		console.log('running getPatientTreatments');
		const res = await axios.get('/treatment-user', { params: { userId } });
		if (res.data.success) {
			dispatch({
				type: SET_PATIENT_TREATMENTS,
				payload: res.data.treatments,
			});
		}
	} catch (err) {
		console.log(err);
	}
};

// export const getPatientTreatmentsByDept = (userId) => async (dispatch) => {
// 	try {
// 		console.log('running getPatientTreatments');
// 		const res = await axios.get('/treatment-user', { params: { userId } });
// 		if (res.data.success) {
// 			dispatch({
// 				type: SET_PATIENT_TREATMENTS,
// 				payload: res.data.treatments,
// 			});
// 		}
// 	} catch (err) {
// 		console.log(err);
// 	}
// };

export const createPatientTreatment = (newTreatment) => async (dispatch) => {
	try {
		const res = await axios.post('/treatment-user', newTreatment);
		if (res.data.success) {
			showNotification('Success', 'The treatment plan is created successfully', 'success');
			dispatch(getPatientTreatments(newTreatment.userId));
		}
	} catch (err) {
		console.log(err);
	}
};

export const updatePatientTreatment = (updateBody) => async (dispatch) => {
	try {
		const res = await axios.patch('/treatment-user', updateBody);
		if (res.data.success) {
			showNotification('Success', 'The treatment plan is updated successfully', 'success');
			dispatch(getPatientTreatments(updateBody.userId));
		}
	} catch (err) {
		console.log(err);
	}
};

export const updatePatientTreatmentStatus = (updateBody) => async (dispatch) => {
	try {
		const res = await axios.post('/treatment-user/status', updateBody);
		if (res.data.success) {
			showNotification('Success', 'The status is updated successfully', 'success');
			dispatch(getPatientTreatments(updateBody.userId));
		}
	} catch (err) {
		console.log(err);
	}
};

export const deletePatientTreatment = (patientTreatId) => async (dispatch) => {
	try {
		const res = await axios.delete(`/treatment-user/${patientTreatId}`);
		if (res.data.success) {
			dispatch({
				type: DELETE_PATIENT_TREATMENT,
				payload: patientTreatId,
			});
			showNotification(
				'Success',
				'The treatment plan has been deleted successfully',
				'success',
			);
		}
	} catch (err) {
		console.log(err);
	}
};

export const createTiming = (name, organisationId) => async (dispatch) => {
	try {
		const res = await axios.post(`/appointment-profile/create-timing`, {
			name,
			organisationId,
		});
		if (res.data.success) {
			showNotification('Success', 'The Timing is created successfully', 'success');
			dispatch(getTimings(organisationId));
		}
	} catch (error) {
		console.error(error);
	}
};

export const getTimings = (organisationId) => async (dispatch) => {
	try {
		// timings
		const res = await axios.get(`/appointment-profile/timings/${organisationId}`);
		if (res.data.success) {
			dispatch({
				type: SET_TIMINGS,
				payload: res.data.data.templates,
			});
		}
	} catch (error) {
		console.error(error);
	}
};

export const getPatientPrescriptions = (userId) => async (dispatch) => {
	try {
		dispatch({
			type: LOADING_PATIENTS,
		});
		console.log('running getPrescriptions');
		const res = await axios.get('/appointment-profile/sp', { params: { userId } });
		if (res.data.success) {
			dispatch({
				type: SET_PATIENT_PRESCRIPTIONS,
				payload: res.data.appointments,
			});
		}
	} catch (err) {
		console.log(err);
	}
};

export const getPatientPrescriptionsByDept = (userId) => async (dispatch) => {
	try {
		dispatch({
			type: LOADING_PATIENTS,
		});
		console.log('running getPrescriptions');
		const res = await axios.get('/appointment-profile/department-prescriptions', {
			params: { userId },
		});
		if (res.data.success) {
			dispatch({
				type: SET_PATIENT_PRESCRIPTIONS,
				payload: res.data.appointments,
			});
		}
	} catch (err) {
		console.log(err);
	}
};

export const createPatientPrescription = async (newPatientPresc, dispatch, isDept = null) => {
	try {
		const res = await axios.post('/doctor/prescription', newPatientPresc);
		if (res.data.success) {
			showNotification('Success', 'The prescription is created successfully', 'success');
			// if (isDept) dispatch(getPatientPrescriptionsByDept(newPatientPresc.userId));
			dispatch(getPatientPrescriptions(newPatientPresc.userId));
			return res.data.appointments;
		}
	} catch (err) {
		console.log(err);
	}
};

export const updatePatientPrescription =
	(updateBody, userId, isDept = null) =>
	async (dispatch) => {
		try {
			const res = await axios.post('/doctor/prescription', updateBody);
			if (res.data.success) {
				showNotification('Success', 'The prescription is updated successfully', 'success');
				// if (isDept) dispatch(getPatientPrescriptionsByDept(userId));
				dispatch(getPatientPrescriptions(userId));
			}
		} catch (err) {
			console.log(err);
		}
	};

export const deletePatientPrescription =
	(prescId, userId, isDept = null) =>
	async (dispatch) => {
		try {
			const res = await axios.delete(
				`/appointment-ehr/all-prescription?appointmentId=${prescId}`,
			);
			if (res.data.success) {
				// if (isDept) dispatch(getPatientPrescriptionsByDept(userId));
				dispatch(getPatientPrescriptions(userId));
				showNotification(
					'Success',
					'The prescription has been deleted successfully',
					'success',
				);
			}
		} catch (err) {
			console.log(err);
		}
	};

export const deletePatientPrescriptionRow = (prescId, userId) => async (dispatch) => {
	try {
		const res = await axios.delete(`/doctor/prescription/${prescId}`);
		if (res.data.success) {
			dispatch(getPatientPrescriptions(userId));
			showNotification(
				'Success',
				'The prescription row has been deleted successfully',
				'success',
			);
		}
	} catch (err) {
		console.log(err);
	}
};

export const getPatientBills = (params) => async (dispatch) => {
	console.log('running getBills');
	try {
		dispatch({
			type: LOADING_PATIENTS,
		});
		const res = await axios.get('/bills', { params: { ...params, limit: 1000 } });
		if (res.data.success) {
			dispatch({
				type: SET_PATIENT_BILLS,
				payload: res.data.bills,
			});
		}
	} catch (err) {
		console.log(err);
	}
};

export const createPatientBill = (newPatientBill) => async (dispatch) => {
	try {
		const res = await axios.post('/bills', newPatientBill);
		if (res.data.success) {
			dispatch(getPatientBills({ userId: newPatientBill.userId }));
			showNotification('Success', 'The bill is created successfully', 'success');
		}
	} catch (err) {
		console.log(err);
	}
};

export const updatePatientBill = (updateBody, userId) => async (dispatch) => {
	try {
		const res = await axios.patch('/bills', updateBody);
		if (res.data.success) {
			showNotification('Success', 'The bill is updated successfully', 'success');
			dispatch(getPatientBills({ userId }));
		}
	} catch (err) {
		console.log(err);
	}
};

export const deletePatientBill = (patientBillId) => async (dispatch) => {
	try {
		const res = await axios.delete(`/bills/${patientBillId}`);
		if (res.data.success) {
			dispatch({
				type: DELETE_PATIENT_BILL,
				payload: patientBillId,
			});
			showNotification('Success', 'The bill has been deleted successfully', 'success');
		}
	} catch (err) {
		console.log(err);
	}
};

export const deletePatientBillRow = (rowId, userId) => async (dispatch) => {
	try {
		const res = await axios.delete(`/bills/list/${rowId}`);
		if (res.data.success) {
			dispatch(getPatientBills({ userId }));
			showNotification('Success', 'The bill row has been deleted successfully', 'success');
		}
	} catch (error) {
		console.error(error);
	}
};

export const getPatientPayments = (params) => async (dispatch) => {
	try {
		console.log('running getPayments');
		const res = await axios.get('/payments', { params: { ...params, limit: 1000 } });
		if (res.data.success) {
			dispatch({
				type: SET_PATIENT_PAYMENTS,
				payload: res.data.payments,
			});
		}
	} catch (err) {
		console.log(err);
	}
};

export const createPatientPayment = (newPatientPayment) => async (dispatch) => {
	try {
		const res = await axios.post('/payments', newPatientPayment);
		if (res.data.sucess) {
			showNotification('Success', 'The Payment is created successfully', 'success');
			dispatch(getPatientPayments({ userId: newPatientPayment.userId }));
		}
	} catch (err) {
		console.log(err);
	}
};

export const updatePatientPayment = (updateBody) => async (dispatch) => {
	try {
		const res = await axios.patch('/payments', updateBody);
		if (res.data.success) {
			showNotification('Success', 'The Payment is updated successfully', 'success');
			dispatch({
				type: UPDATE_PATIENT_PAYMENT,
				payload: updateBody,
			});
		}
	} catch (err) {
		console.log(err);
	}
};

export const deletePatientPayment = (patientPaymentId) => async (dispatch) => {
	try {
		const res = await axios.delete(`/payments/${patientPaymentId}`);
		if (res.data.success) {
			dispatch({
				type: DELETE_PATIENT_PAYMENT,
				payload: patientPaymentId,
			});
			showNotification('Success', 'The Payment has been deleted successfully', 'success');
		}
	} catch (err) {
		console.log(err);
	}
};

export const getPatientAppointments = (patientId) => async (dispatch) => {
	console.log('running getPatientAppointments');

	try {
		dispatch({
			type: LOADING_PATIENTS,
		});
		const res = await axios.get(`/appointment-profile/patient?userId=${patientId}`);
		if (res.data.success) {
			dispatch({
				type: SET_PATIENT_APPOINTMENTS,
				payload: res.data.appointments,
			});
		}
	} catch (err) {
		console.log(err);
	}
};

export const getPatientDocuements = (userId) => async (dispatch) => {
	try {
		dispatch({
			type: LOADING_PATIENTS,
		});
		const res = await axios.get(`/doctor/patient/document?userId=${userId}`);
		if (res.data.success) {
			dispatch({
				type: SET_PATIENT_DOCUMENTS,
				payload: res.data.documents || [],
			});
		}
	} catch (error) {
		console.error(error);
	}
};

export const addPatientDocument = (userId, data) => async (dispatch) => {
	try {
		const res = await axios.post(`/doctor/patient/document`, data);
		if (res.status === 200 || res.status === 201) {
			if (userId) dispatch(getPatientDocuements(userId));
			showNotification('Success', 'The Document uploaded successfully', 'success');
			if (notify)
				showNotification('Success', 'Check Patient Documents for the same', 'success');
		}
	} catch (error) {
		console.error(error);
	}
};

export const deletePatientDocument = (userId, docId) => async (dispatch) => {
	try {
		const res = await axios.delete(
			`/doctor/patient/document?userId=${userId}&documentId=${docId}`,
		);
		if (res.status === 200) {
			dispatch(getPatientDocuements(userId));
			showNotification('Success', 'The Document has been deleted successfully', 'success');
		}
	} catch (error) {
		console.error(error);
	}
};

export const getPatientPickList = () => async (dispatch) => {
	try {
		const res = await axios.get(`/doctor/patient/pick-list`);
		if (res.data.success) {
			dispatch({ type: SET_PATIENT_PICKLIST, payload: res.data.data });
		}
	} catch (error) {
		console.error(error);
	}
};

export const sendViaWhatsapp = (patientId, report, link) => async (dispatch) => {
	try {
		const res = await axios.post(`/uploads/send-report`, {
			patientId,
			report,
			link,
			doctorId: null,
		});
		if (res.data.success) {
			showNotification('Success', 'Report sent to the patient successfully', 'success');
		}
	} catch (error) {
		console.error(error);
	}
};

export const sendReviewLink = (patientId, experience_with, link) => async (dispatch) => {
	try {
		const res = await axios.post(`/whatsapp/review`, { patientId, experience_with, link });
		if (res.data.success) {
			showNotification('Success', 'Review link sent to the patient', 'success');
		}
	} catch (error) {
		console.error(error);
	}
};

export const createCheifComplain = (data, patient_id, setModal) => async (dispatch) => {
	try {
		const url =
			process.env.NODE_ENV !== 'production'
				? 'https://staglis.docplix.online/api'
				: 'https://lis.docplix.online/api';
		const res = await Axios.post(`${url}/v1/hs/chief-complaints/`, data);
		if (res.status == 201) {
			showNotification('Success', 'Chief Complain is created successfully', 'success');
			dispatch(getCCValues(patient_id));
			setModal(true);
		}
	} catch (err) {
		console.log(err);
	}
};

// export const getMedicalAlerts = () => async (dispatch) => {
// 	try {
// 		const res = await axios.get(`/doctor/medical-alert`);
// 		if (res.data.success) {
// 			let data = [];
// 			res.data?.medicalAlerts?.forEach((at) => {
// 				data = [...data, at?.Medical_alert];
// 			});
// 			dispatch({
// 				type: SET_MEDICAL_ALERTS,
// 				payload: data,
// 			});
// 		}
// 	} catch (error) {
// 		console.error(error);
// 	}
// };

export const getCCValues = (doctor_id) => async (dispatch) => {
	try {
		const url =
			process.env.NODE_ENV !== 'production'
				? 'https://staglis.docplix.online/api'
				: 'https://lis.docplix.online/api';
		const response = await Axios.get(`${url}/v1/hs/cc-values/?doctor_id=${doctor_id}`);
		if (response.status == 200) {
			dispatch({
				type: SET_CHIEF_COMPLAINTS_VALUES,
				payload: response.data,
			});
		}
	} catch (err) {
		console.log(err.message);
	}
};

export const getPatientSymptoms = () => async (dispatch) => {
	try {
		const res = await axios.get(`/docplix/symptoms`);
		if (res.data.success) {
			let data = [];
			res.data?.symptomCategories?.forEach((cat) => {
				data = [...data, ...cat.Symptoms];
			});
			dispatch({
				type: SET_CC_SYMPTOMS,
				payload: data,
			});
		}
	} catch (error) {
		console.error(error);
	}
};

export const getPatientCaseSheets = (patientId, departmentId) => async (dispatch) => {
	try {
		const res = await axios.get(`/doctor/patientexaminationsystem/approved`, {
			params: { patientId, departmentId },
		});
		if (res.data.success) {
			dispatch({
				type: SET_PATIENT_CASE_SHEETS,
				payload: res.data.data,
			});
		}
	} catch (error) {
		console.error(error);
	}
};

export const createPatientCaseSheet =
	(newCaseSheet, date, patientId, departmentId) => async (dispatch) => {
		try {
			const res = await axios.post('/doctor/patientexaminationsystem', {
				examData: newCaseSheet,
				date,
				patientId,
				departmentId,
			});
			if (res.data.success) {
				dispatch(getPatientCaseSheets(patientId, departmentId));
				showNotification('Success', 'The case sheet is created successfully', 'success');
			}
		} catch (err) {
			console.log(err);
		}
	};

export const updatePatientCaseSheet = (updateBody, patientId, departmentId) => async (dispatch) => {
	try {
		const res = await axios.patch('/doctor/patientexaminationsystem', updateBody);
		if (res.data.success) {
			dispatch(getPatientCaseSheets(patientId, departmentId));
			showNotification('Success', 'The case sheet is updated successfully', 'success');
		}
	} catch (err) {
		console.log(err);
	}
};

export const deletePatientCaseSheet = (caseSheetId) => async (dispatch) => {
	try {
		const res = await axios.delete(`/doctor/patientexaminationsystem/${caseSheetId}`);
		if (res.data.success) {
			dispatch({
				type: DELETE_PATIENT_CASE_SHEET,
				payload: caseSheetId,
			});
			showNotification('Success', 'The case sheet has been deleted successfully', 'success');
		}
	} catch (error) {
		console.error(error);
	}
};

export const uploadRegistrationSlip = async (data) => {
	try {
		const res = await axios.post(`/doctor/patient/upload-registratio-slip`, data);
		if (res.data.success) {
			return res.data.data;
		}
	} catch (error) {
		console.error(error);
	}
};

export const removeRegistrationSlip = async (id) => {
	try {
		const res = await axios.delete(`/doctor/patient/remove-registratio-slip/${id}`);
		if (res.data.success) {
		}
	} catch (error) {
		console.error(error);
	}
};

/*---------------------------- WHITEBOARD START ----------------------------*/
export const getWhiteboard = (userId) => async (dispatch) => {
	try {
		const res = await axios.get(`/doctor/whiteboard?userId=${userId}`);
		if (res.data.success) {
			dispatch({
				type: SET_WHITEBOARD,
				payload: res.data.whiteboards,
			});
		}
	} catch (error) {
		console.error(error);
	} finally {
		// dispatch(toggleCreateLoading(false));
	}
};

export const saveWhiteBoard = (userId, data) => async (dispatch) => {
	// dispatch(toggleCreateLoading(true));
	try {
		const res = await axios.post(`/doctor/whiteboard`, data);
		if (res.data.success) {
			dispatch(getWhiteboard(userId));
		}
	} catch (error) {
		console.error(error);
	} finally {
		// dispatch(toggleCreateLoading(false));
	}
};

export const updateWhiteBoard = (board, data, userId) => async (dispatch) => {
	// dispatch(toggleCreateLoading(true));
	try {
		const res = await axios.patch(`/doctor/whiteboard/${board}`, data);
		if (res.data.success) {
			dispatch(getWhiteboard(userId));
		}
	} catch (error) {
		console.error(error);
	} finally {
		// dispatch(toggleCreateLoading(false));
	}
};

export const deleteWhiteBoard = (board, userId) => async (dispatch) => {
	// dispatch(toggleCreateLoading(true));
	try {
		const res = await axios.delete(`/doctor/whiteboard/${board}`);
		if (res.data.success) {
			//showNotification('Success', 'Whiteboard deleted!', 'success');
			dispatch(getWhiteboard(userId));
		}
	} catch (error) {
		console.error(error);
	} finally {
		// dispatch(toggleCreateLoading(false));
	}
};
/*---------------------------- WHITEBOARD END ----------------------------*/

/*---------------------------- CLINICAL NOTES START ----------------------------*/
export const getClinicalNotes = (userId) => async (dispatch) => {
	try {
		const res = await axios.get(`/clinical-notes?userId=${userId}`);
		if (res.data.success) {
			dispatch({
				type: SET_CLINICAL_NOTES,
				payload: res.data.clinicalnotes,
			});
		}
	} catch (error) {
		console.error(error);
	} finally {
		// dispatch(toggleCreateLoading(false));
	}
};

export const saveClinicalNotes = (data, userId) => async (dispatch) => {
	// dispatch(toggleCreateLoading(true));
	console.log('running saveClinicalNotes');
	try {
		const res = await axios.post(`/clinical-notes`, data);
		if (res.data.success) {
			dispatch(getClinicalNotes(userId));
		}
	} catch (error) {
		console.error(error);
	} finally {
		// dispatch(toggleCreateLoading(false));
	}
};

export const updateClinicalNotes = (board, data, userId) => async (dispatch) => {
	// dispatch(toggleCreateLoading(true));
	try {
		const res = await axios.patch(`/clinical-notes/${board}`, data);
		if (res.data.success) {
			dispatch(getClinicalNotes(userId));
		}
	} catch (error) {
		console.error(error);
	} finally {
		// dispatch(toggleCreateLoading(false));
	}
};

export const deleteClinicalNotes = (board, userId) => async (dispatch) => {
	// dispatch(toggleCreateLoading(true));
	try {
		const res = await axios.delete(`/clinical-notes/${board}`);
		if (res.data.success) {
			//showNotification('Success', 'Clinical Note deleted!', 'success');
			dispatch(getClinicalNotes(userId));
		}
	} catch (error) {
		console.error(error);
	} finally {
		// dispatch(toggleCreateLoading(false));
	}
};
/*---------------------------- CLINICAL NOTES END ----------------------------*/

export const uploadSoapAttachment = (data) => async (dispatch) => {
	try {
		const res = await axios.post(`/doctor/patient/upload-soap-image`, data);
		if (res.data.success) {
			showNotification('Success', 'Attachment is created successfully', 'success');
			dispatch(getSoapAttachments(data.get('doctor'), data.get('patient')));
		}
	} catch (error) {
		console.error(error);
	}
};

export const getSoapAttachments = (doctorId, patientId) => async (dispatch) => {
	try {
		const res = await axios.patch(`/doctor/patient/upload-soap-image`, { doctorId, patientId });
		if (res.data.success) {
			dispatch({
				type: SET_SOAP_ATTACHMENTS,
				payload: res.data.data,
			});
		}
	} catch (error) {
		console.error(error);
	}
};

export const deleteSoapAttachment = (id, patient, doctor) => async (dispatch) => {
	try {
		const res = await axios.delete(`/doctor/patient/delete-soap-image/${id}`);
		if (res.data.success) {
			dispatch(getSoapAttachments(doctor, patient));
		}
	} catch (error) {
		console.error(error);
	}
};

export const getPatientPathology = async (userId) => {
	try {
		const res = await axios.get(`/doctor/patient/get-histopathology/${userId}`);
		if (res.data.success) {
			return res.data.data;
		}
	} catch (error) {
		console.error(error);
		return null;
	}
};

export const addPatientpathology = async (data) => {
	try {
		const res = await axios.post(`/doctor/patient/add-histopathology`, data);
		if (res.data.success) {
			return res.data.data;
		}
	} catch (error) {
		console.error(error);
		return null;
	}
};

export const updatePatientpathology = async (data) => {
	try {
		const res = await axios.put(`/doctor/patient/add-histopathology`, data);
		if (res.data.success) {
			return await getPatientPathology();
		}
	} catch (error) {
		console.error(error);
		return null;
	}
};

export const uploadPathologyImage = async (data) => {
	try {
		const res = await axios.post(`/doctor/patient/upload-image`, data);
		if (res.data.success) {
			return res.data;
		}
	} catch (error) {
		console.error(error);
		return null;
	}
};

export const getPatientRequisition = async (params) => {
	try {
		const res = await axios.get(`/doctor/requisition`, { params });
		if (res.data.success) {
			return res.data.requisitions;
		}
	} catch (error) {
		console.error(error);
	}
};

export const createPatientRequisition = async (data) => {
	try {
		const res = await axios.post(`/doctor/requisition`, data);
		if (res.data.success) {
			return await getPatientRequisition({
				userId: data.userId,
				departmentId: data.departmentId,
			});
		}
	} catch (error) {
		console.error(error);
	}
};

export const getPatientRefunds = (userId) => async (dispatch) => {
	try {
		const res = await axios.get(`/payments/refund-list/${userId}`);
		if (res.data.success) {
			dispatch({
				type: SET_PATIENT_REFUND_PAYMENTS,
				payload: res.data.result,
			});
		}
	} catch (error) {
		console.error(error);
	}
};

export const createPatientRefund = (data) => async (dispatch) => {
	try {
		const res = await axios.post(`/payments/refund`, data);
		if (res.data.success) {
			dispatch(getPatientRefunds(data.patientId));
		}
	} catch (error) {
		console.error(error);
	}
};

export const deletePatientRefund = (id, userId) => async (dispatch) => {
	try {
		const res = await axios.delete(`/payments/refund-delete/${id}`);
		if (res.data.success) {
			dispatch(getPatientRefunds(userId));
		}
	} catch (error) {
		console.error(error);
	}
};

export const getPatientVisits = (userId) => async (dispatch) => {
	try {
		const res = await axios.get(`/opd/patient-opds/${userId}`);
		if (res.data.success) {
			dispatch({
				type: SET_PATIENT_VISITS,
				payload: res.data.opd,
			});
		}
	} catch (error) {
		console.error(error);
	}
};
