import axios from 'axios';
import showNotification from '../components/extras/showNotification';
import Icon from '../components/icon/Icon';
import { store } from '../store/configureStore';
import { logout } from '../actions/authActions';

const instance = axios.create({
	baseURL: process.env.REACT_APP_SERVER_URL,
});

instance.interceptors.response.use(
	function (res) {
		return res;
	},
	function (error) {
		if (error.response.status == 401) {
			store.dispatch(logout());
			return;
		}
		showNotification(
			<span className='d-flex align-items-center'>
				<Icon icon='x-circle' size='lg' className='me-1' />
				<span>Error</span>
			</span>,
			error.response ? error.response.data.errors[0] : error.message,
			'danger',
		);
		return Promise.reject(error);
	},
);

export default instance;
