import showNotification from '../components/extras/showNotification';
import axios from '../helpers/axios';
import Axios from 'axios';
import { SET_MEDICAL_ALERTS, SET_PENDING_REQUESTS } from '../reducers/types';
import { store } from '../store/configureStore';
import { getOpdStatusByVendor } from './opdActions';

export const getMedicines = async (search) => {
	try {
		const res = await axios.get(`/doctor/medicine?search=${search || ''}`);
		if (res.data.success) {
			return res.data.medicines;
		}
	} catch (err) {
		console.log(err);
		return [];
	}
};

export const getPurchasedItems = async () => {
	try {
		const res = await axios.get('sales-voucher/pruchase-item-list');
		if (res.data.success) {
			return res.data.purchaseItems;
		}
	} catch (err) {
		console.log(err);
		return [];
	}
};

export const getOpdValidity = async (userId) => {
	try {
		const res = await axios.post('/opd/validity', { userId });
		if (res.data.success) {
			return res.data.registrationFee;
		}
	} catch (err) {
		console.log(err);
	}
};

export const getLanguages = async () => {
	try {
		const res = await axios.get('/language');
		if (res.data.success) {
			return res.data.languages;
		}
	} catch (err) {
		console.log(err);
		return [];
	}
};

export const getFrequencies = async () => {
	try {
		const res = await axios.get('/doctor/dose');
		if (res.data.success) {
			return res.data.doses;
		}
	} catch (err) {
		console.log(err);
		return [];
	}
};

export const getPermissions = async (orgId, vendorId) => {
	try {
		const res = await axios.get(
			`/staff-permission?organisationId=${orgId}&vendorId=${vendorId}`,
		);
		if (res.data.success) {
			return res.data.actions;
		}
	} catch (err) {
		console.log(err);
		return [];
	}
};

export const updatePermissions = async (updateBody) => {
	try {
		const res = await axios.post(`/staff-permission`, updateBody);
		if (res.data.success) {
			showNotification('Success', 'Permission updated successfully', 'success');
		}
	} catch (err) {
		console.log(err);
	}
};

export const getOpdByAppointmentId = async (appointmentId) => {
	try {
		const res = await axios.get(`/opd/getOpdByAppointment?appointmentId=${appointmentId}`);
		if (res.data.success) {
			return res.data.opd;
		}
	} catch (err) {
		console.log(err);
		return null;
	}
};

export const isPermitted = async (module, action, next) => {
	const permissions = store.getState?.()?.profile?.permissions;
	const userType = store.getState?.()?.profile?.selectedOrg?.userType;

	if (userType !== 'staff') {
		next();
		return;
	}
	const f = permissions?.find((i) => i.name === module);
	if (f) {
		if (f.Vendor_action?.[action]) {
			await next();
		} else {
			showNotification(
				'Permission Denied',
				'User is not authorized to perform the action',
				'danger',
			);
		}
	} else {
		showNotification('Error', 'Module not found', 'danger');
	}
};

export const getPdf = async (userId, type, reportId) => {
	try {
		const res = await axios.get('/uploads', { params: { userId, type, reportId } });
		return res.data.report?.name || null;
	} catch (err) {
		console.log(err);
		return null;
	}
};

export const uploadPdf = async (data) => {
	try {
		const res = await axios.post(`/uploads`, data);
		if (res.data.success) {
			return `${res.data.url}/${res.data.reprot.name}`;
		}
	} catch (err) {
		console.log(err);
		return null;
	}
};

export const getAllPayments = async (data) => {
	try {
		const res = await axios.get('/payments/organisation');
		if (res.data.success) {
			return res.data.payments;
		}
	} catch (err) {
		console.log(err);
		return [];
	}
};

export const getInventories = async () => {
	try {
		const res = await axios.get('/inventory');
		if (res.data.success) {
			return res.data.inventories;
		}
	} catch (err) {
		console.log(err);
		return [];
	}
};

export const createCCValue = async (name, doctor) => {
	try {
		const url =
			process.env.NODE_ENV !== 'production'
				? 'https://staglis.docplix.online/api'
				: 'https://lis.docplix.online/api';
		const res = await Axios.post(`${url}/v1/hs/cc-values/`, { name, doctor });
		if (res.status == 201) {
			return res.data;
		}
	} catch (err) {
		console.log(err);
		return null;
	}
};

export const createMedicalAlerts = async (title, dispatch) => {
	try {
		const res = await axios.post('/doctor/medical-alert', { title });
		if (res.data.success) {
			dispatch(getMedicalAlerts());
			return res.data.data?.medicalAlert;
		}
	} catch (err) {
		console.log(err);
		return null;
	}
};

export const getMedicalAlerts = () => async (dispatch) => {
	try {
		const res = await axios.get(`/doctor/medical-alert`);
		if (res.data.success) {
			dispatch({
				type: SET_MEDICAL_ALERTS,
				payload: res.data.data.medicalAlerts,
			});
		}
	} catch (error) {
		console.error(error);
	}
};

export const getPendingExaminations = (deptId) => async (dispatch) => {
	try {
		const res = await axios.get(`/examination-record/department?departmentId=${deptId}`);
		if (res.data.success) {
			dispatch({
				type: SET_PENDING_REQUESTS,
				payload: {
					examinations: res.data?.examinationRecords,
					treatmentPlans: null,
					treatments: null,
					prescription: null,
					caseSheets: null,
				},
			});
		}
	} catch (error) {
		console.error(error);
	}
};

export const getPendingTreatmentPlans = (deptId) => async (dispatch) => {
	try {
		const res = await axios.get(`/treatment-plan/department?departmentId=${deptId}`);
		if (res.data.success) {
			dispatch({
				type: SET_PENDING_REQUESTS,
				payload: {
					examinations: null,
					treatmentPlans: res.data?.treatmentPlans,
					treatments: null,
					prescription: null,
					caseSheets: null,
				},
			});
		}
	} catch (error) {
		console.error(error);
	}
};

export const getPendingTreatments = (deptId) => async (dispatch) => {
	try {
		const res = await axios.get(`/treatment-user/pending-master/${deptId}`);
		if (res.data.success) {
			console.log(res.data?.data?.treatmentMasterLists);
			dispatch({
				type: SET_PENDING_REQUESTS,
				payload: {
					examinations: null,
					treatmentPlans: null,
					treatments: res.data?.data?.treatmentMasterLists,
					prescription: null,
					caseSheets: null,
				},
			});
		}
	} catch (error) {
		console.error(error);
	}
};

export const getPendingPrescription = (deptId) => async (dispatch) => {
	try {
		const res = await axios.get(`/appointment-profile/department?departmentId=${deptId}`);
		if (res.data.success) {
			dispatch({
				type: SET_PENDING_REQUESTS,
				payload: {
					examinations: null,
					treatmentPlans: null,
					treatments: null,
					prescription: res.data.appointments,
					caseSheets: null,
				},
			});
		}
	} catch (error) {
		console.error(error);
	}
};

export const getPendingCaseSheet = (deptId) => async (dispatch) => {
	try {
		const res = await axios.get(
			`/doctor/patientexaminationsystem/pending?departmentId=${deptId}`,
		);
		if (res.data.success) {
			dispatch({
				type: SET_PENDING_REQUESTS,
				payload: {
					examinations: null,
					treatmentPlans: null,
					treatments: null,
					prescription: null,
					caseSheets: res.data.data,
				},
			});
		}
	} catch (error) {
		console.error(error);
	}
};

export const getOpdHistory = async (opdId) => {
	try {
		const res = await axios.get('/opd/department-opd-history', {
			params: { opdId },
		});
		if (res.data.success) {
			const data = res.data?.opds?.[0];
			// const result = [];
			// for (var x of data?.Opd_departments) {
			// 	if (x.vendorId) {
			// 		x['statuses'] = await getOpdStatusByVendor(x.vendorId);
			// 	} else x['statuses'] = [];
			// 	result.push(x);
			// }
			// data['Opd_departments'] = result;
			return data || null;
		}
	} catch (error) {
		console.error(error);
	}
};

export const setSelectedOrgBackend = async (organisationId) => {
	try {
		await axios.post('/organisation-ehr/assign', { organisationId });
	} catch (err) {
		console.log('Error setting org:', err);
	}
};

export const getSelectedOrgBackend = async () => {
	try {
		const res = await axios.get('/organisation-ehr/assign');
		return res.data.organisation;
	} catch (err) {
		console.log('Error getting org:', err);
	}
	return null;
};

export const getStorePurchaseList = async () => {
	try {
		const res = await axios.get(`/doctor/purchase/store/list`);
		if (res.data.success) {
			return res.data.data;
		}
	} catch (error) {
		console.error(error);
	}
};
