// Navbar Icons
import Appointments from './assets/img/menu/appointments/appointments.png';
import AddAppointments from './assets/img/menu/appointments/add-appointment.png';
import MIS from './assets/img/menu/accounts/mis.png';
import Transactions from './assets/img/menu/accounts/transactions.png';
import Vendors from './assets/img/menu/accounts/vendors.png';
import AddOPD from './assets/img/menu/opd/add-opd.png';
import OPDs from './assets/img/menu/opd/opds.png';
import Inventory from './assets/img/menu/pharmacy/inventory.png';
import PharmacyVendors from './assets/img/menu/pharmacy/pharmacy-vendors.png';
import PurchaseVouchers from './assets/img/menu/pharmacy/purchase-vouchers.png';
import SaleVouchers from './assets/img/menu/pharmacy/sale-vouchers.png';
import Settings from './assets/img/menu/pharmacy/settings.png';
import AddStaff from './assets/img/menu/staff/add-staff.png';
import Attendance from './assets/img/menu/staff/attendance.png';
import Staff from './assets/img/menu/staff/staff.png';

// Settings Icons
import Doctors from './assets/img/menu/settings/doctors.png';
import Students from './assets/img/menu/settings/students.png';
import Organisations from './assets/img/menu/settings/organisations.png';
import OPDStatus from './assets/img/menu/settings/opd-status.png';
import ChiefComplaints from './assets/img/menu/settings/chief-complaints.png';
import OralExaminations from './assets/img/menu/settings/oral-examinations.png';
import Treatments from './assets/img/menu/settings/treatments.png';
import PrescriptionTemplates from './assets/img/menu/settings/prescription-templates.png';
import Notes from './assets/img/menu/settings/notes.png';
import Investigations from './assets/img/menu/settings/investigations.png';

// Profile Icons
import Profile from './assets/img/menu/profile/profile.png';
import Logout from './assets/img/menu/profile/logout.png';

export const dashboardMenu = {
	dashboard: {
		id: 'dashboard',
		text: 'Dashboard',
		path: '/',
		icon: 'Dashboard',
		subMenu: null,
	},
	appointments: {
		id: 'appointments',
		text: 'Appointments',
		path: 'appointments',
		icon: 'Today',
		subMenu: {
			addAppointment: {
				id: 'addAppointment',
				text: 'Appointment List',
				path: 'appointments/add',
				icon: 'List',
				subMenu: null,
			},
			viewAppointments: {
				id: 'viewAppointments',
				text: 'Appointment Calendar',
				path: 'appointments/list',
				icon: 'Today',
				subMenu: null,
			},
		},
	},
	patients: {
		id: 'patients',
		text: 'Patients',
		path: 'patients',
		icon: 'people',
		subMenu: {
			addPatient: {
				id: 'addPatient',
				text: 'Add Patient',
				path: 'patients/add',
				icon: 'add',
				subMenu: null,
			},
			viewPatients: {
				id: 'viewPatients',
				text: 'View Patients',
				path: 'patients/list',
				icon: 'list',
				subMenu: null,
			},
			viewPatient: {
				id: 'viewPatient',
				text: 'View Patient',
				path: 'patients/:id/:tab',
				icon: 'people',
				subMenu: null,
				hide: true,
			},

			// PDF Routes
			pathology: {
				id: 'pathology',
				text: 'Pathology',
				path: 'patients/:id/pathology-pdf',
				subMenu: null,
				hide: true,
			},
			requisition: {
				id: 'requisition',
				text: 'Requisition Pdf',
				path: 'patients/:id/requisition-pdf',
				subMenu: null,
				hide: true,
			},
			faceCard: {
				id: 'faceCard',
				text: 'Face Card',
				path: 'patients/:id/facecard',
				subMenu: null,
				hide: true,
			},
			patientExaminationPdf: {
				id: 'patientExaminationPdf',
				text: 'Examination',
				path: 'patients/:id/examination-pdf',
				subMenu: null,
				hide: true,
			},
			patientTreatmentPlanPdf: {
				id: 'patientTreatmentPlanPdf',
				text: 'Treatment Plan',
				path: 'patients/:id/treatment-plan-pdf',
				subMenu: null,
				hide: true,
			},
			patientTreatmentPdf: {
				id: 'patientTreatmentPdf',
				text: 'Treatment',
				path: 'patients/:id/treatment-pdf',
				subMenu: null,
				hide: true,
			},
			patientPrescriptionPdf: {
				id: 'patientPrescriptionPdf',
				text: 'Prescription',
				path: 'patients/:id/prescription-pdf',
				subMenu: null,
				hide: true,
			},
			patientBillingPdf: {
				id: 'patientBillingPdf',
				text: 'Billing',
				path: 'patients/:id/billing-pdf',
				subMenu: null,
				hide: true,
			},
			patientPaymentPdf: {
				id: 'patientPaymentPdf',
				text: 'Payment',
				path: 'patients/:id/payment-pdf',
				subMenu: null,
				hide: true,
			},
			patientOpdPdf: {
				id: 'patientOpdPdf',
				text: 'Opd',
				path: 'patients/:id/opd-pdf',
				subMenu: null,
				hide: true,
			},
			patientShortReport: {
				id: 'patientShortReport',
				text: 'Short Report',
				path: '/patients/:id/short-report',
				subMenu: null,
				hide: true,
			},
			patientLongReport: {
				id: 'patientLongReport',
				text: 'Long Report',
				path: '/patients/:id/long-report',
				subMenu: null,
				hide: true,
			},
			patientDetailedLongReport: {
				id: 'patientDetailedLongReport',
				text: 'Detailed Long Report',
				path: '/patients/:id/detailed-long-report',
				subMenu: null,
				hide: true,
			},
			staticConsentForm: {
				id: 'staticConsentForm',
				text: 'Static Consent Form',
				path: '/patients/:id/consent-form',
				subMenu: null,
				hide: true,
			},
			orthodonticTreatmentConsent: {
				id: 'orthodonticTreatmentConsent',
				text: 'Orthodontic Treatment Consent',
				path: '/patients/:id/orthodontic-treatment-consent',
				subMenu: null,
				hide: true,
			},
		},
	},
	opd: {
		id: 'opd',
		text: 'OPD',
		path: 'opd',
		icon: 'article',
		subMenu: {
			addOpdRecord: {
				id: 'addOpdRecord',
				text: 'Add OPD Record',
				path: 'opd/add-opd-record',
				icon: 'add',
				subMenu: null,
			},
			viewOpdRecords: {
				id: 'viewOpdRecords',
				text: 'OPD Record',
				path: 'opd/records',
				icon: 'Summarize',
				subMenu: null,
			},
			pdfHeader: {
				id: 'pdfHeader',
				text: 'Pdf Header',
				path: 'opd/header-pdf',
				icon: null,
				subMenu: null,
				hide: true,
			},
		},
	},
	pharmacy: {
		id: 'pharmacy',
		text: 'Pharmacy',
		path: 'pharmacy',
		icon: 'store',
		subMenu: {
			addPharmacy: {
				id: 'addPharmacy',
				text: 'Pharmacy Settings',
				path: 'pharmacy/add-pharmacy',
				icon: 'shop',
				subMenu: null,
				hide: true,
			},
			pharmacySettings: {
				id: 'pharmacy-settings',
				text: 'Pharmacy Settings',
				path: 'pharmacy/pharmacy-settings',
				icon: 'settings',
				subMenu: null,
			},
			addVendor: {
				id: 'addVendor',
				text: 'Add Vendor',
				path: 'pharmacy/add-vendor',
				icon: 'add',
				subMenu: null,
				hide: true,
			},
			vendors: {
				id: 'vendors',
				text: 'Vendors',
				path: 'pharmacy/vendors',
				icon: 'people',
				subMenu: null,
			},
			addPurchaseVoucher: {
				id: 'addPurchaseVoucher',
				text: 'Add Purchase Voucher',
				path: 'pharmacy/add-purchase-voucher',
				icon: 'add',
				subMenu: null,
				hide: true,
			},
			purchaseVouchers: {
				id: 'purchase-vouchers',
				text: 'Purchase Vouchers',
				path: 'pharmacy/purchase-vouchers',
				icon: 'receipt',
				subMenu: null,
			},
			inventory: {
				id: 'inventory',
				text: 'Inventory',
				path: 'pharmacy/inventory',
				icon: 'category',
				subMenu: null,
			},
			addSaleVoucher: {
				id: 'addSaleVoucher',
				text: 'Add Sale Voucher',
				path: 'pharmacy/add-sales-voucher',
				icon: 'add',
				subMenu: null,
				hide: true,
			},
			saleVouchers: {
				id: 'sale-vouchers',
				text: 'Sales Vouchers',
				path: 'pharmacy/sales-vouchers',
				icon: 'receipt',
				subMenu: null,
			},
			saleVoucher: {
				id: 'saleVoucher',
				text: 'Sale Voucher',
				path: 'pharmacy/sales-voucher',
				subMenu: null,
				hide: true,
			},
		},
	},
	ipd: {
		id: 'ipd',
		text: 'IPD',
		path: 'ipd',
		icon: 'article',
		hide: true,
		subMenu: {
			// STORE ROUTES AND STORE COMPONENTS ARE DECLARED UNDER THE IPD SECTION BUT HAVE A DIFFERENT NAV MENU
			storeItems: {
				id: 'storeItems',
				text: 'Store Items',
				path: 'store/items',
				icon: 'Person',
				subMenu: null,
				hide: true,
			},
			storePurchases: {
				id: 'storePurchases',
				text: 'Store Purchases',
				path: 'store/purchases',
				icon: 'Person',
				subMenu: null,
				hide: true,
			},
			addStorePurchase: {
				id: 'addStorePurchase',
				text: 'Add Store Purchase',
				path: 'store/purchase/:type',
				icon: null,
				subMenu: null,
				hide: true,
			},
			salesIndentation: {
				id: 'salesIndentation',
				text: 'Sales & Indentation',
				path: 'store/sales-indentation',
				icon: null,
				subMenu: null,
				hide: true,
			},
			addSalesIndentation: {
				id: 'salesIndentation',
				text: 'Sales & Indentation',
				path: 'store/sales-indentation/:type',
				icon: null,
				subMenu: null,
				hide: true,
			},
			storeInventory: {
				id: 'storeInventory',
				text: 'Store Inventory',
				path: 'store/inventory',
				icon: null,
			},
		},
	},
	store: {
		id: 'store',
		text: 'Store',
		path: 'store',
		icon: '',
		subMenu: {
			items: {
				id: 'items',
				text: 'Items',
				path: 'store/items',
				icon: 'LocalHospital',
			},
			purchase: {
				id: 'purchase',
				text: 'Store Purchase',
				path: 'store/purchase',
				icon: 'LocalHospital',
			},
		},
	},
	staff: {
		id: 'staff',
		text: 'Staff',
		path: 'staff',
		icon: 'article',
		subMenu: {
			addStaff: {
				id: 'addStaff',
				text: 'Add Staff',
				path: 'staff/add',
				icon: 'add',
				subMenu: null,
			},
			viewStaff: {
				id: 'viewStaff',
				text: 'Staff Record',
				path: 'staff/list',
				icon: 'ListAlt',
				subMenu: null,
			},
			staffPermissions: {
				id: 'staffPermissions',
				text: 'Staff Permissions',
				path: 'staff/permissions',
				icon: 'VpnKey',
				subMenu: null,
				hide: true,
			},
			viewStaffAttendance: {
				id: 'viewStaffAttendance',
				text: 'Staff Attendance',
				path: 'staff/attendances',
				icon: 'ListAlt',
				subMenu: null,
			},
			viewStaffDetails: {
				id: 'viewStaffDetails',
				text: 'Staff Details',
				path: 'staff/view',
				icon: 'ListAlt',
				subMenu: null,
				hide: true,
			},
		},
	},
	lab: {
		id: 'lab',
		text: 'Dental Lab',
		path: 'lab',
		icon: 'tooth',
		subMenu: {
			addLabVendor: {
				id: 'addLabVendor',
				text: 'Add Lab Vendor',
				path: 'lab/vendor/add',
				icon: 'Add',
				subMenu: null,
				hide: true,
			},
			labVendors: {
				id: 'labVendors',
				text: 'Lab Vendors',
				path: 'lab/vendors',
				icon: 'people',
				subMenu: null,
			},
			addLabTransactions: {
				id: 'addLabTransactions',
				text: 'Add Lab Transactions',
				path: 'lab/transaction/add',
				icon: 'Add',
				subMenu: null,
				hide: true,
			},
			labTransactions: {
				id: 'labTransactions',
				text: 'Lab Transactions',
				path: 'lab/transactions',
				icon: 'MonetizationOn',
				subMenu: null,
			},
			labLog: {
				id: 'labLog',
				text: 'Lab Logs',
				path: 'lab/logs',
				icon: 'summarize',
				subMenu: null,
			},
			labSummary: {
				id: 'labSummary',
				text: 'MIS',
				path: 'lab/mis',
				icon: 'Summarize',
				subMenu: null,
			},
		},
	},
	accounts: {
		id: 'accounts',
		text: 'Accounts & Finance',
		path: 'accounts',
		icon: 'AccountCircle',
		subMenu: {
			addVendor: {
				id: 'addVendor',
				text: 'Add Vendors',
				path: 'account/vendor/add',
				icon: 'Add',
				subMenu: null,
				hide: true,
			},
			vendors: {
				id: 'vendors',
				text: 'Vendors',
				path: 'account/vendors',
				icon: 'people',
				subMenu: null,
			},
			addTransactions: {
				id: 'addTransactions',
				text: 'Add Transactions',
				path: 'account/transaction/add',
				icon: 'Add',
				subMenu: null,
				hide: true,
			},
			transactions: {
				id: 'transactions',
				text: 'Vendor Transactions',
				path: 'account/transactions',
				icon: 'MonetizationOn',
				subMenu: null,
			},
			summary: {
				id: 'summary',
				text: 'Summary',
				path: 'account/summary',
				icon: 'Summarize',
				subMenu: null,
			},
			mis: {
				id: 'mis',
				text: 'Summary',
				path: 'account/mis/summary',
				icon: 'Summarize',
				subMenu: null,
			},
			printMis: {
				id: 'printMis',
				text: 'MIS Export',
				path: 'account/mis/export',
				icon: 'Summarize',
				subMenu: null,
			},
			printDrWise: {
				id: 'printDrWise',
				text: 'MIS Export',
				path: 'account/mis/dr/export',
				icon: 'Summarize',
				subMenu: null,
			},
			misSpecific: {
				id: 'misSpecific',
				text: 'Summary',
				path: 'account/mis/:type',
				icon: 'Summarize',
				subMenu: null,
			},
		},
	},
	settings: {
		id: 'settings',
		text: 'Settings',
		path: 'settings',
		icon: 'Settings',
		subMenu: {
			profile: {
				id: 'profile',
				text: 'Profile',
				path: 'profile',
				icon: 'person',
				subMenu: null,
			},
			consents: {
				id: 'consents',
				text: 'Consents',
				path: 'settings/consents',
				icon: 'add',
				subMenu: null,
				hide: false,
			},
			consentsPrint: {
				id: 'consentsPrint',
				text: 'Consents Print',
				path: 'settings/consents/print',
				icon: 'add',
				subMenu: null,
				hide: true,
			},
			addDoctor: {
				id: 'addDoctor',
				text: 'Add Doctor',
				path: 'settings/doctors/add',
				icon: 'add',
				subMenu: null,
				hide: true,
			},
			addStudent: {
				id: 'addStudent',
				text: 'Add Student',
				path: 'settings/student/add',
				icon: 'add',
				subMenu: null,
				hide: true,
			},
			viewStudents: {
				id: 'viewStudents',
				text: 'Students',
				path: 'settings/students',
				icon: 'People',
				subMenu: null,
			},
			viewDoctors: {
				id: 'viewDoctors',
				text: 'Doctors',
				path: 'settings/doctors',
				icon: 'People',
				subMenu: null,
			},
			addOrganisation: {
				id: 'addOrganisation',
				text: 'Add Organisation',
				path: 'settings/organisations/add',
				icon: 'add',
				subMenu: null,
				hide: true,
			},
			viewOrganisations: {
				id: 'viewOrganisations',
				text: 'Organisations',
				path: 'settings/organisations',
				icon: 'Building',
				subMenu: null,
			},
			addDepartment: {
				id: 'addDepartment',
				text: 'Add Department',
				path: 'settings/departments/add',
				icon: 'add',
				subMenu: null,
				hide: true,
			},
			viewDepartments: {
				id: 'viewDepartments',
				text: 'View Departments',
				path: 'settings/departments',
				icon: 'list',
				subMenu: null,
				hide: true,
			},

			opdStatuses: {
				id: 'opd-statuses',
				text: 'OPD Statuses',
				path: 'settings/statuses',
				icon: 'ListAlt',
				subMenu: null,
			},
			chiefComplaints: {
				id: 'chief-complaints',
				text: 'Chief Complaints',
				path: 'settings/chief-complaints',
				icon: 'Feed',
				subMenu: null,
			},
			oralExaminations: {
				id: 'oral-examinations',
				text: 'Oral Examinations',
				path: 'settings/oral-examinations',
				icon: 'Article',
				subMenu: null,
			},
			diagnoses: {
				id: 'diagnoses',
				text: 'Diagnosis',
				path: 'settings/diagnoses',
				icon: 'Medication',
				subMenu: null,
			},
			communicationGroups: {
				id: 'communication-groups',
				text: 'Communication Groups',
				path: 'settings/communication-groups',
				icon: 'Announcement',
				subMenu: null,
			},
			referralTypes: {
				id: 'referral-types',
				text: 'Referral Types',
				path: 'settings/referral-types',
				icon: 'BorderAll',
				subMenu: null,
				hide: true,
			},
			externalReferrers: {
				id: 'external-referrars',
				text: 'External Referrers',
				path: 'settings/external-referrars',
				icon: 'PersonAdd',
				subMenu: null,
				hide: true,
			},
			treatments: {
				id: 'treatments',
				text: 'Treatments',
				path: 'settings/treatments',
				icon: 'LocalHospital',
				subMenu: null,
			},
			prescriptionTemplates: {
				id: 'prescriptionTemplates',
				text: 'Prescription Templates',
				path: 'settings/prescriptionTemplates',
				icon: 'ContentPaste',
				subMenu: null,
			},
			notes: {
				id: 'notes',
				text: 'Notes',
				path: 'settings/notes',
				icon: 'Note',
				subMenu: null,
			},
			investigations: {
				id: 'investigations',
				text: 'Investigations',
				path: 'settings/investigations',
				icon: 'FindInPage',
				subMenu: null,
			},
			pendingRequests: {
				id: 'pendingRequests',
				text: 'Pending Requests',
				path: 'pending-requests',
				icon: 'Notifications',
				subMenu: null,
			},
		},
	},
};

export const authMenu = {
	login: {
		id: 'login',
		text: 'Login',
		path: 'auth-pages/login',
		icon: 'Login',
	},
	signUp: {
		id: 'signUp',
		text: 'Sign Up',
		path: 'auth-pages/sign-up',
		icon: 'PersonAdd',
	},
	page404: {
		id: 'Page404',
		text: '404 Page',
		path: 'auth-pages/404',
		icon: 'ReportGmailerrorred',
	},
	logout: {
		id: 'logout',
		text: 'Logging Out',
		path: 'auth-pages/logout',
		icon: 'logout',
	},
};

export const navMenu = {
	appointments: {
		id: 'appointments',
		text: 'Appointments',
		subMenu: {
			addAppointment: {
				id: 'addAppointment',
				icon: AddAppointments,

				iconFamily: 'bootstrap',
				text: 'Add Appointment',
				path: dashboardMenu.appointments.subMenu.addAppointment.path,
			},
			viewAppointments: {
				id: 'viewAppointments',
				icon: Appointments,
				iconFamily: 'bootstrap',
				text: 'View Appointments',
				path: dashboardMenu.appointments.subMenu.viewAppointments.path,
			},
		},
	},
	patients: {
		id: 'patients',
		text: 'Patients',
		subMenu: {
			addPatient: {
				id: 'addPatient',
				icon: AddStaff,
				text: 'Add Patient',
				path: dashboardMenu.patients.subMenu.addPatient.path,
			},
			patients: {
				id: 'patients',
				icon: Vendors,
				text: 'Patients',
				path: dashboardMenu.patients.subMenu.viewPatients.path,
			},
		},
	},
	opd: {
		id: 'opd',
		text: 'OPD',
		subMenu: {
			addOpd: {
				id: 'addOpd',
				icon: AddOPD,
				text: 'Add OPD',
				path: dashboardMenu.opd.subMenu.addOpdRecord.path,
			},
			viewOpds: {
				id: 'viewOpds',
				icon: OPDs,
				text: 'View OPDs',
				path: dashboardMenu.opd.subMenu.viewOpdRecords.path,
			},
		},
	},
	pharmacy: {
		id: 'pharmacy',
		text: 'Pharmacy',
		subMenu: {
			vendors: {
				id: 'vendors',
				icon: PharmacyVendors,
				text: 'Vendors',
				path: dashboardMenu.pharmacy.subMenu.vendors.path,
			},
			purchaseVouchers: {
				id: 'purchaseVouchers',
				icon: PurchaseVouchers,
				text: 'Purchase Vouchers',
				path: dashboardMenu.pharmacy.subMenu.purchaseVouchers.path,
			},
			saleVouchers: {
				id: 'saleVouchers',
				icon: SaleVouchers,
				text: 'Sale Vouchers',
				path: dashboardMenu.pharmacy.subMenu.saleVouchers.path,
			},
			inventory: {
				id: 'inventory',
				icon: Inventory,
				text: 'Inventory',
				path: dashboardMenu.pharmacy.subMenu.inventory.path,
			},
			// mis: {
			// 	id: 'mis',
			// 	icon: MIS,
			// 	text: 'MIS',
			// 	path: '/not-found',
			// },
			settings: {
				id: 'settings',
				icon: Settings,
				text: 'Settings',
				path: dashboardMenu.pharmacy.subMenu.pharmacySettings.path,
			},
		},
	},
	store: {
		id: 'store',
		text: 'Store',
		path: 'store',
		icon: 'store',
		subMenu: {
			storeItems: {
				type: 'ipd',
				id: 'storeItems',
				icon: Transactions,
				text: 'Store Items',
				path: dashboardMenu.ipd.subMenu.storeItems.path,
			},
			inventory: {
				type: 'ipd',
				id: 'inventory',
				icon: Inventory,
				text: 'Inventory',
				path: dashboardMenu.ipd.subMenu.storeInventory.path,
			},
			purchaseItems: {
				type: 'ipd',
				id: 'purchaseItems',
				icon: PurchaseVouchers,
				text: 'Store Purchases',
				path: dashboardMenu.ipd.subMenu.storePurchases.path,
			},
			salesIndentation: {
				type: 'ipd',
				id: 'salesIndentation',
				icon: SaleVouchers,
				text: 'Sales & Indentation',
				path: dashboardMenu.ipd.subMenu.salesIndentation.path,
			},
		},
	},
	staff: {
		id: 'staff',
		text: 'Staff',
		subMenu: {
			staff: {
				id: 'staff',
				icon: Staff,
				text: 'Staff',
				path: dashboardMenu.staff.subMenu.viewStaff.path,
			},
			addStaff: {
				id: 'addStaff',
				icon: AddStaff,
				text: 'Add Staff',
				path: dashboardMenu.staff.subMenu.addStaff.path,
			},
			attendance: {
				id: 'attendance',
				icon: Attendance,
				text: 'Attendance',
				path: dashboardMenu.staff.subMenu.viewStaffAttendance.path,
			},
		},
	},
	lab: {
		id: 'lab',
		text: 'Dental Lab',
		subMenu: {
			labVendors: {
				id: 'labVendors',
				text: 'Lab Vendors',
				path: dashboardMenu.lab.subMenu.labVendors.path,
				icon: Vendors,
			},
			labTransactions: {
				id: 'labTransactions',
				text: 'Lab Transactions',
				path: dashboardMenu.lab.subMenu.labTransactions.path,
				icon: Transactions,
			},
			labLog: {
				id: 'labLog',
				text: 'Lab Logs',
				path: dashboardMenu.lab.subMenu.labLog.path,
				icon: SaleVouchers,
			},
			labSummary: {
				id: 'labSummary',
				text: 'MIS',
				path: dashboardMenu.lab.subMenu.labSummary.path,
				icon: MIS,
			},
		},
	},
	accounts: {
		id: 'accounts',
		text: 'Accounts',
		subMenu: {
			vendors: {
				id: 'vendors',
				icon: Vendors,
				text: 'Vendors',
				path: dashboardMenu.accounts.subMenu.vendors.path,
			},
			transactions: {
				id: 'transactions',
				icon: Transactions,
				text: 'Transactions',
				path: dashboardMenu.accounts.subMenu.transactions.path,
			},
			// mis: {
			// 	id: 'mis',
			// 	icon: MIS,
			// 	text: 'MIS',
			// 	path: '/not-found',
			// },
			summary: {
				id: 'summary',
				text: 'Summary',
				path: dashboardMenu.accounts.subMenu.summary.path,
				icon: MIS,
			},
			mis: {
				id: 'mis',
				text: 'Organisation MIS',
				path: dashboardMenu.accounts.subMenu.mis.path,
				icon: MIS,
			},
		},
	},
};

export const settingsMenu = {
	faculties: {
		id: 'faculties',
		icon: Doctors,
		text: 'Faculties',
		path: dashboardMenu.settings.subMenu.viewDoctors.path,
	},
	consents: {
		id: 'consents',
		text: 'Consent Forms',
		path: 'settings/consents',
		icon: SaleVouchers,
		subMenu: null,
		hide: false,
	},
	students: {
		id: 'students',
		icon: Students,
		text: 'Students',
		path: dashboardMenu.settings.subMenu.viewStudents.path,
	},
	departments: {
		id: 'departments',
		icon: Organisations,
		text: 'Departments',
		path: dashboardMenu.settings.subMenu.viewDepartments.path,
	},
	// organisations: {
	// 	id: 'organisations',
	// 	icon: Organisations,
	// 	text: 'Organisations',
	// 	path: dashboardMenu.settings.subMenu.viewOrganisations.path,
	// },
	opdStatuses: {
		id: 'opdStatuses',
		icon: OPDStatus,
		text: 'OPD Status',
		path: dashboardMenu.settings.subMenu.opdStatuses.path,
	},
	chiefComplaints: {
		id: 'chiefComplaints',
		icon: ChiefComplaints,
		text: 'Chief Complaints',
		path: dashboardMenu.settings.subMenu.chiefComplaints.path,
	},
	oralExaminations: {
		id: 'oralExaminations',
		icon: OralExaminations,
		text: 'Oral Examinations',
		path: dashboardMenu.settings.subMenu.oralExaminations.path,
	},
	treatments: {
		id: 'treatments',
		icon: Treatments,
		text: 'Treatments',
		path: dashboardMenu.settings.subMenu.treatments.path,
	},
	prescriptionTemplates: {
		id: 'prescriptionTemplates',
		icon: PrescriptionTemplates,
		text: 'Prescription Templates',
		path: dashboardMenu.settings.subMenu.prescriptionTemplates.path,
	},
	notes: {
		id: 'notes',
		icon: Notes,
		text: 'Notes',
		path: dashboardMenu.settings.subMenu.notes.path,
	},
	investigations: {
		id: 'investigations',
		icon: Investigations,
		text: 'Investigations',
		path: dashboardMenu.settings.subMenu.investigations.path,
	},
};

export const profileMenu = {
	profile: {
		id: 'profile',
		icon: Profile,
		text: 'Profile',
		path: dashboardMenu.settings.subMenu.profile.path,
	},
	logout: {
		id: 'logout',
		icon: Logout,
		text: 'Logout',
		path: authMenu.logout.path,
	},
};
