import Checks from '../components/bootstrap/forms/Checks';
import Box1SVG from '../pages/patients/components/case-sheets/Box1SVG';
import Box2SVG from '../pages/patients/components/case-sheets/Box2SVG';
import axios from './axios';

export function test() {
	return null;
}

export function getOS() {
	const { userAgent } = window.navigator;
	const { platform } = window.navigator;
	const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
	const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
	const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
	let os = null;

	if (macosPlatforms.indexOf(platform) !== -1) {
		os = 'MacOS';
	} else if (iosPlatforms.indexOf(platform) !== -1) {
		os = 'iOS';
	} else if (windowsPlatforms.indexOf(platform) !== -1) {
		os = 'Windows';
	} else if (/Android/.test(userAgent)) {
		os = 'Android';
	} else if (!os && /Linux/.test(platform)) {
		os = 'Linux';
	}

	document.documentElement.setAttribute('os', os);
	return os;
}

export const capitalizeFirstLetter = (str) => str.charAt(0).toUpperCase() + str.slice(1);

export const createDataView = (data) => {
	// debugger;
	return Object.keys(data).map((key) => {
		if (typeof data[key] == 'function') return data[key]();
		if (typeof data[key] === 'object') {
			if (Array.isArray(data[key]) && data[key].length > 0) {
				if (data[key].length == 128) {
					return <Box1SVG editItem={data[key]} />;
				}
				if (data[key].length == 26) {
					return <Box2SVG editItem={data[key]} />;
				}
				const head = Object.keys(data[key][0] || {});
				if (key == "Carey's/Arch Perimeter Analysis" || key == "Moyer's Analysis") {
					head.shift();
					head.unshift('');
				}
				return (
					<div className='col-12 g-4'>
						<h5 className='mt-4 mb-2 text-center'>
							{key?.replace(/([a-z])([A-Z])/g, '$1 $2')?.toUpperCase?.()}
						</h5>
						<table className='table table-bordered'>
							<thead>
								{head.map((x, i) => (
									<th key={i} className={i != 0 ? 'text-center' : ''}>
										{x}
									</th>
								))}
							</thead>
							<tbody>
								{data?.[
									key
										?.replace(/\n+/g, ' ')
										.replace(/\t+/g, ' ')
										.replace(/\s+/g, ' ')
										.trim()
								]?.map((obj, i) => (
									<tr key={i}>
										{Object.entries(obj).map(([ob, value], idx) => {
											if (idx == 0) {
												if (typeof value == 'function') return value();
												return (
													<td
														dangerouslySetInnerHTML={{ __html: value }}
													/>
												);
											}
											return (
												<td key={idx}>
													{typeof value === 'object' ? (
														<ul style={{ listStyle: 'none' }}>
															{value?.map((item, index) => {
																return (
																	<li key={index}>
																		{Object.keys(item).length >
																		1 ? (
																			<>
																				{Object.keys(
																					item,
																				)?.map(
																					(
																						objItem,
																						objIndex,
																					) => {
																						return (
																							<p>
																								{`${objItem} : ${item[objItem]}`}
																							</p>
																						);
																					},
																				)}
																			</>
																		) : (
																			<>
																				{`${
																					Object.keys(
																						item,
																					)[index]
																				} : ${
																					Object.values(
																						item,
																					)[index]
																				}`}
																			</>
																		)}
																	</li>
																);
															})}
														</ul>
													) : typeof value == 'function' ? (
														<td>{value()}</td>
													) : (
														<td>{`${value}..`}</td>
													)}
												</td>
											);
										})}
									</tr>
								))}
							</tbody>
						</table>
					</div>
				);
			}
			return (
				<div className='col-12 row g-4'>
					{!key.includes('row') && (
						<h5 className='mt-4 mb-1 text-center'>
							{key?.replace(/([a-z])([A-Z])/g, '$1 $2')?.toUpperCase?.()}
						</h5>
					)}
					{createDataView(data[key])}
				</div>
			);
		} else if (isBase64(data[key]?.split?.('base64,')?.[1])) {
			return (
				<div className='col-6'>
					<tr className='d-flex justify-content-center'>
						<img src={data[key]} className='image-for-case-sheet' />
					</tr>
				</div>
			);
		} else if (typeof data[key] === 'string')
			return (
				<div className='col-6'>
					<tr className='d-flex justify-content-center'>
						<th>{capitalizeFirstLetter(key)}: </th>
						<td>{data[key]}</td>
					</tr>
				</div>
			);
	});
};

export const createFormFromFormik = (data, formik, FormGroup, Input, parentPath = '') => {
	return Object.keys(data).map((key) => {
		if (key == 'date' || key == 'Date') return;
		if (typeof value == 'function') return <td>{value()}</td>;
		if (typeof data[key] === 'object') {
			if (Array.isArray(data[key])) {
				const head = Object.keys(data[key][0]);
				if (key == "Carey's/Arch Perimeter Analysis" || key == "Moyer's Analysis") {
					head.shift();
					head.unshift('');
				}
				return (
					<div className='col-12 g-3'>
						<h5 className='mt-4 mb-2 text-center'>
							{key?.replace(/([a-z])([A-Z])/g, '$1 $2')?.toUpperCase?.()}
						</h5>
						<table className='table table-bordered'>
							<thead>
								{head.map((x, i) => (
									<th key={i} className={i != 0 ? 'text-center' : ''}>
										{x}
									</th>
								))}
							</thead>
							{data[key].length > 1 && (
								<tbody>
									{data[key].map((obj, i) => {
										// if (idx == 0) return <tr dangerouslySetInnerHTML={{__html:obj[0]}}></tr>;
										return (
											<tr key={i}>
												{Object.entries(obj).map(([ob, value], idx) => {
													if (idx == 0) {
														if (typeof value == 'function')
															return value();
														return (
															<td
																key={idx}
																dangerouslySetInnerHTML={{
																	__html: value,
																}}
															/>
														);
													}
													if (typeof value == 'boolean')
														return (
															<td key={idx}>
																<Checks
																	type='checkbox'
																	id={
																		parentPath +
																		key +
																		`[${i}].` +
																		ob
																	}
																	name={
																		parentPath +
																		key +
																		`[${i}].` +
																		ob
																	}
																	label={ob}
																	onChange={formik.handleChange}
																	checked={value}
																/>
															</td>
														);
													if (typeof value == 'function') return value();
													return (
														<td key={idx}>
															<Input
																style={{
																	height: '3rem',
																}}
																className='rounded-0'
																type='text'
																onChange={formik.handleChange}
																onBlur={formik.handleBlur}
																value={value}
																isValid={formik.isValid}
																name={
																	parentPath +
																	key +
																	`[${i}].` +
																	ob
																}
															/>
														</td>
													);
												})}
											</tr>
										);
									})}
								</tbody>
							)}
						</table>
					</div>
				);
			}
			// else if (data[key].hasOwnProperty('props')) return data[key];
			return (
				<div className='col-12 row g-3'>
					<h5 className={parentPath == '' ? 'text-center mt-4 mb-0' : 'my-0'}>
						{key?.replace(/([a-z])([A-Z])/g, '$1 $2')?.toUpperCase?.()}
					</h5>
					{createFormFromFormik(
						data[key],
						formik,
						FormGroup,
						Input,
						parentPath.length > 0 ? parentPath + key + '.' : key + '.',
					)}
				</div>
			);
		} else if (typeof data[key] === 'boolean') {
			return (
				<div className='col-3 my-3'>
					<Checks
						type='checkbox'
						id={parentPath + key}
						name={parentPath + key}
						label={key}
						onChange={formik.handleChange}
						checked={data[key]}
					/>
				</div>
			);
		} else if (typeof data[key] === 'string') {
			return (
				<div className='col-6'>
					<FormGroup label={key}>
						<Input
							style={{
								height: 'calc(3.5rem + 2px)',
							}}
							type='text'
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values[key]}
							isValid={formik.isValid}
							isTouched={formik.touched[key]}
							invalidFeedback={formik.errors[key]}
							name={parentPath + key}
						/>
					</FormGroup>
				</div>
			);
		}
	});
};

export const isBase64 = (value) =>
	/^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{4})$/.test(value);

export const toBase64 = (file) =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = reject;
	});

export const MAX_CASE_SHEET_IMAGE_SIZE = 2048; // this is for MB

export const hasNotch = () => {
	/**
	 * For storybook test
	 */
	const storybook = window.location !== window.parent.location;
	const iPhone = /iPhone/.test(navigator.userAgent) && !window.MSStream;
	const aspect = window.screen.width / window.screen.height;
	const aspectFrame = window.innerWidth / window.innerHeight;
	return (
		(iPhone && aspect.toFixed(3) === '0.462') ||
		(storybook && aspectFrame.toFixed(3) === '0.462')
	);
};

export const mergeRefs = (refs) => {
	return (value) => {
		refs.forEach((ref) => {
			if (typeof ref === 'function') {
				ref(value);
			} else if (ref != null) {
				ref.current = value;
			}
		});
	};
};

export const randomColor = () => {
	const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger'];

	const color = Math.floor(Math.random() * colors.length);

	return colors[color];
};

export const priceFormat = (price) => {
	return price.toLocaleString('en-US', {
		style: 'currency',
		currency: 'USD',
	});
};

export const average = (array) => array.reduce((a, b) => a + b) / array.length;

export const percent = (value1, value2) => ((value1 / value2 - 1) * 100).toFixed(2);

export const getFirstLetter = (text, letterCount = 2) =>
	text
		.toUpperCase()
		.match(/\b(\w)/g)
		.join('')
		.substring(0, letterCount);

export const debounce = (func, wait = 1000) => {
	let timeout;

	return function executedFunction(...args) {
		const later = () => {
			clearTimeout(timeout);
			func(...args);
		};

		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
	};
};

export const prescriptionHeader = {
	UHID: 'true',
	'OPD No': 'true',
	Name: 'true',
	Contact: 'true',
	'Age/Gender': 'true',
	Validity: 'true',
	Address: 'true',
	Doctor: 'true',
};

export const billHeader = {
	UHID: 'true',
	Name: 'true',
	Contact: 'true',
	'Age/Gender': 'true',
	Address: 'true',
};

export const truncate = (source, size) =>
	source.length > size ? source.slice(0, size - 1) + '…' : source;

export const invalidNums = ['e', 'E', '+', '-'];

export const staticUrl = process.env.REACT_APP_STATIC_URL;

export const toothHelpers = [
	'1st Quadrant',
	'2nd Quadrant',
	'3rd Quadrant',
	'4th Quadrant',
	'Upper Arch',
	'Lower Arch',
	'Full Mouth',
	'Full Mouth(Single Unit)',
];

export const MISSummaryRoutes = {
	dentalLabBill: {
		path: 'dental-lab-billing',
		name: 'Dental Lab Billing',
	},
	treatmentBill: {
		path: 'treatment-billing',
		name: 'Treatment Billing',
	},
	purchaseVouchersBill: {
		path: 'purchase-voucher',
		name: 'Purchase Voucher',
	},
	saleVouchersBill: {
		path: 'sales-voucher',
		name: 'Sales Voucher',
	},
	ledgerBill: {
		path: 'ledger',
		name: 'Ledger',
	},
	opdBill: {
		path: 'opd-billing',
		name: 'Consultation Billing',
	},
};

export const getMedicineByName = async (name) => {
	try {
		const res = await axios.get(
			`${process.env.REACT_APP_SERVER_URL}/doctor/medicine/suggestion?search=${name}`,
		);

		const allMeds = [...res?.data?.inventories, ...res?.data?.medicines];

		if (allMeds.length > 0) {
			let med = null;
			allMeds?.forEach?.((m) => {
				if (m?.name?.toLowerCase?.().includes?.(name?.toLowerCase?.())) med = m;
			});
			return med;
		} else {
			return null;
		}
	} catch (err) {
		console.log(err);
		return null;
	}
};

export const checkAnalysisLicense = async () => {
	try {
		const res = await axios.get('/doctor/profile/check-analyze-licence');

		if (res.data.success) {
			if (!res.data.isAnalyze)
				showNotification(
					'Permission Denied',
					'You are not subscribed to use analysis',
					'danger',
				);
			return res.data.isAnalyze;
		} else {
			return false;
		}
	} catch (err) {
		console.log(err);
		return false;
	}
};

export const checkSTTLicense = async () => {
	try {
		const res = await axios.get('/doctor/profile/check-speech-to-text-licence');

		if (res.data.success) {
			return res.data.isSpeechToTextLicence;
		} else {
			return false;
		}
	} catch (err) {
		console.log(err);
		return false;
	}
};

export const coalesce = (...args) => {
	return args.find((arg) => arg !== null && arg !== undefined);
};

export const fontSizes = {
	'Extra Small': 5,
	Smaller: 7,
	Small: 9,
	Normal: 11,
	Medium: 13,
	Large: 15,
	Larger: 18,
	'Extra Large': 21,
};

export const billingTypes = [
	{ name: 'Total Billed', key: 'totalBill' },
	{ name: 'Total Collected', key: 'totalPaidBill' },
	{ name: 'Cash', key: 'modePayBills;billByCash' },
	{ name: 'Card', key: 'modePayBills;billByCard' },
	{ name: 'UPI', key: 'modePayBills;billByUpi' },
	{ name: 'Net Banking', key: 'modePayBills;billByBanking' },
	{ name: 'Wallet', key: 'modePayBills;billByWallet' },
	{ name: 'Return', key: 'returnPaidBill' },
	{ name: 'Outstanding', key: 'outstanding' },
];

export const renderNumber = (number) => {
	if (!isNaN(parseFloat(number))) return parseFloat(number)?.toFixed?.(2);
	return 0;
};
export const renderRupee = (number) => {
	if (!isNaN(parseFloat(number))) return `₹${parseFloat(number)?.toFixed?.(2)}`;
	return 0;
};

export const calculateAge = (dob, long = false) => {
	// Convert the date of birth string to a Date object
	const dobDate = new Date(dob);

	// Get the current date
	const currentDate = new Date();

	// Calculate the difference between current date and DOB in milliseconds
	const ageInMilliseconds = currentDate - dobDate;

	if (currentDate < dobDate) return `Invalid DOB`;

	// Convert milliseconds to years and months
	const msPerDay = 1000 * 60 * 60 * 24;
	const years = Math.floor(ageInMilliseconds / (msPerDay * 365));
	const months = Math.floor((ageInMilliseconds % (msPerDay * 365)) / (msPerDay * 30));
	const days = Math.floor(((ageInMilliseconds % (msPerDay * 365)) % (msPerDay * 30)) / msPerDay);

	// Construct the result string

	if (years > 0) {
		if (long) return `${years} Years`;
		return `${years} Y`;
	}
	if (months > 0) {
		if (long) return `${months} Months`;
		return `${months} M`;
	}
	if (days > 0) {
		if (long) return `${days} Days`;
		return `${days} D`;
	}
	return '-';
};

export const formatAddress = (object) => {
	let address = '';
	if (object) {
		if (object.address1) address += object.address1.toString();
		if (object.address2) {
			if (address == '') address += object.address2.toString();
			else address += ', ' + object.address2.toString();
		}
		if (object.city) {
			if (address == '') address += object.city.toString();
			else address += ', ' + object.city.toString();
		}
		if (object.state) {
			if (address == '') address += object.state.toString();
			else address += ', ' + object.state.toString();
		}
		if (object.pincode) {
			if (address == '') address += object.pincode.toString();
			else address += ', ' + object.pincode.toString();
		}
		if (object.country) {
			if (address == '') address += object.country.toString();
			else address += ', ' + object.country.toString();
		}
	}
	return address;
};

export const matchInventoryItem = async (item, inventory) => {
	let invItem = inventory.find((x) => x.medicineId == item.medicineId);
	if (!invItem && item.composition) invItem = await getInventoryByComposition(item.composition);
	return invItem;
};

export const deepCopyObject = (obj) => {
	let newObj = {};
	for (let key in obj) {
		if (obj[key] != '' && obj[key] != null && obj[key] != undefined) {
			if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
				newObj[key] = deepCopyObject(obj[key]);
			} else {
				newObj[key] = obj[key];
			}
		}
	}
	return newObj;
};

export const handlePaymentRatio = (value, balance) => {
	switch (value) {
		case 'oneForth':
			return Math.round(balance / 4);

		case 'half':
			return Math.round(balance / 2);

		case 'full':
			return Math.round(balance);

		default:
			return 0;
	}
};

export const getGST = (cgst, sgst, amount) => {
	let cgstAmount = (renderNumber(cgst) * renderNumber(amount)) / 100;
	let sgstAmount = (renderNumber(sgst) * renderNumber(amount)) / 100;
	return { cgstAmount, sgstAmount };
};

export function dateDiffInDays(startDate, endDate) {
	const start = new Date(startDate);
	const end = new Date(endDate);

	const timeDifference = end - start;
	const dayDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24)); // Milliseconds in a day

	return dayDifference || 0;
}

export const formatTooth = (tooth = '') => {
	const Quad1 = ['11', '12', '13', '14', '15', '16', '17', '18'];
	const Quad2 = ['21', '22', '23', '24', '25', '26', '27', '28'];
	const Quad3 = ['31', '32', '33', '34', '35', '36', '37', '38'];
	const Quad4 = ['41', '42', '43', '44', '45', '46', '47', '48'];
	const pedoQuad1 = ['51', '52', '53', '54', '55'];
	const pedoQuad2 = ['61', '62', '63', '64', '65'];
	const pedoQuad3 = ['71', '72', '73', '74', '75'];
	const pedoQuad4 = ['81', '82', '83', '84', '85'];
	const toothNos = tooth.split(',');
	const length = toothNos.length;
	let res = '';
	if (length > 0) {
		if (length == 8) {
			const isQuad1 = toothNos.map((x) => Quad1.includes(x)).every((x) => x);
			const isQuad2 = toothNos.map((x) => Quad2.includes(x)).every((x) => x);
			const isQuad3 = toothNos.map((x) => Quad3.includes(x)).every((x) => x);
			const isQuad4 = toothNos.map((x) => Quad4.includes(x)).every((x) => x);
			if (isQuad1) res = 'Quadrant 1';
			else if (isQuad2) res = 'Quadrant 2';
			else if (isQuad3) res = 'Quadrant 3';
			else if (isQuad4) res = 'Quadrant 4';
		} else if (length == 5) {
			const isPedoQuad1 = toothNos.map((x) => pedoQuad1.includes(x)).every((x) => x);
			const isPedoQuad2 = toothNos.map((x) => pedoQuad2.includes(x)).every((x) => x);
			const isPedoQuad3 = toothNos.map((x) => pedoQuad3.includes(x)).every((x) => x);
			const isPedoQuad4 = toothNos.map((x) => pedoQuad4.includes(x)).every((x) => x);
			if (isPedoQuad1) res = 'Quadrant 1';
			else if (isPedoQuad2) res = 'Quadrant 2';
			else if (isPedoQuad3) res = 'Quadrant 3';
			else if (isPedoQuad4) res = 'Quadrant 4';
		} else if (length == 16) {
			const isUpperArch = toothNos
				.map((x) => Quad1.includes(x) || Quad2.includes(x))
				.every((x) => x);
			const isLowerArch = toothNos
				.map((x) => Quad3.includes(x) || Quad4.includes(x))
				.every((x) => x);
			if (isUpperArch) res = 'Upper Arch';
			else if (isLowerArch) res = 'Lower Arch';
		} else if (length == 10) {
			const isPedoUpperArch = toothNos
				.map((x) => pedoQuad1.includes(x) || pedoQuad2.includes(x))
				.every((x) => x);
			const isPedoLowerArch = toothNos
				.map((x) => pedoQuad3.includes(x) || pedoQuad4.includes(x))
				.every((x) => x);
			if (isPedoUpperArch) res = 'Upper Arch';
			else if (isPedoLowerArch) res = 'Lower Arch';
		} else if (length == 32 || length == 20) {
			res = 'Full mouth';
		} else {
			res = tooth;
		}
	}
	return res || tooth;
};

export const getDayByNumber = (num) => {
	const days = {
		0: 'Sunday',
		1: 'Monday',
		2: 'Tuesday',
		3: 'Wednesday',
		4: 'Thursday',
		5: 'Friday',
		6: 'Saturday',
	};
	return days[num];
};

export const months = {
	0: 'January',
	1: 'February',
	2: 'March',
	3: 'April',
	4: 'May',
	5: 'June',
	6: 'July',
	7: 'August',
	8: 'September',
	9: 'October',
	10: 'November',
	11: 'December',
};

export const getMonthByNumber = (num) => {
	return months[num];
};

export const GetNestedValue = (obj, path, defaultVal) => {
	try {
		if (!obj || typeof obj !== 'object') {
			return defaultVal; // Return default value if obj is not an object or is null/undefined
		}

		const keys = path?.split('.');
		let currentObj = obj;

		for (const key of keys) {
			if (currentObj && typeof currentObj === 'object' && key in currentObj) {
				currentObj = currentObj[key];
			} else {
				return defaultVal; // Return default value if the key is not present or the value is undefined
			}
		}
		return currentObj ?? defaultVal;
	} catch (err) {
		console.error(err);
		return defaultVal;
	}
};
