import { SET_OPD_RECORDS, DELETE_OPD_RECORD } from '../reducers/types';
import axios from '../helpers/axios';
import showNotification from '../components/extras/showNotification';
import { dashboardMenu } from '../menu';
import { getDoctors } from './doctorActions';
import { store } from '../store/configureStore';
import { getOpdHistory } from './helperActions';

export const getOpdRecordsByUserId = (userId) => async (dispatch) => {
	try {
		const res = await axios.get('/opd', { params: { userId, limit: 1000 } });
		if (res.data.success) {
			return res.data?.opds?.docs || [];
		}
	} catch (err) {
		console.log(err);
		return null;
	}
};

export const getOpdRecordsByDept = (deptId) => async (dispatch) => {
	try {
		const res = await axios.get('/opd/department-opd', {
			params: { departmentId: deptId, limit: 1000 },
		});
		if (res.data.success) {
			dispatch({
				type: SET_OPD_RECORDS,
				payload: res.data?.opds || [],
			});
		}
	} catch (err) {
		console.log(err);
	}
};

export const getOpdRecords = () => async (dispatch) => {
	try {
		const res = await axios.get('/opd', { params: { limit: 1000 } });
		if (res.data.success) {
			dispatch({
				type: SET_OPD_RECORDS,
				payload: res.data?.opds?.docs || [],
			});
		}
	} catch (err) {
		console.log(err);
	}
};

export const getOpdTransactionRecords = async () => {
	try {
		const res = await axios.get('/opd/organisations');
		if (res.data.success) {
			// dispatch({
			// 	type: SET_OPD_RECORDS,
			// 	payload: res.data?.opds?.docs || [],
			// });
			return res.data?.opds || [];
		}
	} catch (err) {
		console.log(err);
	}
};

export const createOpdRecord = (newRec, navigate, deptId) => async (dispatch) => {
	try {
		const res = await axios.post('/opd', newRec);
		if (res.data.success) {
			if (deptId) dispatch(getOpdRecordsByDept(deptId));
			else dispatch(getOpdRecords());
			showNotification('Success', 'The record is created successfully', 'success');
			navigate(`/${dashboardMenu.opd.subMenu.viewOpdRecords.path}`);
		}
	} catch (err) {
		console.log(err);
	}
};

export const updateOpdRecord = (updateBody, navigate) => async (dispatch) => {
	const selectedDept = store.getState?.()?.profile?.selectedDept;
	try {
		const res = await axios.put('/opd/update-status-on-department', {
			assignId: updateBody?.assignId,
			status: updateBody?.status,
		});
		if (res.data.success) {
			const res = await axios.get(`/opd/${updateBody.opdId}`);
			if (res.data.success && res.data.opd) {
				if (selectedDept)
					dispatch(getOpdRecordsByDept(selectedDept?.departmentId || selectedDept?.id));
				else dispatch(getOpdRecords());
				showNotification('Success', 'The record is updated successfully', 'success');
				if (navigate) navigate(`/${dashboardMenu.opd.subMenu.viewOpdRecords.path}`);
			}
		}
	} catch (err) {
		console.log(err);
	}
};

export const deleteOpdRecord = (recordId) => async (dispatch) => {
	try {
		const res = await axios.delete(`/opd/delete-assign-department/${recordId}`);
		if (res.data.success) {
			dispatch({
				type: DELETE_OPD_RECORD,
				payload: recordId,
			});
			// if (deptId) dispatch(getOpdRecordsByDept(deptId));
			// else
			// dispatch(getOpdRecords());

			showNotification('Success', 'The record has been deleted successfully', 'success');
		}
	} catch (err) {
		console.log(err);
	}
};

export const assignDoctorToOpd =
	(data, deptId = null) =>
	async (dispatch) => {
		try {
			const res = await axios.post(`/opd/assign`, data);
			if (res.data.success) {
				showNotification('Success', 'OPD Transferred to Department', 'success');
				if (deptId) dispatch(getOpdRecordsByDept(deptId));
				else dispatch(getOpdRecords());
			}
		} catch (error) {
			console.error(error);
		}
	};

export const updateVendorInOpd =
	(data, deptId = null) =>
	async (dispatch) => {
		try {
			const res = await axios.put(`/opd/assign-doctor-to-department`, data);
			if (res.data.success) {
				if (deptId) dispatch(getOpdRecordsByDept(deptId));
				else dispatch(getOpdRecords());
			}
		} catch (error) {
			console.error(error);
		}
	};

export const transferOpd = (data, deptId) => async (dispatch) => {
	try {
		const res = await axios.post(`/doctor/patient/transfer-opd`, data);
		if (res.status == 200) {
			dispatch(getOpdRecordsByDept(deptId));
		}
	} catch (error) {
		console.error(error);
	}
};

export const assignStudentToDoctor = (data) => async (dispatch) => {
	try {
		const res = await axios.get(`doctor/profile/assign-student-to-doctor`, { params: data });
		if (res.status == 200) {
			dispatch(getDoctors());
		}
	} catch (error) {
		console.error(error);
	}
};

export const assignOpdToStudent = (data, deptId) => async (dispatch) => {
	try {
		const res = await axios.post(`/doctor/patient/transfer-patient`, data);
		if (res.data.success) {
			dispatch(getOpdRecordsByDept(deptId));
		}
	} catch (error) {
		console.error(error);
	}
};

export const getOpdStatusByVendor = async (vendorId) => {
	try {
		const res = await axios.get(`/opd/get-vendor-opd-status/${vendorId}`);
		if (res.data.success) {
			return res.data.data;
		}
	} catch (error) {
		console.error(error);
	}
};

export const updateDeptOpdStatus = async (data, opdId) => {
	try {
		const res = await axios.put(`/opd/update-status-on-department`, data);
		if (res.data.success) {
			return await getOpdHistory(opdId);
		}
	} catch (error) {
		console.error(error);
		return [];
	}
};

export const removeVendorFromOpd = async (opdDepartmentId, dispatch) => {
	try {
		const res = await axios.delete(`/opd/removed-student-opd-department/${opdDepartmentId}`);
		if (res.data.status) {
			dispatch(getOpdRecords());
		}
	} catch (error) {
		console.error(error);
	}
};
