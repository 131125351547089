// This is the reducer for the highly reused component state like Table
import { SET_TABLE_LOADING, SET_CREATE_LOADING } from './types';

const initialState = {
	tableLoading: false,
	createLoading: false,
};

const componentReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_TABLE_LOADING:
			return {
				...state,
				tableLoading: action.payload,
			};
		case SET_CREATE_LOADING:
			return {
				...state,
				createLoading: action.payload,
			};
		default:
			return state;
	}
};

export default componentReducer;
