import { SET_CONSENT_MODAL, SET_REPORTS_MODAL } from './types';

const initialState = {
	viewReports: false,
};

const buttonReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_REPORTS_MODAL:
			return {
				...state,
				viewReports: action.payload,
			};
		case SET_CONSENT_MODAL:
			return {
				...state,
				generalConsent: action.payload,
			};
		default:
			return state;
	}
};

export default buttonReducer;
