import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Person from '../../assets/img/person.png';
import Doctor from '../../assets/img/doctor.png';
import Patient from '../../assets/img/patient.png';
import Organisation from '../../assets/img/organisation.png';
import Pharmacy from '../../assets/img/pharmacy.png';
import Status from '../../assets/img/status.png';

const PlaceholderImage = ({ avatar, width, height, text, className, ariaLabel }) => {
	var avatarImg;

	switch (avatar) {
		case 'doctor':
			avatarImg = Doctor;
			break;
		case 'patient':
			avatarImg = Patient;
			break;
		case 'person':
			avatarImg = Person;
			break;
		case 'organisation':
			avatarImg = Organisation;
			break;
		case 'pharmacy':
			avatarImg = Pharmacy;
			break;
		case 'status':
			avatarImg = Status;
			break;
		default:
			avatarImg = Person;
			break;
	}

	return (
		<img
			aria-label={ariaLabel || `avatar image`}
			className={classNames('placeholder-img', className)}
			width={width}
			height={height}
			src={avatarImg}
			alt='avatar image'
		/>
	);
};
PlaceholderImage.propTypes = {
	width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	text: PropTypes.string,
	className: PropTypes.string,
	ariaLabel: PropTypes.string,
};
PlaceholderImage.defaultProps = {
	width: 75,
	height: 75,
	text: null,
	className: null,
	ariaLabel: null,
};

export default PlaceholderImage;
