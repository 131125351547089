import { AUTH_LOGOUT, LOADING_USER, SET_OTP_KEY, SET_USER } from '../reducers/types';
import axios from '../helpers/axios';
import showNotification from '../components/extras/showNotification';
import { setInitialOrganisation } from './organisationActions';
import { getUserPermissions } from './profileActions';
import { setInitialDept } from './departmentActions';

export const sendOtp = (phone) => async (dispatch) => {
	try {
		dispatch({
			type: LOADING_USER,
		});
		const res = await axios.post('/doctor/getotpForWeb', {
			phone,
			countryCode: '+91',
			loginType: 'dentalEHR',
		});
		if (res.data.success) {
			dispatch({
				type: SET_OTP_KEY,
				payload: res.data.key,
			});
			showNotification('Success', 'An OTP has been sent to the Phone Number', 'success');
		}
	} catch (err) {
		dispatch({
			type: SET_OTP_KEY,
			payload: null,
		});
		console.log(err);
	}
};

export const resendOtp = (key) => async (dispatch) => {
	try {
		dispatch({
			type: LOADING_USER,
		});
		const res = await axios.post('/doctor/resend-otp', { key });
		if (res.data.success) {
			showNotification('Success', 'OTP has been resent to the Phone Number', 'success');
		}
	} catch (err) {
		console.log(err);
	}
};

export const verifyOtp = (key, otp, navigate) => async (dispatch) => {
	try {
		dispatch({
			type: LOADING_USER,
		});
		const res = await axios.post('/doctor/verify-otp', { key, otp });
		if (res.data.success) {
			setAuthorizationHeader(res.data.token);
			dispatch({
				type: SET_USER,
				payload: res.data.user,
			});
			if (res.data.user?.Vendor_organisations?.length > 0) {
				dispatch(setInitialOrganisation(res.data.user?.Vendor_organisations?.[0]));
				dispatch(
					getUserPermissions(
						res.data.user?.Vendor_organisations?.[0]?.organisationId,
						res.data?.user?.id,
					),
				);
			}
			if (res.data.user?.Vendor_departments?.length > 0) {
				dispatch(setInitialDept(res.data.user?.Vendor_departments?.[0]));
			}
			navigate('/', { replace: true });
		}
	} catch (err) {
		dispatch({
			type: SET_OTP_KEY,
			payload: null,
		});
		console.log(err);
	}
};

export const logout = () => (dispatch) => {
	localStorage.removeItem('ehrToken');
	localStorage.removeItem('init_org');
	delete axios.defaults.headers.common.Authorization;
	dispatch({ type: AUTH_LOGOUT });
};

const setAuthorizationHeader = (token) => {
	localStorage.setItem('ehrToken', token);
	axios.defaults.headers.common.Authorization = token;
};
