import React, { lazy } from 'react';
import { authMenu, dashboardMenu } from '../menu';

const LANDING = {
	DASHBOARD: lazy(() => import('../pages/dashboard/DashboardPage')),
};
const APPOINTMENTS = {
	ADD: lazy(() => import('../pages/appointment/AppointmentList')),
	LIST: lazy(() => import('../pages/appointment/CalendarPage')),
};
const PATIENTS = {
	ADD: lazy(() => import('../pages/patients/AddPatient')),
	LIST: lazy(() => import('../pages/patients/PatientsList')),
	VIEW: lazy(() => import('../pages/patients/ViewPatient')),

	// PDFs
	FACE_CARD: lazy(() => import('../pages/patients/components/FaceCard')),
	EXAMINATION_PDF: lazy(() => import('../pages/patients/components/ExaminationPdf')),
	TREATMENT_PLAN_PDF: lazy(() => import('../pages/patients/components/TreatmentPlanPdf')),
	TREATMENT_PDF: lazy(() => import('../pages/patients/components/TreatmentPdf')),
	PRESCRIPTION_PDF: lazy(() => import('../pages/patients/components/PrescriptionPdf')),
	BILLING_PDF: lazy(() => import('../pages/patients/components/BillingPdf')),
	PAYMENT_PDF: lazy(() => import('../pages/patients/components/PaymentPdf')),
	OPD_PDF: lazy(() => import('../pages/patients/components/OpdPdf')),
	SHORT_REPORT: lazy(() => import('../pages/patients/components/ShortReport')),
	LONG_REPORT: lazy(() => import('../pages/patients/components/LongReport')),
	PATHOLOGY: lazy(() => import('../pages/patients/components/PathologyPdf')),
	REQUISITION: lazy(() => import('../pages/patients/components/RequisitionPdf')),
	DETAILED_LONG_REPORT: lazy(() => import('../pages/patients/components/DetailedLongReport')),
	STATIC_CONSENT_FORM: lazy(() => import('../pages/patients/components/StaticConsentForm')),
	ORTHODONTIC_TRETMENT_CONSENT: lazy(() =>
		import('../pages/patients/components/OrthodonticTreatmentConsent'),
	),
};
const OPD = {
	ADD_RECORD: lazy(() => import('../pages/opd/AddOPDRecord')),
	VIEW_RECORDS: lazy(() => import('../pages/opd/OPDRecordList')),
	PDF_HEADER: lazy(() => import('../pages/opd/components/HeaderPdf')),
};
// const IPD = {
// 	ADD_ACCOMMODATION: lazy(() => import('../pages/ipd/AddAccomodation')),
// 	VIEW_ACCOMMODATIONS: lazy(() => import('../pages/ipd/AccomodationList')),
// 	CREATE_IPD: lazy(() => import('../pages/ipd/createIpd')),
// 	VIEW_IPDS: lazy(() => import('../pages/ipd/IpdList')),
// };
const STORE = {
	STORE_ITEMS: lazy(() => import('../pages/store/Items')),
	STORE_PURCHASE: lazy(() => import('../pages/store/Purchase')),
	ADD_STORE_PURCHASE: lazy(() => import('../pages/store/AddPurchase')),
	SALES_INDENTATION: lazy(() => import('../pages/store/SalesIndentation')),
	ADD_SALES_INDENTATION: lazy(() => import('../pages/store/AddSalesIndentation')),
	STORE_INVENTORY: lazy(() => import('../pages/store/StoreInventory')),
};
const PHARMACY = {
	ADD_PHARMACY: lazy(() => import('../pages/pharmacy/AddPharmacy')),
	PHARMACY_SETTINGS: lazy(() => import('../pages/pharmacy/PharmacySettings')),
	ADD_VENDOR: lazy(() => import('../pages/pharmacy/AddVendor')),
	VENDORS: lazy(() => import('../pages/pharmacy/VendorsList')),
	ADD_PURCHASE_VOUCHER: lazy(() => import('../pages/pharmacy/AddPurchaseVoucher')),
	PURCHASE_VOUCHERS: lazy(() => import('../pages/pharmacy/PurchaseVouchersList')),
	INVENTORY: lazy(() => import('../pages/pharmacy/InventoriesList')),
	ADD_SALE_VOUCHER: lazy(() => import('../pages/pharmacy/AddSaleVoucher')),
	SALE_VOUCHERS: lazy(() => import('../pages/pharmacy/SaleVouchersList')),
	SALE_VOUCHER_PDF: lazy(() => import('../pages/pharmacy/SalesVoucherPdf')),
};
const STAFF = {
	ADD_STAFF: lazy(() => import('../pages/staff/AddStaff')),
	VIEW_STAFF: lazy(() => import('../pages/staff/StaffList')),
	VIEW_STAFF_PERMISSIONS: lazy(() => import('../pages/staff/StaffPermissions')),
	VIEW_STAFF_ATTENDANCE: lazy(() => import('../pages/staff/StaffAttendanceList')),
	VIEW_STAFF_DETAILS: lazy(() => import('../pages/staff/ViewStaffDetails')),
};
const ACCOUNTS = {
	ADD_VENDOR: lazy(() => import('../pages/accounts/AddVendor')),
	VENDORS: lazy(() => import('../pages/accounts/VendorList')),
	ADD_TRANSACTION: lazy(() => import('../pages/accounts/AddTransaction')),
	TRANSACTION: lazy(() => import('../pages/accounts/TransactionList')),
	SUMMARY: lazy(() => import('../pages/accounts/Summary')),
	MIS: lazy(() => import('../pages/accounts/OrganisationMIS')),
	EXPORT_MIS: lazy(() => import('../pages/accounts/OrgMISPrint')),
	EXPORT_DR_MIS: lazy(() => import('../pages/accounts/ExportDrMIS.js')),
	MIS_SPECIFIC: lazy(() => import('../pages/accounts/OrgMISSpecific')),
};

const LAB = {
	ADD_LAB_VENDOR: lazy(() => import('../pages/lab/AddLabVendor')),
	LAB_VENDORS: lazy(() => import('../pages/lab/LabVendorList')),
	ADD_LAB_TRANSACTION: lazy(() => import('../pages/lab/AddLabTransaction')),
	LAB_TRANSACTIONS: lazy(() => import('../pages/lab/LabTransactionsList')),
	LAB_LOG: lazy(() => import('../pages/lab/LabLog')),
	LAB_SUMMARY: lazy(() => import('../pages/lab/LabSummary')),
};

const SETTINGS = {
	PROFILE: lazy(() => import('../pages/profile/Profile')),
	CONSENTS: lazy(() => import('../pages/settings/Consents')),
	CONSENTS_PRINT: lazy(() => import('../pages/settings/ConsentFormPrint')),
	ADD_DOCTOR: lazy(() => import('../pages/doctors/AddDoctor')),
	DOCTORS: lazy(() => import('../pages/doctors/DoctorsList')),
	ADD_STUDENT: lazy(() => import('../pages/students/AddStudent')),
	STUDENTS: lazy(() => import('../pages/students/StudentsList')),
	ADD_ORGANISATION: lazy(() => import('../pages/organisations/AddOrganisation')),
	ORGANISATIONS: lazy(() => import('../pages/organisations/OrganisationsList')),
	ADD_DEPARTMENT: lazy(() => import('../pages/departments/AddDepartment')),
	DEPARTMENTS: lazy(() => import('../pages/departments/DepartmentsList')),
	OPD_STATUSES: lazy(() => import('../pages/settings/OPDStatuses')),
	CHIEF_COMPLAINTS: lazy(() => import('../pages/settings/ChiefComplaints')),
	ORAL_EXAMINATIONS: lazy(() => import('../pages/settings/OralExaminations')),
	DIAGNOSES: lazy(() => import('../pages/settings/Diagnoses')),
	COMMUNICATION_GROUPS: lazy(() => import('../pages/settings/CommunicationGroups')),
	REFERRAL_TYPES: lazy(() => import('../pages/settings/ReferralTypes')),
	EXTERNAL_REFERRERS: lazy(() => import('../pages/settings/ExternalReferrers')),
	TREATMENTS: lazy(() => import('../pages/settings/Treatments')),
	PRESCRIPTION_TEMPLATES: lazy(() => import('../pages/settings/PrescriptionTemplates')),
	NOTES: lazy(() => import('../pages/settings/Notes')),
	INVESTIGATIONS: lazy(() => import('../pages/settings/Investigations')),
	PENDING_REQUESTS: lazy(() => import('../pages/pending-requests/PendingRequests')),
};

const AUTH = {
	PAGE_404: lazy(() => import('../pages/auth/Page404')),
	LOGIN: lazy(() => import('../pages/auth/Login')),
	LOGOUT: lazy(() => import('../pages/auth/Logout')),
};

const contents = [
	/**
	 * Landing
	 */
	{
		path: dashboardMenu.dashboard.path,
		element: <LANDING.DASHBOARD />,
		exact: true,
	},
	{
		path: dashboardMenu.appointments.subMenu.addAppointment.path,
		element: <APPOINTMENTS.ADD />,
		exact: true,
	},
	{
		path: dashboardMenu.appointments.subMenu.viewAppointments.path,
		element: <APPOINTMENTS.LIST />,
		exact: true,
	},
	{
		path: dashboardMenu.patients.subMenu.addPatient.path,
		element: <PATIENTS.ADD />,
		exact: true,
	},
	{
		path: dashboardMenu.patients.subMenu.viewPatient.path,
		element: <PATIENTS.VIEW />,
		exact: true,
	},
	{
		path: dashboardMenu.patients.subMenu.viewPatients.path,
		element: <PATIENTS.LIST />,
		exact: true,
	},

	// --------- Patient PDFs
	{
		path: dashboardMenu.patients.subMenu.patientExaminationPdf.path,
		element: <PATIENTS.EXAMINATION_PDF />,
		exact: true,
	},
	{
		path: dashboardMenu.patients.subMenu.faceCard.path,
		element: <PATIENTS.FACE_CARD />,
		exact: true,
	},
	{
		path: dashboardMenu.patients.subMenu.patientTreatmentPlanPdf.path,
		element: <PATIENTS.TREATMENT_PLAN_PDF />,
		exact: true,
	},
	{
		path: dashboardMenu.patients.subMenu.patientTreatmentPdf.path,
		element: <PATIENTS.TREATMENT_PDF />,
		exact: true,
	},
	{
		path: dashboardMenu.patients.subMenu.patientPrescriptionPdf.path,
		element: <PATIENTS.PRESCRIPTION_PDF />,
		exact: true,
	},
	{
		path: dashboardMenu.patients.subMenu.patientBillingPdf.path,
		element: <PATIENTS.BILLING_PDF />,
		exact: true,
	},
	{
		path: dashboardMenu.patients.subMenu.patientPaymentPdf.path,
		element: <PATIENTS.PAYMENT_PDF />,
		exact: true,
	},
	{
		path: dashboardMenu.patients.subMenu.patientOpdPdf.path,
		element: <PATIENTS.OPD_PDF />,
		exact: true,
	},
	{
		path: dashboardMenu.patients.subMenu.patientShortReport.path,
		element: <PATIENTS.SHORT_REPORT />,
		exact: true,
	},
	{
		path: dashboardMenu.patients.subMenu.patientLongReport.path,
		element: <PATIENTS.LONG_REPORT />,
		exact: true,
	},
	{
		path: dashboardMenu.patients.subMenu.pathology.path,
		element: <PATIENTS.PATHOLOGY />,
		exact: true,
	},
	{
		path: dashboardMenu.patients.subMenu.requisition.path,
		element: <PATIENTS.REQUISITION />,
		exact: true,
	},
	{
		path: dashboardMenu.patients.subMenu.patientDetailedLongReport.path,
		element: <PATIENTS.DETAILED_LONG_REPORT />,
		exact: true,
	},
	{
		path: dashboardMenu.patients.subMenu.staticConsentForm.path,
		element: <PATIENTS.STATIC_CONSENT_FORM />,
		exact: true,
	},
	{
		path: dashboardMenu.patients.subMenu.orthodonticTreatmentConsent.path,
		element: <PATIENTS.ORTHODONTIC_TRETMENT_CONSENT />,
		exact: true,
	},

	// ------ END OF Patient PDFs

	{
		path: dashboardMenu.opd.subMenu.addOpdRecord.path,
		element: <OPD.ADD_RECORD />,
		exact: true,
	},
	{
		path: dashboardMenu.opd.subMenu.viewOpdRecords.path,
		element: <OPD.VIEW_RECORDS />,
		exact: true,
	},
	{
		path: dashboardMenu.opd.subMenu.pdfHeader.path,
		element: <OPD.PDF_HEADER />,
		exact: true,
	},
	{
		path: dashboardMenu.pharmacy.subMenu.addPharmacy.path,
		element: <PHARMACY.ADD_PHARMACY />,
		exact: true,
	},
	{
		path: dashboardMenu.pharmacy.subMenu.pharmacySettings.path,
		element: <PHARMACY.PHARMACY_SETTINGS />,
		exact: true,
	},
	{
		path: dashboardMenu.pharmacy.subMenu.addVendor.path,
		element: <PHARMACY.ADD_VENDOR />,
		exact: true,
	},
	{
		path: dashboardMenu.pharmacy.subMenu.vendors.path,
		element: <PHARMACY.VENDORS />,
		exact: true,
	},
	{
		path: dashboardMenu.pharmacy.subMenu.addPurchaseVoucher.path,
		element: <PHARMACY.ADD_PURCHASE_VOUCHER />,
		exact: true,
	},
	{
		path: dashboardMenu.pharmacy.subMenu.purchaseVouchers.path,
		element: <PHARMACY.PURCHASE_VOUCHERS />,
		exact: true,
	},
	{
		path: dashboardMenu.pharmacy.subMenu.inventory.path,
		element: <PHARMACY.INVENTORY />,
		exact: true,
	},
	{
		path: dashboardMenu.pharmacy.subMenu.addSaleVoucher.path,
		element: <PHARMACY.ADD_SALE_VOUCHER />,
		exact: true,
	},
	{
		path: dashboardMenu.pharmacy.subMenu.saleVouchers.path,
		element: <PHARMACY.SALE_VOUCHERS />,
		exact: true,
	},
	{
		path: dashboardMenu.pharmacy.subMenu.saleVoucher.path,
		element: <PHARMACY.SALE_VOUCHER_PDF />,
		exact: true,
	},
	{
		path: dashboardMenu.staff.subMenu.addStaff.path,
		element: <STAFF.ADD_STAFF />,
		exact: true,
	},
	{
		path: dashboardMenu.staff.subMenu.viewStaff.path,
		element: <STAFF.VIEW_STAFF />,
		exact: true,
	},
	{
		path: dashboardMenu.staff.subMenu.staffPermissions.path,
		element: <STAFF.VIEW_STAFF_PERMISSIONS />,
		exact: true,
	},
	{
		path: dashboardMenu.staff.subMenu.viewStaffAttendance.path,
		element: <STAFF.VIEW_STAFF_ATTENDANCE />,
		exact: true,
	},
	{
		path: dashboardMenu.staff.subMenu.viewStaffDetails.path,
		element: <STAFF.VIEW_STAFF_DETAILS />,
		exact: true,
	},
	{
		path: dashboardMenu.ipd.subMenu.storeItems.path,
		element: <STORE.STORE_ITEMS />,
		exact: true,
	},
	{
		path: dashboardMenu.ipd.subMenu.storePurchases.path,
		element: <STORE.STORE_PURCHASE />,
		exact: true,
	},
	{
		path: dashboardMenu.ipd.subMenu.addStorePurchase.path,
		element: <STORE.ADD_STORE_PURCHASE />,
		exact: true,
	},
	{
		path: dashboardMenu.ipd.subMenu.salesIndentation.path,
		element: <STORE.SALES_INDENTATION />,
		exact: true,
	},
	{
		path: dashboardMenu.ipd.subMenu.addSalesIndentation.path,
		element: <STORE.ADD_SALES_INDENTATION />,
		exact: true,
	},
	{
		path: dashboardMenu.ipd.subMenu.storeInventory.path,
		element: <STORE.STORE_INVENTORY />,
		exact: true,
	},
	{
		path: dashboardMenu.accounts.subMenu.addVendor.path,
		element: <ACCOUNTS.ADD_VENDOR />,
		exact: true,
	},
	{
		path: dashboardMenu.accounts.subMenu.vendors.path,
		element: <ACCOUNTS.VENDORS />,
		exact: true,
	},
	{
		path: dashboardMenu.accounts.subMenu.addTransactions.path,
		element: <ACCOUNTS.ADD_TRANSACTION />,
		exact: true,
	},
	{
		path: dashboardMenu.accounts.subMenu.transactions.path,
		element: <ACCOUNTS.TRANSACTION />,
		exact: true,
	},
	{
		path: dashboardMenu.accounts.subMenu.summary.path,
		element: <ACCOUNTS.SUMMARY />,
		exact: true,
	},
	{
		path: dashboardMenu.accounts.subMenu.mis.path,
		element: <ACCOUNTS.MIS />,
		exact: true,
	},
	{
		path: dashboardMenu.accounts.subMenu.printMis.path,
		element: <ACCOUNTS.EXPORT_MIS />,
		exact: true,
	},
	{
		path: dashboardMenu.accounts.subMenu.printDrWise.path,
		element: <ACCOUNTS.EXPORT_DR_MIS />,
		exact: true,
	},
	{
		path: dashboardMenu.accounts.subMenu.misSpecific.path,
		element: <ACCOUNTS.MIS_SPECIFIC />,
		exact: true,
	},
	{
		path: dashboardMenu.lab.subMenu.addLabVendor.path,
		element: <LAB.ADD_LAB_VENDOR />,
		exact: true,
	},
	{
		path: dashboardMenu.lab.subMenu.labVendors.path,
		element: <LAB.LAB_VENDORS />,
		exact: true,
	},
	{
		path: dashboardMenu.lab.subMenu.addLabTransactions.path,
		element: <LAB.ADD_LAB_TRANSACTION />,
		exact: true,
	},
	{
		path: dashboardMenu.lab.subMenu.labTransactions.path,
		element: <LAB.LAB_TRANSACTIONS />,
		exact: true,
	},
	{
		path: dashboardMenu.lab.subMenu.labLog.path,
		element: <LAB.LAB_LOG />,
		exact: true,
	},
	{
		path: dashboardMenu.lab.subMenu.labSummary.path,
		element: <LAB.LAB_SUMMARY />,
		exact: true,
	},
	{
		path: dashboardMenu.settings.subMenu.profile.path,
		element: <SETTINGS.PROFILE />,
		exact: true,
	},
	{
		path: dashboardMenu.settings.subMenu.consents.path,
		element: <SETTINGS.CONSENTS />,
		exact: true,
	},
	{
		path: dashboardMenu.settings.subMenu.consentsPrint.path,
		element: <SETTINGS.CONSENTS_PRINT />,
		exact: true,
	},
	{
		path: dashboardMenu.settings.subMenu.addStudent.path,
		element: <SETTINGS.ADD_STUDENT />,
		exact: true,
	},
	{
		path: dashboardMenu.settings.subMenu.viewStudents.path,
		element: <SETTINGS.STUDENTS />,
		exact: true,
	},
	{
		path: dashboardMenu.settings.subMenu.addDoctor.path,
		element: <SETTINGS.ADD_DOCTOR />,
		exact: true,
	},
	{
		path: dashboardMenu.settings.subMenu.viewDoctors.path,
		element: <SETTINGS.DOCTORS />,
		exact: true,
	},
	{
		path: dashboardMenu.settings.subMenu.addOrganisation.path,
		element: <SETTINGS.ADD_ORGANISATION />,
		exact: true,
	},
	{
		path: dashboardMenu.settings.subMenu.viewOrganisations.path,
		element: <SETTINGS.ORGANISATIONS />,
		exact: true,
	},
	{
		path: dashboardMenu.settings.subMenu.opdStatuses.path,
		element: <SETTINGS.OPD_STATUSES />,
		exact: true,
	},
	{
		path: dashboardMenu.settings.subMenu.chiefComplaints.path,
		element: <SETTINGS.CHIEF_COMPLAINTS />,
		exact: true,
	},
	{
		path: dashboardMenu.settings.subMenu.diagnoses.path,
		element: <SETTINGS.DIAGNOSES />,
		exact: true,
	},
	{
		path: dashboardMenu.settings.subMenu.communicationGroups.path,
		element: <SETTINGS.COMMUNICATION_GROUPS />,
		exact: true,
	},
	{
		path: dashboardMenu.settings.subMenu.referralTypes.path,
		element: <SETTINGS.REFERRAL_TYPES />,
		exact: true,
	},
	{
		path: dashboardMenu.settings.subMenu.externalReferrers.path,
		element: <SETTINGS.EXTERNAL_REFERRERS />,
		exact: true,
	},
	{
		path: dashboardMenu.settings.subMenu.treatments.path,
		element: <SETTINGS.TREATMENTS />,
		exact: true,
	},
	{
		path: dashboardMenu.settings.subMenu.prescriptionTemplates.path,
		element: <SETTINGS.PRESCRIPTION_TEMPLATES />,
		exact: true,
	},
	{
		path: dashboardMenu.settings.subMenu.notes.path,
		element: <SETTINGS.NOTES />,
		exact: true,
	},
	{
		path: dashboardMenu.settings.subMenu.investigations.path,
		element: <SETTINGS.INVESTIGATIONS />,
		exact: true,
	},
	{
		path: dashboardMenu.settings.subMenu.oralExaminations.path,
		element: <SETTINGS.ORAL_EXAMINATIONS />,
		exact: true,
	},
	{
		path: dashboardMenu.settings.subMenu.addDepartment.path,
		element: <SETTINGS.ADD_DEPARTMENT />,
		exact: true,
	},
	{
		path: dashboardMenu.settings.subMenu.viewDepartments.path,
		element: <SETTINGS.DEPARTMENTS />,
		exact: true,
	},
	{
		path: dashboardMenu.settings.subMenu.pendingRequests.path,
		element: <SETTINGS.PENDING_REQUESTS />,
		exact: true,
	},
	/** ************************************************** */

	/**
	 * Auth Page
	 */
	{
		path: authMenu.page404.path,
		element: <AUTH.PAGE_404 />,
		exact: true,
	},
	{
		path: authMenu.login.path,
		element: <AUTH.LOGIN />,
		exact: true,
	},
	{
		path: authMenu.logout.path,
		element: <AUTH.LOGOUT />,
		exact: true,
	},
	// {
	// 	path: authMenu.signUp.path,
	// 	element: <Login isSignUp />,
	// 	exact: true,
	// },
];

export default contents;
