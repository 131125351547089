// ⚠️ warning: extreme coordinate geometry used
// do not touch until you understand it completely
// for info reach out: ayushk
import React, { useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';

import showNotification from '../../../../components/extras/showNotification';

// Atomic Width
const aW = 31.1;

// Position Constants,
// (0, 1, 2, 3)->(data top, bottom, left right)
// (4, 5, 6, 7)->(lower top, bottom, left right)
const uPos = {
	0: { x: 11.6, y: 8.5 },
	1: { x: 22, y: 17 },
	2: { x: 11.6, y: 26 },
	3: { x: 2, y: 17 },
	4: { x: 11.6, y: 35.5 },
	5: { x: 22, y: 44 },
	6: { x: 11.6, y: 53 },
	7: { x: 2, y: 44 },
};

const Texts = ({ data, openPopper }) =>
	data.map(({ x, y, value }) => {
		const color = value >= 6 ? 'red' : value >= 4 ? 'green' : value >= 1 ? 'blue' : 'black';
		const xModified = `${value}`.length === 2 ? x : x + 2;
		return (
			<text
				fill='black'
				fontSize='8px'
				x={xModified}
				y={y}
				onDoubleClick={(e) => openPopper(e, x, y)}
				style={{ fill: color }}>
				{value}
			</text>
		);
	});

const Box1SVG = ({ editItem, setData }) => {
	const containerRef = useRef(null);
	const inputRef = useRef(null);

	const [open, setOpen] = useState(false);
	const [curInputPos, setCurInputPos] = useState({ x: 0, y: 0 });
	const [curXY, setCurXY] = useState({ x: null, y: null });

	const formik = useFormik({
		initialValues: {
			data: Array.from(Array(128).keys()).map((i) => ({
				x: (i % 16) * aW + uPos[parseInt(i / 16)].x,
				y: uPos[parseInt(i / 16)].y,
				value: '0',
			})),
			input: '',
		},
	});

	const openPopper = (e, x, y) => {
		if (editItem) return;
		setCurXY({ x, y });

		const elementRect = containerRef.current.getBoundingClientRect();
		setCurInputPos({
			x: e.clientY - elementRect.top + 10,
			y: e.clientX - elementRect.left - 25,
		});
		setOpen(true);

		inputRef.current?.focus();
	};

	const handleSubmit = (e) => {
		const newVal = parseInt(e.target.value);
		if ((newVal == 0 || newVal) && newVal < 99 && newVal >= 0) {
			const pos = formik.values.data.findIndex(({ x, y }) => x == curXY.x && y == curXY.y);
			let newData = formik.values.data;
			newData[pos] = { x: curXY.x, y: curXY.y, value: e.target.value };
			formik.setValues({
				data: newData,
			});
			setData(newData);
			setOpen(false);
		} else {
			showNotification('Error', 'Invalid Value', 'danger');
		}
	};

	// Plug n Play
	// const onFinalSubmit = () => {
	// 	console.log(formik.values.data);
	// };

	useEffect(() => {
		if (editItem) {
			formik.setValues((prev) => ({
				...prev,
				data: editItem,
			}));
		}
	}, []);

	return (
		<div style={{ position: 'relative' }} ref={containerRef}>
			<svg
				xmlns='http://www.w3.org/2000/svg'
				id='Layer_1'
				data-name='Layer 1'
				viewBox='0 0 500.06 55.61'>
				<defs>
					<style>{'.cls-1{fill:none;stroke:#000;stroke-miterlimit:10}'}</style>
				</defs>
				<Texts data={formik.values.data} openPopper={openPopper} />
				{/* 1 - Path */}
				<path className='cls-1' d='M16.73 14.9L1.36 27.92 32.1 27.92 16.73 14.9z' />
				<path className='cls-1' d='M16.73 13.71L32.1 0.7 1.36 0.7 16.73 13.71z' />
				<path className='cls-1' d='M16.04 14.31L0.85 1.1 0.85 27.52 16.04 14.31z' />
				<path className='cls-1' d='M16.88 14.31L32.06 27.52 32.06 1.1 16.88 14.31z' />
				{/* 2 - Path */}
				<path className='cls-1' d='M47.84 14.9L32.47 27.92 63.2 27.92 47.84 14.9z' />
				<path className='cls-1' d='M47.84 13.71L63.2 0.7 32.47 0.7 47.84 13.71z' />
				<path className='cls-1' d='M47.15 14.31L31.96 1.1 31.96 27.52 47.15 14.31z' />
				<path className='cls-1' d='M47.98 14.31L63.17 27.52 63.17 1.1 47.98 14.31z' />
				{/* 3 - Path */}
				<path className='cls-1' d='M78.94 14.9L63.58 27.92 94.31 27.92 78.94 14.9z' />
				<path className='cls-1' d='M78.94 13.71L94.31 0.7 63.58 0.7 78.94 13.71z' />
				<path className='cls-1' d='M78.25 14.31L63.06 1.1 63.06 27.52 78.25 14.31z' />
				<path className='cls-1' d='M79.09 14.31L94.28 27.52 94.28 1.1 79.09 14.31z' />
				{/* 4 - Path */}
				<path className='cls-1' d='M110.05 14.9L94.68 27.92 125.42 27.92 110.05 14.9z' />
				<path className='cls-1' d='M110.05 13.71L125.42 0.7 94.68 0.7 110.05 13.71z' />
				<path className='cls-1' d='M109.36 14.31L94.17 1.1 94.17 27.52 109.36 14.31z' />
				<path className='cls-1' d='M110.19 14.31L125.38 27.52 125.38 1.1 110.19 14.31z' />
				{/* 5 - Path */}
				<path className='cls-1' d='M141.16 14.9L125.79 27.92 156.52 27.92 141.16 14.9z' />
				<path className='cls-1' d='M141.16 13.71L156.52 0.7 125.79 0.7 141.16 13.71z' />
				<path className='cls-1' d='M140.47 14.31L125.28 1.1 125.28 27.52 140.47 14.31z' />
				<path className='cls-1' d='M141.3 14.31L156.49 27.52 156.49 1.1 141.3 14.31z' />
				{/* 6 - Path */}
				<path className='cls-1' d='M172.26 14.9L156.9 27.92 187.63 27.92 172.26 14.9z' />
				<path className='cls-1' d='M172.26 13.71L187.63 0.7 156.9 0.7 172.26 13.71z' />
				<path className='cls-1' d='M171.57 14.31L156.38 1.1 156.38 27.52 171.57 14.31z' />
				<path className='cls-1' d='M172.41 14.31L187.6 27.52 187.6 1.1 172.41 14.31z' />
				{/* 7 - Path */}
				<path className='cls-1' d='M203.37 14.9L188 27.92 218.74 27.92 203.37 14.9z' />
				<path className='cls-1' d='M203.37 13.71L218.74 0.7 188 0.7 203.37 13.71z' />
				<path className='cls-1' d='M202.68 14.31L187.49 1.1 187.49 27.52 202.68 14.31z' />
				<path className='cls-1' d='M203.51 14.31L218.7 27.52 218.7 1.1 203.51 14.31z' />
				{/* 8 - Path */}
				<path className='cls-1' d='M234.47 14.9L219.11 27.92 249.84 27.92 234.47 14.9z' />
				<path className='cls-1' d='M234.47 13.71L249.84 0.7 219.11 0.7 234.47 13.71z' />
				<path className='cls-1' d='M233.78 14.31L218.6 1.1 218.6 27.52 233.78 14.31z' />
				<path className='cls-1' d='M234.62 14.31L249.81 27.52 249.81 1.1 234.62 14.31z' />
				{/* 9 - Path */}
				<path className='cls-1' d='M265.58 14.9L250.21 27.92 280.95 27.92 265.58 14.9z' />
				<path className='cls-1' d='M265.58 13.71L280.95 0.7 250.21 0.7 265.58 13.71z' />
				<path className='cls-1' d='M264.89 14.31L249.7 1.1 249.7 27.52 264.89 14.31z' />
				<path className='cls-1' d='M265.73 14.31L280.92 27.52 280.92 1.1 265.73 14.31z' />
				{/* 10 - Path */}
				<path className='cls-1' d='M296.69 14.9L281.32 27.92 312.06 27.92 296.69 14.9z' />
				<path className='cls-1' d='M296.69 13.71L312.06 0.7 281.32 0.7 296.69 13.71z' />
				<path className='cls-1' d='M296 14.31L280.81 1.1 280.81 27.52 296 14.31z' />
				<path className='cls-1' d='M296.83 14.31L312.02 27.52 312.02 1.1 296.83 14.31z' />
				{/* 11 - Path */}
				<path className='cls-1' d='M327.79 14.9L312.43 27.92 343.16 27.92 327.79 14.9z' />
				<path className='cls-1' d='M327.79 13.71L343.16 0.7 312.43 0.7 327.79 13.71z' />
				<path className='cls-1' d='M327.1 14.31L311.92 1.1 311.92 27.52 327.1 14.31z' />
				<path className='cls-1' d='M327.94 14.31L343.13 27.52 343.13 1.1 327.94 14.31z' />
				{/* 12 - Path */}
				<path className='cls-1' d='M358.9 14.9L343.53 27.92 374.27 27.92 358.9 14.9z' />
				<path className='cls-1' d='M358.9 13.71L374.27 0.7 343.53 0.7 358.9 13.71z' />
				<path className='cls-1' d='M358.21 14.31L343.02 1.1 343.02 27.52 358.21 14.31z' />
				<path className='cls-1' d='M359.05 14.31L374.24 27.52 374.24 1.1 359.05 14.31z' />
				{/* 13 - Path */}
				<path className='cls-1' d='M390.01 14.9L374.64 27.92 405.37 27.92 390.01 14.9z' />
				<path className='cls-1' d='M390.01 13.71L405.37 0.7 374.64 0.7 390.01 13.71z' />
				<path className='cls-1' d='M389.32 14.31L374.13 1.1 374.13 27.52 389.32 14.31z' />
				<path className='cls-1' d='M390.15 14.31L405.34 27.52 405.34 1.1 390.15 14.31z' />
				{/* 14 - Path */}
				<path className='cls-1' d='M421.11 14.9L405.75 27.92 436.48 27.92 421.11 14.9z' />
				<path className='cls-1' d='M421.11 13.71L436.48 0.7 405.75 0.7 421.11 13.71z' />
				<path className='cls-1' d='M420.42 14.31L405.23 1.1 405.23 27.52 420.42 14.31z' />
				<path className='cls-1' d='M421.26 14.31L436.45 27.52 436.45 1.1 421.26 14.31z' />
				{/* 15 - Path */}
				<path className='cls-1' d='M452.22 14.9L436.85 27.92 467.59 27.92 452.22 14.9z' />
				<path className='cls-1' d='M452.22 13.71L467.59 0.7 436.85 0.7 452.22 13.71z' />
				<path className='cls-1' d='M451.53 14.31L436.34 1.1 436.34 27.52 451.53 14.31z' />
				<path className='cls-1' d='M452.36 14.31L467.55 27.52 467.55 1.1 452.36 14.31z' />
				{/* 16 - Path */}
				<path className='cls-1' d='M483.33 14.9L467.96 27.92 498.69 27.92 483.33 14.9z' />
				<path className='cls-1' d='M483.33 13.71L498.69 0.7 467.96 0.7 483.33 13.71z' />
				<path className='cls-1' d='M482.63 14.31L467.45 1.1 467.45 27.52 482.63 14.31z' />
				<path className='cls-1' d='M483.47 14.31L498.66 27.52 498.66 1.1 483.47 14.31z' />
				{/* 17 - Path */}
				<path className='cls-1' d='M16.73 41.9L1.36 54.92 32.1 54.92 16.73 41.9z' />
				<path className='cls-1' d='M16.73 40.72L32.1 27.7 1.36 27.7 16.73 40.72z' />
				<path className='cls-1' d='M16.04 41.31L0.85 28.1 0.85 54.52 16.04 41.31z' />
				<path className='cls-1' d='M16.88 41.31L32.06 54.52 32.06 28.1 16.88 41.31z' />
				{/* 18 - Path */}
				<path className='cls-1' d='M47.84 41.9L32.47 54.92 63.2 54.92 47.84 41.9z' />
				<path className='cls-1' d='M47.84 40.72L63.2 27.7 32.47 27.7 47.84 40.72z' />
				<path className='cls-1' d='M47.15 41.31L31.96 28.1 31.96 54.52 47.15 41.31z' />
				<path className='cls-1' d='M47.98 41.31L63.17 54.52 63.17 28.1 47.98 41.31z' />
				{/* 19 - Path */}
				<path className='cls-1' d='M78.94 41.9L63.58 54.92 94.31 54.92 78.94 41.9z' />
				<path className='cls-1' d='M78.94 40.72L94.31 27.7 63.58 27.7 78.94 40.72z' />
				<path className='cls-1' d='M78.25 41.31L63.06 28.1 63.06 54.52 78.25 41.31z' />
				<path className='cls-1' d='M79.09 41.31L94.28 54.52 94.28 28.1 79.09 41.31z' />
				{/* 20 - Path */}
				<path className='cls-1' d='M110.05 41.9L94.68 54.92 125.42 54.92 110.05 41.9z' />
				<path className='cls-1' d='M110.05 40.72L125.42 27.7 94.68 27.7 110.05 40.72z' />
				<path className='cls-1' d='M109.36 41.31L94.17 28.1 94.17 54.52 109.36 41.31z' />
				<path className='cls-1' d='M110.19 41.31L125.38 54.52 125.38 28.1 110.19 41.31z' />
				{/* 21 - Path */}
				<path className='cls-1' d='M141.16 41.9L125.79 54.92 156.52 54.92 141.16 41.9z' />
				<path className='cls-1' d='M141.16 40.72L156.52 27.7 125.79 27.7 141.16 40.72z' />
				<path className='cls-1' d='M140.47 41.31L125.28 28.1 125.28 54.52 140.47 41.31z' />
				<path className='cls-1' d='M141.3 41.31L156.49 54.52 156.49 28.1 141.3 41.31z' />
				{/* 22 - Path */}
				<path className='cls-1' d='M172.26 41.9L156.9 54.92 187.63 54.92 172.26 41.9z' />
				<path className='cls-1' d='M172.26 40.72L187.63 27.7 156.9 27.7 172.26 40.72z' />
				<path className='cls-1' d='M171.57 41.31L156.38 28.1 156.38 54.52 171.57 41.31z' />
				<path className='cls-1' d='M172.41 41.31L187.6 54.52 187.6 28.1 172.41 41.31z' />
				{/* 23 - Path */}
				<path className='cls-1' d='M203.37 41.9L188 54.92 218.74 54.92 203.37 41.9z' />
				<path className='cls-1' d='M203.37 40.72L218.74 27.7 188 27.7 203.37 40.72z' />
				<path className='cls-1' d='M202.68 41.31L187.49 28.1 187.49 54.52 202.68 41.31z' />
				<path className='cls-1' d='M203.51 41.31L218.7 54.52 218.7 28.1 203.51 41.31z' />
				{/* 24 - Path */}
				<path className='cls-1' d='M234.47 41.9L219.11 54.92 249.84 54.92 234.47 41.9z' />
				<path className='cls-1' d='M234.47 40.72L249.84 27.7 219.11 27.7 234.47 40.72z' />
				<path className='cls-1' d='M233.78 41.31L218.6 28.1 218.6 54.52 233.78 41.31z' />
				<path className='cls-1' d='M234.62 41.31L249.81 54.52 249.81 28.1 234.62 41.31z' />
				{/* 25 - Path */}
				<path className='cls-1' d='M265.58 41.9L250.21 54.92 280.95 54.92 265.58 41.9z' />
				<path className='cls-1' d='M265.58 40.72L280.95 27.7 250.21 27.7 265.58 40.72z' />
				<path className='cls-1' d='M264.89 41.31L249.7 28.1 249.7 54.52 264.89 41.31z' />
				<path className='cls-1' d='M265.73 41.31L280.92 54.52 280.92 28.1 265.73 41.31z' />
				{/* 26 - Path */}
				<path className='cls-1' d='M296.69 41.9L281.32 54.92 312.06 54.92 296.69 41.9z' />
				<path className='cls-1' d='M296.69 40.72L312.06 27.7 281.32 27.7 296.69 40.72z' />
				<path className='cls-1' d='M296 41.31L280.81 28.1 280.81 54.52 296 41.31z' />
				<path className='cls-1' d='M296.83 41.31L312.02 54.52 312.02 28.1 296.83 41.31z' />
				{/* 27 - Path */}
				<path className='cls-1' d='M327.79 41.9L312.43 54.92 343.16 54.92 327.79 41.9z' />
				<path className='cls-1' d='M327.79 40.72L343.16 27.7 312.43 27.7 327.79 40.72z' />
				<path className='cls-1' d='M327.1 41.31L311.92 28.1 311.92 54.52 327.1 41.31z' />
				<path className='cls-1' d='M327.94 41.31L343.13 54.52 343.13 28.1 327.94 41.31z' />
				{/* 28 - Path */}
				<path className='cls-1' d='M358.9 41.9L343.53 54.92 374.27 54.92 358.9 41.9z' />
				<path className='cls-1' d='M358.9 40.72L374.27 27.7 343.53 27.7 358.9 40.72z' />
				<path className='cls-1' d='M358.21 41.31L343.02 28.1 343.02 54.52 358.21 41.31z' />
				<path className='cls-1' d='M359.05 41.31L374.24 54.52 374.24 28.1 359.05 41.31z' />
				{/* 29 - Path */}
				<path className='cls-1' d='M390.01 41.9L374.64 54.92 405.37 54.92 390.01 41.9z' />
				<path className='cls-1' d='M390.01 40.72L405.37 27.7 374.64 27.7 390.01 40.72z' />
				<path className='cls-1' d='M389.32 41.31L374.13 28.1 374.13 54.52 389.32 41.31z' />
				<path className='cls-1' d='M390.15 41.31L405.34 54.52 405.34 28.1 390.15 41.31z' />
				{/* 30 - Path */}
				<path className='cls-1' d='M421.11 41.9L405.75 54.92 436.48 54.92 421.11 41.9z' />
				<path className='cls-1' d='M421.11 40.72L436.48 27.7 405.75 27.7 421.11 40.72z' />
				<path className='cls-1' d='M420.42 41.31L405.23 28.1 405.23 54.52 420.42 41.31z' />
				<path className='cls-1' d='M421.26 41.31L436.45 54.52 436.45 28.1 421.26 41.31z' />
				{/* 31 - Path */}
				<path className='cls-1' d='M452.22 41.9L436.85 54.92 467.59 54.92 452.22 41.9z' />
				<path className='cls-1' d='M452.22 40.72L467.59 27.7 436.85 27.7 452.22 40.72z' />
				<path className='cls-1' d='M451.53 41.31L436.34 28.1 436.34 54.52 451.53 41.31z' />
				<path className='cls-1' d='M452.36 41.31L467.55 54.52 467.55 28.1 452.36 41.31z' />
				{/* 32 - Path */}
				<path className='cls-1' d='M483.33 41.9L467.96 54.92 498.69 54.92 483.33 41.9z' />
				<path className='cls-1' d='M483.33 40.72L498.69 27.7 467.96 27.7 483.33 40.72z' />
				<path className='cls-1' d='M482.63 41.31L467.45 28.1 467.45 54.52 482.63 41.31z' />
				<path className='cls-1' d='M483.47 41.31L498.66 54.52 498.66 28.1 483.47 41.31z' />
			</svg>

			{open && (
				<div
					style={{
						position: 'absolute',
						top: curInputPos?.x || 0,
						left: curInputPos?.y || 0,
					}}>
					<input
						ref={inputRef}
						autoFocus
						style={{ width: '4rem', borderRadius: '5px', border: '1px solid black' }}
						onKeyPress={(e) => {
							if (e.key == 'Enter') handleSubmit(e);
						}}
						onBlur={handleSubmit}
					/>
				</div>
			)}
		</div>
	);
};

export default Box1SVG;
