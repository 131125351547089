import _ from 'lodash';
import {
	SET_PATIENTS,
	LOADING_PATIENTS,
	CREATE_PATIENT,
	UPDATE_PATIENT,
	DELETE_PATIENT,
	SET_PATIENT_EXAMINATIONS,
	CREATE_PATIENT_EXAMINATION,
	UPDATE_PATIENT_EXAMINATION,
	DELETE_PATIENT_EXAMINATION,
	SET_PATIENT_TREATMENT_PLANS,
	CREATE_PATIENT_TREATMENT_PLAN,
	UPDATE_PATIENT_TREATMENT_PLAN,
	DELETE_PATIENT_TREATMENT_PLAN,
	SET_PATIENT_TREATMENTS,
	CREATE_PATIENT_TREATMENT,
	UPDATE_PATIENT_TREATMENT,
	DELETE_PATIENT_TREATMENT,
	SET_PATIENT_PRESCRIPTIONS,
	CREATE_PATIENT_PRESCRIPTION,
	UPDATE_PATIENT_PRESCRIPTION,
	DELETE_PATIENT_PRESCRIPTION,
	SET_PATIENT_BILLS,
	CREATE_PATIENT_BILL,
	UPDATE_PATIENT_BILL,
	DELETE_PATIENT_BILL,
	SET_PATIENT_PAYMENTS,
	CREATE_PATIENT_PAYMENT,
	UPDATE_PATIENT_PAYMENT,
	DELETE_PATIENT_PAYMENT,
	SET_PATIENT,
	SET_PATIENT_APPOINTMENTS,
	UPDATE_PATIENT_APPOINTMENT,
	DELETE_PATIENT_APPOINTMENT,
	CREATE_PATIENT_APPOINTMENT,
	SET_PATIENT_DOCUMENTS,
	SET_TIMINGS,
	SET_PATIENT_PICKLIST,
	SET_MEDICAL_ALERTS,
	SET_CHIEF_COMPLAINTS_VALUES,
	SET_CC_SYMPTOMS,
	SET_PATIENT_CASE_SHEETS,
	CREATE_PATIENT_CASE_SHEET,
	UPDATE_PATIENT_CASE_SHEET,
	DELETE_PATIENT_CASE_SHEET,
	SET_WHITEBOARD,
	SET_CLINICAL_NOTES,
	SET_SOAP_ATTACHMENTS,
	SET_PATIENT_REFUND_PAYMENTS,
	SET_PATIENT_VISITS,
} from './types';

const initialState = {
	pickList: null,
	patients: [],
	patient: null,
	examinationRecords: [],
	treatmentPlans: [],
	treatments: [],
	prescriptions: [],
	timings: [],
	bills: [],
	payments: [],
	refundPayments: [],
	appointments: [],
	opds: [],
	documents: [],
	medicalAlerts: [],
	chiefComplains: [],
	symptoms: [],
	caseSheets: [],
	soapAttachments: [],
	error: null,
	loading: false,
};

const patientReducer = (state = initialState, action) => {
	switch (action.type) {
		case LOADING_PATIENTS:
			return {
				...state,
				loading: true,
			};

		case SET_PATIENTS:
			return {
				...state,
				patients: action.payload || [],
				loading: false,
			};

		case SET_PATIENT:
			return {
				...state,
				patient: action.payload || null,
				loading: false,
			};

		case CREATE_PATIENT:
			return {
				...state,
				patients: [action.payload, ...state.patients],
			};

		case UPDATE_PATIENT:
			const patientIdx = _.findIndex(state.patients, (p) => p.id === action.payload.id);
			return {
				...state,
				patients: [
					...state.patients.slice(0, patientIdx),
					action.payload,
					...state.patients.slice(patientIdx + 1),
				],
			};

		case DELETE_PATIENT:
			return {
				...state,
				patients: _.reject(state.patients, (p) => p.id === action.payload.id),
			};

		case SET_PATIENT_EXAMINATIONS:
			return {
				...state,
				examinationRecords: action.payload || [],
				loading: false,
			};

		case CREATE_PATIENT_EXAMINATION:
			return {
				...state,
				examinationRecords: [action.payload, ...state.examinationRecords],
			};

		case UPDATE_PATIENT_EXAMINATION:
			const patientExamIdx = _.findIndex(
				state.examinationRecords,
				(p) => p.id === action.payload.id,
			);
			return {
				...state,
				examinationRecords: [
					...state.examinationRecords.slice(0, patientExamIdx),
					action.payload,
					...state.examinationRecords.slice(patientExamIdx + 1),
				],
			};

		case DELETE_PATIENT_EXAMINATION:
			return {
				...state,
				examinationRecords: _.reject(
					state.examinationRecords,
					(p) => p.id === action.payload,
				),
			};

		case SET_PATIENT_TREATMENT_PLANS:
			return {
				...state,
				treatmentPlans: action.payload || [],
				loading: false,
			};

		case CREATE_PATIENT_TREATMENT_PLAN:
			return {
				...state,
				treatmentPlans: [action.payload, ...state.treatmentPlans],
			};

		case UPDATE_PATIENT_TREATMENT_PLAN:
			const patientTPlanIdx = _.findIndex(
				state.treatmentPlans,
				(p) => p.id === action.payload.id,
			);
			return {
				...state,
				treatmentPlans: [
					...state.treatmentPlans.slice(0, patientTPlanIdx),
					action.payload,
					...state.treatmentPlans.slice(patientTPlanIdx + 1),
				],
			};

		case DELETE_PATIENT_TREATMENT_PLAN:
			return {
				...state,
				treatmentPlans: _.reject(state.treatmentPlans, (p) => p.id === action.payload),
			};

		case SET_PATIENT_TREATMENTS:
			return {
				...state,
				treatments: action.payload || [],
				loading: false,
			};

		case CREATE_PATIENT_TREATMENT:
			return {
				...state,
				treatments: [action.payload, ...state.treatments],
			};

		case UPDATE_PATIENT_TREATMENT:
			const patientTreatIdx = _.findIndex(
				state.treatments,
				(p) => p.id === action.payload.id,
			);
			return {
				...state,
				treatments: [
					...state.treatments.slice(0, patientTreatIdx),
					action.payload,
					...state.treatments.slice(patientTreatIdx + 1),
				],
			};

		case DELETE_PATIENT_TREATMENT:
			return {
				...state,
				treatments: _.reject(state.treatments, (p) => p.id === action.payload),
			};

		case SET_PATIENT_PRESCRIPTIONS:
			return {
				...state,
				prescriptions: action.payload || [],
				loading: false,
			};

		case CREATE_PATIENT_PRESCRIPTION:
			return {
				...state,
				prescriptions: [action.payload, ...state.prescriptions],
			};

		case UPDATE_PATIENT_PRESCRIPTION:
			const patientPrescIdx = _.findIndex(
				state.prescriptions,
				(p) => p.id === action.payload.id,
			);
			return {
				...state,
				prescriptions: [
					...state.prescriptions.slice(0, patientPrescIdx),
					action.payload,
					...state.prescriptions.slice(patientPrescIdx + 1),
				],
			};

		case DELETE_PATIENT_PRESCRIPTION:
			return {
				...state,
				prescriptions: _.reject(state.prescriptions, (p) => p.id === action.payload),
			};

		case SET_PATIENT_BILLS:
			return {
				...state,
				bills: action.payload || [],
				loading: false,
			};

		case CREATE_PATIENT_BILL:
			return {
				...state,
				bills: [action.payload, ...state.bills],
			};

		case UPDATE_PATIENT_BILL:
			const patientBillIdx = _.findIndex(state.bills, (p) => p.id === action.payload.id);
			return {
				...state,
				bills: [
					...state.bills.slice(0, patientBillIdx),
					action.payload,
					...state.bills.slice(patientBillIdx + 1),
				],
			};

		case DELETE_PATIENT_BILL:
			return {
				...state,
				bills: _.reject(state.bills, (p) => p.id === action.payload),
			};

		case SET_PATIENT_PAYMENTS:
			return {
				...state,
				payments: action.payload || [],
				loading: false,
			};

		case CREATE_PATIENT_PAYMENT:
			return {
				...state,
				payments: [action.payload, ...state.payments],
			};

		case UPDATE_PATIENT_PAYMENT:
			const patientPaymentIdx = _.findIndex(
				state.payments,
				(p) => p.id === action.payload.id,
			);
			return {
				...state,
				payments: [
					...state.payments.slice(0, patientPaymentIdx),
					action.payload,
					...state.payments.slice(patientPaymentIdx + 1),
				],
			};

		case DELETE_PATIENT_PAYMENT:
			return {
				...state,
				payments: _.reject(state.payments, (p) => p.id === action.payload),
			};

		case SET_PATIENT_APPOINTMENTS:
			return {
				...state,
				appointments: action.payload || [],
				loading: false,
			};

		case SET_PATIENT_VISITS:
			return {
				...state,
				opds: action.payload || [],
				loading: false,
			};

		case CREATE_PATIENT_APPOINTMENT:
			return {
				...state,
				appointments: [action.payload, ...state.appointments],
			};

		case UPDATE_PATIENT_APPOINTMENT:
			const patientApptmtIdx = _.findIndex(
				state.appointments,
				(p) => p.id === action.payload.id,
			);
			return {
				...state,
				appointments: [
					...state.appointments.slice(0, patientApptmtIdx),
					action.payload,
					...state.appointments.slice(patientApptmtIdx + 1),
				],
			};

		case DELETE_PATIENT_APPOINTMENT:
			return {
				...state,
				appointments: _.reject(state.appointments, (p) => p.id === action.payload),
			};

		case SET_TIMINGS:
			return {
				...state,
				timings: action.payload || [],
				loading: false,
			};

		case SET_PATIENT_DOCUMENTS:
			return {
				...state,
				documents: action.payload || [],
				loading: false,
			};
		case SET_PATIENT_PICKLIST:
			return {
				...state,
				pickList: action.payload || null,
				loading: false,
			};
		case SET_MEDICAL_ALERTS:
			return {
				...state,
				medicalAlerts: action.payload || [],
				loading: false,
			};
		case SET_CHIEF_COMPLAINTS_VALUES:
			return {
				...state,
				chiefComplains: action.payload || [],
				loading: false,
			};

		case SET_CC_SYMPTOMS:
			return {
				...state,
				symptoms: action.payload || [],
			};

		// CASE SHEETS
		case SET_PATIENT_CASE_SHEETS:
			return {
				...state,
				caseSheets: action.payload || [],
				loading: false,
			};

		case CREATE_PATIENT_CASE_SHEET:
			return {
				...state,
				caseSheets: [action.payload, ...state.caseSheets],
			};

		case UPDATE_PATIENT_CASE_SHEET:
			const caseSheetIdx = _.findIndex(state.caseSheets, (p) => p.id === action.payload.id);
			return {
				...state,
				caseSheets: [
					...state.caseSheets.slice(0, caseSheetIdx),
					action.payload,
					...state.caseSheets.slice(caseSheetIdx + 1),
				],
			};

		case DELETE_PATIENT_CASE_SHEET:
			return {
				...state,
				caseSheets: _.reject(state.caseSheets, (p) => p.id === action.payload),
			};

		case SET_WHITEBOARD:
			return {
				...state,
				whiteboard: action.payload || [],
			};

		case SET_SOAP_ATTACHMENTS:
			return {
				...state,
				soapAttachments: action.payload || [],
			};

		case SET_CLINICAL_NOTES:
			return {
				...state,
				clinicalNotes: action.payload || [],
			};

		case SET_PATIENT_REFUND_PAYMENTS:
			return {
				...state,
				refundPayments: action.payload || [],
			};

		default:
			return state;
	}
};

export default patientReducer;
