// ⚠️ warning: extreme coordinate geometry used
// do not touch until you understand it completely
// for info reach out: ayushk
import React, { useEffect, useState } from 'react';
import Button from '../../../../components/bootstrap/Button';
import { useFormik } from 'formik';

const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark'];

const Box2SVG = ({ editItem }) => {
	const [selectedColor, setSelectedColor] = useState(colors[0]);
	const [isEraser, setIsEraser] = useState(false);

	const { setValues, values } = useFormik({
		initialValues: Object.fromEntries(
			Array.from({ length: 26 }, (_, index) => [
				index + 1,
				{
					top: '#fff',
					right: '#fff',
					bottom: '#fff',
					left: '#fff',
					center: '#fff',
				},
			]),
		),
	});

	const handleClick = (box, part) => {
		setValues((prev) => ({
			...prev,
			[box]: {
				...prev[box],
				[part]: isEraser ? '#fff' : `var(--bs-${selectedColor})`,
			},
		}));
	};

	const onFinalSubmit = () => {
		console.log(formik.values);
	};

	useEffect(() => {
		if (editItem) {
			formik.setValues(editItem);
		}
	}, []);

	return (
		<>
			<div className='mb-5 w-75 m-auto d-flex justify-content-between align-items-center'>
				{colors.map((c) => (
					<tr key={c}>
						<td
							style={{ padding: '0.7rem' }}
							className={
								c === selectedColor && selectedColor === 'light'
									? 'bg-dark rounded'
									: c === selectedColor
									? 'bg-light rounded'
									: ''
							}>
							<Button
								className='rounded-circle'
								color={c}
								onClick={() => {
									setSelectedColor(c);
									setIsEraser(false);
								}}
							/>
						</td>
					</tr>
				))}
				<tr>
					<td
						style={{ padding: '0.7rem' }}
						className={isEraser ? 'bg-light rounded' : ''}>
						<Button
							icon='eraser'
							className='rounded-circle'
							onClick={() => setIsEraser((prev) => !prev)}
						/>
					</td>
				</tr>
			</div>

			<svg
				id='Layer_1'
				data-name='Layer 1'
				xmlns='http://www.w3.org/2000/svg'
				viewBox='0 0 488.67 77.91'>
				<defs>
					<style
						dangerouslySetInnerHTML={{
							__html: '.cls-1{fill:none;stroke:#000;stroke-miterlimit:10;}',
						}}
					/>
				</defs>
				{/* 1 - Path */}
				<path
					style={{ fill: values?.[1]?.['left'] || 'none' }}
					onClick={() => handleClick(1, 'left')}
					className='cls-1'
					d='M97.31,142.68v36.83l9.82-12.6v-12.6Z'
					transform='translate(-4.92 -140.85)'
				/>
				<path
					style={{ fill: values?.[1]?.['right'] || 'none' }}
					onClick={() => handleClick(1, 'right')}
					className='cls-1'
					d='M127.75,179.51V142.68l-9.82,12.6v12.6Z'
					transform='translate(-4.92 -140.85)'
				/>
				<path
					style={{ fill: values?.[1]?.['bottom'] || 'none' }}
					onClick={() => handleClick(1, 'bottom')}
					className='cls-1'
					d='M97.3,179.6h30L117,167.39H106.76Z'
					transform='translate(-4.92 -140.85)'
				/>
				<path
					style={{ fill: values?.[1]?.['top'] || 'none' }}
					onClick={() => handleClick(1, 'top')}
					className='cls-1'
					d='M127.26,142.2h-30q5.13,6.09,10.25,12.2H117.8Z'
					transform='translate(-4.92 -140.85)'
				/>
				<path
					style={{ fill: values?.[1]?.['center'] || 'none' }}
					onClick={() => handleClick(1, 'center')}
					d='M102.458 13.526h10.25v13.25h-10.25z'
					className='cls-2'
				/>
				<path d='M112.334 13.901v12.5h-9.5v-12.5h9.5m.75-.75h-11v14h11v-14Z' />

				{/* 2 - Path */}
				<path
					style={{ fill: values?.[2]?.['left'] || 'none' }}
					onClick={() => handleClick(2, 'left')}
					className='cls-1'
					d='M127.75,142.68v36.83l9.82-12.6v-12.6Z'
					transform='translate(-4.92 -140.85)'
				/>
				<path
					style={{ fill: values?.[2]?.['right'] || 'none' }}
					onClick={() => handleClick(2, 'right')}
					className='cls-1'
					d='M158.19,179.51V142.68l-9.82,12.6v12.6Z'
					transform='translate(-4.92 -140.85)'
				/>
				<path
					style={{ fill: values?.[2]?.['bottom'] || 'none' }}
					onClick={() => handleClick(2, 'bottom')}
					className='cls-1'
					d='M127.74,179.6h30l-10.25-12.21H137.2Z'
					transform='translate(-4.92 -140.85)'
				/>
				<path
					style={{ fill: values?.[2]?.['top'] || 'none' }}
					onClick={() => handleClick(2, 'top')}
					className='cls-1'
					d='M157.7,142.2h-30q5.13,6.09,10.25,12.2h10.25Q153,148.3,157.7,142.2Z'
					transform='translate(-4.92 -140.85)'
				/>
				<path
					style={{ fill: values?.[2]?.['center'] || 'none' }}
					onClick={() => handleClick(2, 'center')}
					d='M132.758 13.826h10.25v13.25h-10.25z'
					className='cls-2'
				/>
				<path d='M142.634 14.201v12.5h-9.5v-12.5h9.5m.75-.75h-11v14h11v-14Z' />

				{/* 3 - Polygon */}
				<polygon
					style={{ fill: values?.[3]?.['top'] || 'none' }}
					onClick={() => handleClick(3, 'top')}
					className='cls-1'
					points='168.98 19.62 183.41 1.15 154.55 1.15 168.98 19.62'
				/>
				<polygon
					style={{ fill: values?.[3]?.['bottom'] || 'none' }}
					onClick={() => handleClick(3, 'bottom')}
					className='cls-1'
					points='168.98 20.54 154.55 39 183.41 39 168.98 20.54'
				/>
				<polygon
					style={{ fill: values?.[3]?.['right'] || 'none' }}
					onClick={() => handleClick(3, 'right')}
					className='cls-1'
					points='169.49 20.05 184.2 38.66 184.2 1.44 169.49 20.05'
				/>
				<polygon
					style={{ fill: values?.[3]?.['left'] || 'none' }}
					onClick={() => handleClick(3, 'left')}
					className='cls-1'
					points='168.46 20.05 153.75 1.44 153.75 38.66 168.46 20.05'
				/>
				{/* 4 - Polygon */}
				<polygon
					style={{ fill: values?.[4]?.['top'] || 'none' }}
					onClick={() => handleClick(4, 'top')}
					className='cls-1'
					points='199.42 19.62 213.85 1.15 184.99 1.15 199.42 19.62'
				/>
				<polygon
					style={{ fill: values?.[4]?.['bottom'] || 'none' }}
					onClick={() => handleClick(4, 'bottom')}
					className='cls-1'
					points='199.42 20.54 184.99 39 213.85 39 199.42 20.54'
				/>
				<polygon
					style={{ fill: values?.[4]?.['right'] || 'none' }}
					onClick={() => handleClick(4, 'right')}
					className='cls-1'
					points='199.93 20.05 214.64 38.66 214.64 1.44 199.93 20.05'
				/>
				<polygon
					style={{ fill: values?.[4]?.['left'] || 'none' }}
					onClick={() => handleClick(4, 'left')}
					className='cls-1'
					points='198.89 20.05 184.19 1.44 184.19 38.66 198.89 20.05'
				/>
				{/* 5 - Polygon */}
				<polygon
					style={{ fill: values?.[5]?.['top'] || 'none' }}
					onClick={() => handleClick(5, 'top')}
					className='cls-1'
					points='229.86 19.62 244.29 1.15 215.43 1.15 229.86 19.62'
				/>
				<polygon
					style={{ fill: values?.[5]?.['bottom'] || 'none' }}
					onClick={() => handleClick(5, 'bottom')}
					className='cls-1'
					points='229.86 20.54 215.43 39 244.29 39 229.86 20.54'
				/>
				<polygon
					style={{ fill: values?.[5]?.['right'] || 'none' }}
					onClick={() => handleClick(5, 'right')}
					className='cls-1'
					points='230.37 20.05 245.07 38.66 245.07 1.44 230.37 20.05'
				/>
				<polygon
					style={{ fill: values?.[5]?.['left'] || 'none' }}
					onClick={() => handleClick(5, 'left')}
					className='cls-1'
					points='229.33 20.05 214.62 1.44 214.62 38.66 229.33 20.05'
				/>
				{/* 6 - Polygon */}
				<polygon
					style={{ fill: values?.[6]?.['top'] || 'none' }}
					onClick={() => handleClick(6, 'top')}
					className='cls-1'
					points='260.3 19.62 274.73 1.15 245.87 1.15 260.3 19.62'
				/>
				<polygon
					style={{ fill: values?.[6]?.['bottom'] || 'none' }}
					onClick={() => handleClick(6, 'bottom')}
					className='cls-1'
					points='260.3 20.54 245.87 39 274.73 39 260.3 20.54'
				/>
				<polygon
					style={{ fill: values?.[6]?.['right'] || 'none' }}
					onClick={() => handleClick(6, 'right')}
					className='cls-1'
					points='260.8 20.05 275.51 38.66 275.51 1.44 260.8 20.05'
				/>
				<polygon
					style={{ fill: values?.[6]?.['left'] || 'none' }}
					onClick={() => handleClick(6, 'left')}
					className='cls-1'
					points='259.77 20.05 245.06 1.44 245.06 38.66 259.77 20.05'
				/>
				{/* 7 - Polygon */}
				<polygon
					style={{ fill: values?.[7]?.['top'] || 'none' }}
					onClick={() => handleClick(7, 'top')}
					className='cls-1'
					points='290.73 19.62 305.16 1.15 276.3 1.15 290.73 19.62'
				/>
				<polygon
					style={{ fill: values?.[7]?.['bottom'] || 'none' }}
					onClick={() => handleClick(7, 'bottom')}
					className='cls-1'
					points='290.73 20.54 276.3 39 305.16 39 290.73 20.54'
				/>
				<polygon
					style={{ fill: values?.[7]?.['right'] || 'none' }}
					onClick={() => handleClick(7, 'right')}
					className='cls-1'
					points='291.24 20.05 305.95 38.66 305.95 1.44 291.24 20.05'
				/>
				<polygon
					style={{ fill: values?.[7]?.['left'] || 'none' }}
					onClick={() => handleClick(7, 'left')}
					className='cls-1'
					points='290.21 20.05 275.5 1.44 275.5 38.66 290.21 20.05'
				/>
				{/* 8 - Polygon */}
				<polygon
					style={{ fill: values?.[8]?.['top'] || 'none' }}
					onClick={() => handleClick(8, 'top')}
					className='cls-1'
					points='321.17 19.62 335.6 1.15 306.74 1.15 321.17 19.62'
				/>
				<polygon
					style={{ fill: values?.[8]?.['bottom'] || 'none' }}
					onClick={() => handleClick(8, 'bottom')}
					className='cls-1'
					points='321.17 20.54 306.74 39 335.6 39 321.17 20.54'
				/>
				<polygon
					style={{ fill: values?.[8]?.['right'] || 'none' }}
					onClick={() => handleClick(8, 'right')}
					className='cls-1'
					points='321.68 20.05 336.39 38.66 336.39 1.44 321.68 20.05'
				/>
				<polygon
					style={{ fill: values?.[8]?.['left'] || 'none' }}
					onClick={() => handleClick(8, 'left')}
					className='cls-1'
					points='320.64 20.05 305.94 1.44 305.94 38.66 320.64 20.05'
				/>
				{/* 9 - Path */}
				<path
					style={{ fill: values?.[9]?.['left'] || 'none' }}
					onClick={() => handleClick(9, 'left')}
					className='cls-1'
					d='M340.81,142.68v36.83l9.82-12.6v-12.6Z'
					transform='translate(-4.92 -140.85)'
				/>
				<path
					style={{ fill: values?.[9]?.['right'] || 'none' }}
					onClick={() => handleClick(9, 'right')}
					className='cls-1'
					d='M371.25,179.51V142.68l-9.82,12.6v12.6Z'
					transform='translate(-4.92 -140.85)'
				/>
				<path
					style={{ fill: values?.[9]?.['bottom'] || 'none' }}
					onClick={() => handleClick(9, 'bottom')}
					className='cls-1'
					d='M340.8,179.6h30l-10.25-12.21H350.26Z'
					transform='translate(-4.92 -140.85)'
				/>
				<path
					style={{ fill: values?.[9]?.['top'] || 'none' }}
					onClick={() => handleClick(9, 'top')}
					className='cls-1'
					d='M370.76,142.2h-30q5.13,6.09,10.25,12.2H361.3Z'
					transform='translate(-4.92 -140.85)'
				/>
				<path
					style={{ fill: values?.[9]?.['center'] || 'none' }}
					onClick={() => handleClick(9, 'center')}
					d='M345.959 13.526h10.25v13.25h-10.25z'
					className='cls-2'
				/>
				<path d='M355.834 13.901v12.5h-9.5v-12.5h9.5m.75-.75h-11v14h11v-14Z' />
				{/* 10 - Path */}
				<path
					style={{ fill: values?.[10]?.['left'] || 'none' }}
					onClick={() => handleClick(10, 'left')}
					className='cls-1'
					d='M371.25,142.68v36.83l9.82-12.6v-12.6Z'
					transform='translate(-4.92 -140.85)'
				/>
				<path
					style={{ fill: values?.[10]?.['right'] || 'none' }}
					onClick={() => handleClick(10, 'right')}
					className='cls-1'
					d='M401.69,179.51V142.68l-9.82,12.6v12.6Z'
					transform='translate(-4.92 -140.85)'
				/>
				<path
					style={{ fill: values?.[10]?.['bottom'] || 'none' }}
					onClick={() => handleClick(10, 'bottom')}
					className='cls-1'
					d='M371.24,179.6h30L391,167.39H380.7Z'
					transform='translate(-4.92 -140.85)'
				/>
				<path
					style={{ fill: values?.[10]?.['top'] || 'none' }}
					onClick={() => handleClick(10, 'top')}
					className='cls-1'
					d='M401.2,142.2h-30q5.13,6.09,10.25,12.2h10.25Q396.47,148.3,401.2,142.2Z'
					transform='translate(-4.92 -140.85)'
				/>
				<path
					style={{ fill: values?.[10]?.['center'] || 'none' }}
					onClick={() => handleClick(10, 'center')}
					d='M376.459 13.526h10.25v13.25h-10.25z'
					className='cls-2'
				/>
				<path d='M386.334 13.901v12.5h-9.5v-12.5h9.5m.75-.75h-11v14h11v-14Z' />
				{/* 11 - Path */}
				<path
					style={{ fill: values?.[11]?.['left'] || 'none' }}
					onClick={() => handleClick(11, 'left')}
					className='cls-1'
					d='M6,180.48v36.83l9.82-12.6v-12.6Z'
					transform='translate(-4.92 -140.85)'
				/>
				<path
					style={{ fill: values?.[11]?.['right'] || 'none' }}
					onClick={() => handleClick(11, 'right')}
					className='cls-1'
					d='M36.44,217.31V180.48l-9.82,12.6v12.6Z'
					transform='translate(-4.92 -140.85)'
				/>
				<path
					style={{ fill: values?.[11]?.['bottom'] || 'none' }}
					onClick={() => handleClick(11, 'bottom')}
					className='cls-1'
					d='M6,217.4H36L25.7,205.19H15.45Z'
					transform='translate(-4.92 -140.85)'
				/>
				<path
					style={{ fill: values?.[11]?.['top'] || 'none' }}
					onClick={() => handleClick(11, 'top')}
					className='cls-1'
					d='M36,180H6L16.24,192.2H26.49Z'
					transform='translate(-4.92 -140.85)'
				/>
				<path
					style={{ fill: values?.[11]?.['center'] || 'none' }}
					onClick={() => handleClick(11, 'center')}
					d='M10.958 51.626h10.25v12.449h-10.25z'
					className='cls-2'
				/>
				<path d='M20.834 52.001v11.7h-9.5v-11.7h9.5m.75-.75h-11v13.2h11v-13.2Z' />
				{/* 12 - Path */}
				<path
					style={{ fill: values?.[12]?.['left'] || 'none' }}
					onClick={() => handleClick(12, 'left')}
					className='cls-1'
					d='M36.44,180.48v36.83l9.82-12.6v-12.6Z'
					transform='translate(-4.92 -140.85)'
				/>
				<path
					style={{ fill: values?.[12]?.['right'] || 'none' }}
					onClick={() => handleClick(12, 'right')}
					className='cls-1'
					d='M66.87,217.31V180.48l-9.81,12.6v12.6Z'
					transform='translate(-4.92 -140.85)'
				/>
				<path
					style={{ fill: values?.[12]?.['bottom'] || 'none' }}
					onClick={() => handleClick(12, 'bottom')}
					className='cls-1'
					d='M36.43,217.4H66.38L56.14,205.19H45.89Z'
					transform='translate(-4.92 -140.85)'
				/>
				<path
					style={{ fill: values?.[12]?.['top'] || 'none' }}
					onClick={() => handleClick(12, 'top')}
					className='cls-1'
					d='M66.38,180H36.43L46.68,192.2H56.92Z'
					transform='translate(-4.92 -140.85)'
				/>
				<path
					style={{ fill: values?.[12]?.['center'] || 'none' }}
					onClick={() => handleClick(12, 'center')}
					d='M41.458 51.426h10.25v12.45h-10.25z'
					className='cls-2'
				/>
				<path d='M51.334 51.801v11.7h-9.5v-11.7h9.5m.75-.75h-11v13.2h11v-13.2Z' />
				{/* 13 - Path */}
				<path
					style={{ fill: values?.[13]?.['left'] || 'none' }}
					onClick={() => handleClick(13, 'left')}
					className='cls-1'
					d='M66.87,180.48v36.83l9.82-12.6v-12.6Z'
					transform='translate(-4.92 -140.85)'
				/>
				<path
					style={{ fill: values?.[13]?.['right'] || 'none' }}
					onClick={() => handleClick(13, 'right')}
					className='cls-1'
					d='M97.31,217.31V180.48l-9.82,12.6v12.6Z'
					transform='translate(-4.92 -140.85)'
				/>
				<path
					style={{ fill: values?.[13]?.['bottom'] || 'none' }}
					onClick={() => handleClick(13, 'bottom')}
					className='cls-1'
					d='M66.86,217.4h30L86.57,205.19H76.32Z'
					transform='translate(-4.92 -140.85)'
				/>
				<path
					style={{ fill: values?.[13]?.['top'] || 'none' }}
					onClick={() => handleClick(13, 'top')}
					className='cls-1'
					d='M96.82,180h-30L77.11,192.2H87.36Z'
					transform='translate(-4.92 -140.85)'
				/>
				<path
					style={{ fill: values?.[13]?.['center'] || 'none' }}
					onClick={() => handleClick(13, 'center')}
					d='M71.958 51.426h10.25v12.45h-10.25z'
					className='cls-2'
				/>
				<path d='M81.834 51.801v11.7h-9.5v-11.7h9.5m.75-.75h-11v13.2h11v-13.2Z' />
				{/* 14 - Path */}
				<path
					style={{ fill: values?.[14]?.['left'] || 'none' }}
					onClick={() => handleClick(14, 'left')}
					className='cls-1'
					d='M97.31,180.48v36.83l9.82-12.6v-12.6Z'
					transform='translate(-4.92 -140.85)'
				/>
				<path
					style={{ fill: values?.[14]?.['right'] || 'none' }}
					onClick={() => handleClick(14, 'right')}
					className='cls-1'
					d='M127.75,217.31V180.48l-9.82,12.6v12.6Z'
					transform='translate(-4.92 -140.85)'
				/>
				<path
					style={{ fill: values?.[14]?.['bottom'] || 'none' }}
					onClick={() => handleClick(14, 'bottom')}
					className='cls-1'
					d='M97.3,217.4h30L117,205.19H106.76Z'
					transform='translate(-4.92 -140.85)'
				/>
				<path
					style={{ fill: values?.[14]?.['top'] || 'none' }}
					onClick={() => handleClick(14, 'top')}
					className='cls-1'
					d='M127.26,180h-30l10.25,12.21H117.8Z'
					transform='translate(-4.92 -140.85)'
				/>
				<path
					style={{ fill: values?.[14]?.['center'] || 'none' }}
					onClick={() => handleClick(14, 'center')}
					d='M102.358 51.426h10.25v12.45h-10.25z'
					className='cls-2'
				/>
				<path d='M112.234 51.801v11.7h-9.5v-11.7h9.5m.75-.75h-11v13.2h11v-13.2Z' />
				{/* 15 - Path */}
				<path
					style={{ fill: values?.[15]?.['left'] || 'none' }}
					onClick={() => handleClick(15, 'left')}
					className='cls-1'
					d='M127.75,180.48v36.83l9.82-12.6v-12.6Z'
					transform='translate(-4.92 -140.85)'
				/>
				<path
					style={{ fill: values?.[15]?.['right'] || 'none' }}
					onClick={() => handleClick(15, 'right')}
					className='cls-1'
					d='M158.19,217.31V180.48l-9.82,12.6v12.6Z'
					transform='translate(-4.92 -140.85)'
				/>
				<path
					style={{ fill: values?.[15]?.['bottom'] || 'none' }}
					onClick={() => handleClick(15, 'bottom')}
					className='cls-1'
					d='M127.74,217.4h30l-10.25-12.21H137.2Z'
					transform='translate(-4.92 -140.85)'
				/>
				<path
					style={{ fill: values?.[15]?.['top'] || 'none' }}
					onClick={() => handleClick(15, 'top')}
					className='cls-1'
					d='M157.7,180h-30L138,192.2h10.25Z'
					transform='translate(-4.92 -140.85)'
				/>
				<path
					style={{ fill: values?.[15]?.['center'] || 'none' }}
					onClick={() => handleClick(15, 'center')}
					d='M132.758 51.526h10.25v12.65h-10.25z'
					className='cls-2'
				/>
				<path d='M142.634 51.901v11.9h-9.5v-11.9h9.5m.75-.75h-11v13.4h11v-13.4Z' />
				{/* 16 - Polygon */}
				<polygon
					style={{ fill: values?.[16]?.['top'] || 'none' }}
					onClick={() => handleClick(16, 'top')}
					className='cls-1'
					points='168 57.42 182.43 38.95 153.57 38.95 168 57.42'
				/>
				<polygon
					style={{ fill: values?.[16]?.['bottom'] || 'none' }}
					onClick={() => handleClick(16, 'bottom')}
					className='cls-1'
					points='168 58.33 153.57 76.8 182.43 76.8 168 58.33'
				/>
				<polygon
					style={{ fill: values?.[16]?.['right'] || 'none' }}
					onClick={() => handleClick(16, 'right')}
					className='cls-1'
					points='168.51 57.85 183.22 76.46 183.22 39.24 168.51 57.85'
				/>
				<polygon
					style={{ fill: values?.[16]?.['left'] || 'none' }}
					onClick={() => handleClick(16, 'left')}
					className='cls-1'
					points='167.47 57.85 152.77 39.24 152.77 76.46 167.47 57.85'
				/>
				{/* 17 - Polygon */}
				<polygon
					style={{ fill: values?.[17]?.['top'] || 'none' }}
					onClick={() => handleClick(17, 'top')}
					className='cls-1'
					points='198.44 57.42 212.87 38.95 184.01 38.95 198.44 57.42'
				/>
				<polygon
					style={{ fill: values?.[17]?.['bottom'] || 'none' }}
					onClick={() => handleClick(17, 'bottom')}
					className='cls-1'
					points='198.44 58.33 184.01 76.8 212.87 76.8 198.44 58.33'
				/>
				<polygon
					style={{ fill: values?.[17]?.['right'] || 'none' }}
					onClick={() => handleClick(17, 'right')}
					className='cls-1'
					points='198.94 57.85 213.66 76.46 213.66 39.24 198.94 57.85'
				/>
				<polygon
					style={{ fill: values?.[17]?.['left'] || 'none' }}
					onClick={() => handleClick(17, 'left')}
					className='cls-1'
					points='197.91 57.85 183.2 39.24 183.2 76.46 197.91 57.85'
				/>
				{/* 18 - Polygon */}
				<polygon
					style={{ fill: values?.[18]?.['top'] || 'none' }}
					onClick={() => handleClick(18, 'top')}
					className='cls-1'
					points='228.88 57.42 243.31 38.95 214.44 38.95 228.88 57.42'
				/>
				<polygon
					style={{ fill: values?.[18]?.['bottom'] || 'none' }}
					onClick={() => handleClick(18, 'bottom')}
					className='cls-1'
					points='228.88 58.33 214.44 76.8 243.31 76.8 228.88 58.33'
				/>
				<polygon
					style={{ fill: values?.[18]?.['right'] || 'none' }}
					onClick={() => handleClick(18, 'right')}
					className='cls-1'
					points='229.38 57.85 244.09 76.46 244.09 39.24 229.38 57.85'
				/>
				<polygon
					style={{ fill: values?.[18]?.['left'] || 'none' }}
					onClick={() => handleClick(18, 'left')}
					className='cls-1'
					points='228.35 57.85 213.64 39.24 213.64 76.46 228.35 57.85'
				/>
				{/* 19 - Polygon */}
				<polygon
					style={{ fill: values?.[19]?.['top'] || 'none' }}
					onClick={() => handleClick(19, 'top')}
					className='cls-1'
					points='259.31 57.42 273.74 38.95 244.88 38.95 259.31 57.42'
				/>
				<polygon
					style={{ fill: values?.[19]?.['bottom'] || 'none' }}
					onClick={() => handleClick(19, 'bottom')}
					className='cls-1'
					points='259.31 58.33 244.88 76.8 273.74 76.8 259.31 58.33'
				/>
				<polygon
					style={{ fill: values?.[19]?.['right'] || 'none' }}
					onClick={() => handleClick(19, 'right')}
					className='cls-1'
					points='259.82 57.85 274.53 76.46 274.53 39.24 259.82 57.85'
				/>
				<polygon
					style={{ fill: values?.[19]?.['left'] || 'none' }}
					onClick={() => handleClick(19, 'left')}
					className='cls-1'
					points='258.79 57.85 244.08 39.24 244.08 76.46 258.79 57.85'
				/>
				{/* 20 - Polygon */}
				<polygon
					style={{ fill: values?.[20]?.['top'] || 'none' }}
					onClick={() => handleClick(20, 'top')}
					className='cls-1'
					points='289.75 57.42 304.18 38.95 275.32 38.95 289.75 57.42'
				/>
				<polygon
					style={{ fill: values?.[20]?.['bottom'] || 'none' }}
					onClick={() => handleClick(20, 'bottom')}
					className='cls-1'
					points='289.75 58.33 275.32 76.8 304.18 76.8 289.75 58.33'
				/>
				<polygon
					style={{ fill: values?.[20]?.['right'] || 'none' }}
					onClick={() => handleClick(20, 'right')}
					className='cls-1'
					points='290.26 57.85 304.97 76.46 304.97 39.24 290.26 57.85'
				/>
				<polygon
					style={{ fill: values?.[20]?.['left'] || 'none' }}
					onClick={() => handleClick(20, 'left')}
					className='cls-1'
					points='289.23 57.85 274.52 39.24 274.52 76.46 289.23 57.85'
				/>
				{/* 21 - Polygon */}
				<polygon
					style={{ fill: values?.[21]?.['top'] || 'none' }}
					onClick={() => handleClick(21, 'top')}
					className='cls-1'
					points='320.19 57.42 334.62 38.95 305.76 38.95 320.19 57.42'
				/>
				<polygon
					style={{ fill: values?.[21]?.['bottom'] || 'none' }}
					onClick={() => handleClick(21, 'bottom')}
					className='cls-1'
					points='320.19 58.33 305.76 76.8 334.62 76.8 320.19 58.33'
				/>
				<polygon
					style={{ fill: values?.[21]?.['right'] || 'none' }}
					onClick={() => handleClick(21, 'right')}
					className='cls-1'
					points='320.7 57.85 335.4 76.46 335.4 39.24 320.7 57.85'
				/>
				<polygon
					style={{ fill: values?.[21]?.['left'] || 'none' }}
					onClick={() => handleClick(21, 'left')}
					className='cls-1'
					points='319.66 57.85 304.95 39.24 304.95 76.46 319.66 57.85'
				/>
				{/* 22 - Path */}
				<path
					style={{ fill: values?.[22]?.['left'] || 'none' }}
					onClick={() => handleClick(22, 'left')}
					className='cls-1'
					d='M340.81,180.48v36.83l9.82-12.6v-12.6Z'
					transform='translate(-4.92 -140.85)'
				/>
				<path
					style={{ fill: values?.[22]?.['right'] || 'none' }}
					onClick={() => handleClick(22, 'right')}
					className='cls-1'
					d='M371.25,217.31V180.48l-9.82,12.6v12.6Z'
					transform='translate(-4.92 -140.85)'
				/>
				<path
					style={{ fill: values?.[22]?.['center'] || 'none' }}
					onClick={() => handleClick(22, 'center')}
					d='M345.959 51.526h10.25v12.25h-10.25z'
					className='cls-2'
				/>
				<path d='M355.834 51.901v11.5h-9.5v-11.5h9.5m.75-.75h-11v13h11v-13Z' />
				<path
					style={{ fill: values?.[22]?.['bottom'] || 'none' }}
					onClick={() => handleClick(22, 'bottom')}
					className='cls-1'
					d='M340.8,217.4h30l-10.25-12.21H350.26Z'
					transform='translate(-4.92 -140.85)'
				/>
				<path
					style={{ fill: values?.[22]?.['top'] || 'none' }}
					onClick={() => handleClick(22, 'top')}
					className='cls-1'
					d='M370.76,180h-30l10.25,12.21H361.3Z'
					transform='translate(-4.92 -140.85)'
				/>
				{/* 23 - Path */}
				<path
					style={{ fill: values?.[23]?.['left'] || 'none' }}
					onClick={() => handleClick(23, 'left')}
					className='cls-1'
					d='M371.25,180.48v36.83l9.82-12.6v-12.6Z'
					transform='translate(-4.92 -140.85)'
				/>
				<path
					style={{ fill: values?.[23]?.['right'] || 'none' }}
					onClick={() => handleClick(23, 'right')}
					className='cls-1'
					d='M401.69,217.31V180.48l-9.82,12.6v12.6Z'
					transform='translate(-4.92 -140.85)'
				/>
				<path
					style={{ fill: values?.[23]?.['bottom'] || 'none' }}
					onClick={() => handleClick(23, 'bottom')}
					className='cls-1'
					d='M371.24,217.4h30L391,205.19H380.7Z'
					transform='translate(-4.92 -140.85)'
				/>
				<path
					style={{ fill: values?.[23]?.['top'] || 'none' }}
					onClick={() => handleClick(23, 'top')}
					className='cls-1'
					d='M401.2,180h-30l10.25,12.21h10.25Z'
					transform='translate(-4.92 -140.85)'
				/>
				<path
					style={{ fill: values?.[23]?.['center'] || 'none' }}
					onClick={() => handleClick(23, 'center')}
					d='M376.459 51.526h10.25v12.65h-10.25z'
					className='cls-2'
				/>
				<path d='M386.334 51.901v11.9h-9.5v-11.9h9.5m.75-.75h-11v13.4h11v-13.4Z' />
				{/* 24 - Path */}
				<path
					style={{ fill: values?.[24]?.['left'] || 'none' }}
					onClick={() => handleClick(24, 'left')}
					className='cls-1'
					d='M401.69,180.48v36.83l9.82-12.6v-12.6Z'
					transform='translate(-4.92 -140.85)'
				/>
				<path
					style={{ fill: values?.[24]?.['right'] || 'none' }}
					onClick={() => handleClick(24, 'right')}
					className='cls-1'
					d='M432.12,217.31V180.48l-9.81,12.6v12.6Z'
					transform='translate(-4.92 -140.85)'
				/>
				<path
					style={{ fill: values?.[24]?.['bottom'] || 'none' }}
					onClick={() => handleClick(24, 'bottom')}
					className='cls-1'
					d='M401.68,217.4h29.95l-10.24-12.21H411.14Z'
					transform='translate(-4.92 -140.85)'
				/>
				<path
					style={{ fill: values?.[24]?.['top'] || 'none' }}
					onClick={() => handleClick(24, 'top')}
					className='cls-1'
					d='M431.63,180H401.68l10.25,12.21h10.24Z'
					transform='translate(-4.92 -140.85)'
				/>
				<path
					style={{ fill: values?.[24]?.['center'] || 'none' }}
					onClick={() => handleClick(24, 'center')}
					d='M406.959 51.825h10.25v12.65h-10.25z'
					className='cls-2'
				/>
				<path d='M416.834 52.201v11.9h-9.5v-11.9h9.5m.75-.75h-11v13.4h11v-13.4Z' />
				{/* 25 - Path */}
				<path
					style={{ fill: values?.[25]?.['left'] || 'none' }}
					onClick={() => handleClick(25, 'left')}
					className='cls-1'
					d='M432.12,180.48v36.83l9.82-12.6v-12.6Z'
					transform='translate(-4.92 -140.85)'
				/>
				<path
					style={{ fill: values?.[25]?.['right'] || 'none' }}
					onClick={() => handleClick(25, 'right')}
					className='cls-1'
					d='M462.56,217.31V180.48l-9.82,12.6v12.6Z'
					transform='translate(-4.92 -140.85)'
				/>
				<path
					style={{ fill: values?.[25]?.['bottom'] || 'none' }}
					onClick={() => handleClick(25, 'bottom')}
					className='cls-1'
					d='M432.11,217.4h30l-10.25-12.21H441.57Z'
					transform='translate(-4.92 -140.85)'
				/>
				<path
					style={{ fill: values?.[25]?.['top'] || 'none' }}
					onClick={() => handleClick(25, 'top')}
					className='cls-1'
					d='M462.07,180h-30l10.25,12.21h10.25Z'
					transform='translate(-4.92 -140.85)'
				/>
				<path
					style={{ fill: values?.[25]?.['center'] || 'none' }}
					onClick={() => handleClick(25, 'center')}
					d='M437.158 51.626h10.25v12.649h-10.25z'
					className='cls-2'
				/>
				<path d='M447.034 52.001v11.9h-9.5v-11.9h9.5m.75-.75h-11v13.4h11v-13.4Z' />
				{/* 26 - Path */}
				<path
					style={{ fill: values?.[26]?.['left'] || 'none' }}
					onClick={() => handleClick(26, 'left')}
					className='cls-1'
					d='M462.56,180.48v36.83l9.82-12.6v-12.6Z'
					transform='translate(-4.92 -140.85)'
				/>
				<path
					style={{ fill: values?.[26]?.['right'] || 'none' }}
					onClick={() => handleClick(26, 'right')}
					className='cls-1'
					d='M493,217.31V180.48l-9.82,12.6v12.6Z'
					transform='translate(-4.92 -140.85)'
				/>
				<path
					style={{ fill: values?.[26]?.['bottom'] || 'none' }}
					onClick={() => handleClick(26, 'bottom')}
					className='cls-1'
					d='M462.55,217.4h30l-10.25-12.21H472Z'
					transform='translate(-4.92 -140.85)'
				/>
				<path
					style={{ fill: values?.[26]?.['top'] || 'none' }}
					onClick={() => handleClick(26, 'top')}
					className='cls-1'
					d='M492.51,180h-30L472.8,192.2h10.25Z'
					transform='translate(-4.92 -140.85)'
				/>
				<path
					style={{ fill: values?.[26]?.['center'] || 'none' }}
					onClick={() => handleClick(26, 'center')}
					d='M467.658 51.626h10.25v12.649h-10.25z'
					className='cls-2'
				/>
				<path d='M477.534 52.001v11.9h-9.5v-11.9h9.5m.75-.75h-11v13.4h11v-13.4Z' />
			</svg>
		</>
	);
};

export default Box2SVG;
