import React from 'react';
import Modal, {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
} from '../components/bootstrap/Modal';
import Button from '../components/bootstrap/Button';
import { useDispatch, useSelector } from 'react-redux';
import { getOpdRecordsByDept } from '../actions/opdActions';

// Popup to show a notification when a new opd is added and to be verified
const NewOpdPopup = ({ isOpenModal, setIsOpenModal }) => {
	const dispatch = useDispatch();
	const selectedDept = useSelector((state) => state.profile.selectedDept);

	return (
		<Modal isOpen={isOpenModal} setIsOpen={setIsOpenModal}>
			<ModalHeader setIsOpen={setIsOpenModal}>
				<ModalTitle className='d-flex align-items-end'>New OPD Added</ModalTitle>
			</ModalHeader>
			<ModalBody>A new opd is added</ModalBody>
			<ModalFooter>
				{/* <Button icon='Close' color='danger' isLight onClick={() => setIsOpenModal(false)}>
					Reject
				</Button> */}
				<Button
					color='success'
					isLight
					onClick={() => {
						dispatch(
							getOpdRecordsByDept(selectedDept?.departmentId || selectedDept?.id),
						);
						setIsOpenModal(false);
					}}>
					OK
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default NewOpdPopup;
