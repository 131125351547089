import {
	SET_ACCOUNT_VENDORS,
	LOADING_ACCOUNT_VENDORS,
	CREATE_ACCOUNT_VENDOR,
	UPDATE_ACCOUNT_VENDOR,
	DELETE_ACCOUNT_VENDOR,
	SET_ACCOUNT_TRANSACTIONS,
	LOADING_ACCOUNT_TRANSACTIONS,
	CREATE_ACCOUNT_TRANSACTION,
	UPDATE_ACCOUNT_TRANSACTION,
	SET_MIS_SPECIFIC_TOTALS,
	DELETE_ACCOUNT_TRANSACTION,
	SET_MIS_DATA,
	SET_MIS_OVERVIEW,
	SET_STAFF_DATA,
	SET_ACCOUNT_EXPENSES,
	SET_PATIENT_PENDING_BILLS,
	DELETE_ORG_EXPENSE,
	UPDATE_ORG_EXPENSE,
	CREATE_ORG_EXPENSES,
	SET_ORG_EXPENSES,
	SET_DETAIL_DOCTOR_REPORT,
} from './types';

const initialState = {
	vendors: [],
	transactions: [],
	currentMisTotal: '',
	currentMisDiscount: '',
	error: null,
	loading: false,
};

const accountTransactionReducer = (state = initialState, action) => {
	switch (action.type) {
		case LOADING_ACCOUNT_VENDORS:
			return {
				...state,
				loading: true,
			};

		case SET_ACCOUNT_VENDORS:
			return {
				...state,
				vendors: action.payload || [],
				loading: false,
			};

		case CREATE_ACCOUNT_VENDOR:
			return {
				...state,
				vendors: [action.payload, ...state.vendors],
			};

		case UPDATE_ACCOUNT_VENDOR:
			state.vendors = state.vendors.filter((o) => o.id != action.payload.id);
			return {
				vendors: [action.payload, ...state.vendors],
				...state,
			};

		case DELETE_ACCOUNT_VENDOR:
			state.vendors = state.vendors.filter((ig) => ig.id != action.payload);
			return {
				...state,
			};

		case LOADING_ACCOUNT_TRANSACTIONS:
			return {
				...state,
				loading: true,
			};

		case SET_ACCOUNT_TRANSACTIONS:
			return {
				...state,
				transactions: action.payload || [],
				loading: false,
			};

		case CREATE_ACCOUNT_TRANSACTION:
			return {
				...state,
				transactions: [action.payload, ...state.transactions],
			};

		case UPDATE_ACCOUNT_TRANSACTION:
			state.transactions = state.transactions.filter((o) => o.id != action.payload.id);
			return {
				transactions: [action.payload, ...state.transactions],
				...state,
			};

		case DELETE_ACCOUNT_TRANSACTION:
			state.transactions = state.transactions.filter((ig) => ig.id != action.payload);
			return {
				...state,
			};

		case SET_MIS_OVERVIEW:
			return {
				...state,
				currentMisOverview: action.payload || [],
			};
		case SET_ACCOUNT_EXPENSES:
			return {
				...state,
				expenses: action.payload.expenses || [],
				expensesPage: action.payload.page || 1,
				expensesTotal: action.payload.total || 1,
			};
		case SET_MIS_DATA:
			return {
				...state,
				misData: action.payload || null,
			};
		case SET_DETAIL_DOCTOR_REPORT:
			return {
				...state,
				detailDoctorReport: action.payload || {},
			};
		case SET_MIS_SPECIFIC_TOTALS:
			return {
				...state,
				currentMisTotal: action.payload.total || '',
				currentMisDiscount: action.payload.discount || '',
			};
		case SET_ORG_EXPENSES:
			return {
				...state,
				orgExpenses: action.payload.orgExpenses || [],
				orgPages: action.payload.page || 1,
				orgTotal: action.payload.total || 1,
			};
		case SET_STAFF_DATA:
			return {
				...state,
				staffData: action.payload || null,
			};
		case SET_PATIENT_PENDING_BILLS:
			return {
				...state,
				patientPendingBills: action.payload.pendingPayments || [],
				pendingPage: action.payload.page || 1,
				pendingTotal: action.payload.total || 1,
			};

		case SET_ACCOUNT_EXPENSES:
			return {
				...state,
				expenses: action.payload.expenses || [],
				expensesPage: action.payload.page || 1,
				expensesTotal: action.payload.total || 1,
			};
		case CREATE_ORG_EXPENSES:
			return {
				...state,
				expenses: [action.payload, ...state.expenses],
			};
		case UPDATE_ORG_EXPENSE:
			return {
				...state,
				// expenses :
			};
		case DELETE_ORG_EXPENSE:
			return {
				...state,
				expenses: state.expenses?.filter((it) => it.id !== action.payload),
			};
		default:
			return state;
	}
};

export default accountTransactionReducer;
