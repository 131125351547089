import { combineReducers } from 'redux';

import authReducer from './authReducer';
import organisationReducer from './organisationReducer';
import specialityReducer from './specialityReducer';
import departmentReducer from './departmentReducer';
import doctorReducer from './doctorReducer';
import patientReducer from './patientReducer';
import pharmacyReducer from './pharmacyReducer';
import pharmacyVendorReducer from './pharmacyVendorReducer';
import purchaseVoucherReducer from './purchaseVoucherReducer';
import saleVoucherReducer from './saleVoucherReducer';
import inventoryReducer from './inventoryReducer';
import amenityReducer from './amenityReducer';
import opdReducer from './opdReducer';
import ipdReducer from './ipdReducer';
import staffReducer from './staffReducer';
import settingReducer from './settingReducer';
import profileReducer from './profileReducer';
import appointmentReducer from './appointmentReducer';
import accountReducer from './accountReducer';
import labReducer from './labReducer';
import modalReducer from './modalReducer';
import componentReducer from './componentReducer';
import { AUTH_LOGOUT } from './types';

const appReducer = combineReducers({
	auth: authReducer,
	profile: profileReducer,
	organisation: organisationReducer,
	speciality: specialityReducer,
	department: departmentReducer,
	doctor: doctorReducer,
	patient: patientReducer,
	pharmacy: pharmacyReducer,
	pharmacyVendor: pharmacyVendorReducer,
	purchaseVoucher: purchaseVoucherReducer,
	saleVoucher: saleVoucherReducer,
	inventory: inventoryReducer,
	amenity: amenityReducer,
	appointment: appointmentReducer,
	opd: opdReducer,
	ipd: ipdReducer,
	staff: staffReducer,
	setting: settingReducer,
	account: accountReducer,
	lab: labReducer,
	modal: modalReducer,
	component: componentReducer,
});

const rootReducer = (state, action) => {
	if (action.type === AUTH_LOGOUT) {
		return appReducer(undefined, action);
	}

	return appReducer(state, action);
};

export default rootReducer;
